// Lib
import React from 'react'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormikContext } from 'formik'

//Images

// Include in project
import { Props, RadioSx } from './config'

const RadioComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, handleChange } = useFormikContext<any>()
  return (
    <FormControlLabel
      control={
        <Radio
          name={props.name}
          sx={RadioSx}
          value={props.value}
          checked={values[props.name] === props.value}
          onChange={handleChange}
          disabled={props.disabled}
        />
      }
      label={<p>{props.label}</p>}
    />
  )
}

export default RadioComponent
