// Lib
import React from 'react'
import { Modal } from '@mui/material'
import { useFormikContext } from 'formik'

// Images
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, Checkbox } from 'component'
import { Props } from './config'
import { useTranslation } from 'react-i18next'

const ModalRecentlyDeleted: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()

  const handleSelectAll = () => {
    if (values.recently.recentlyList.length === props.dataTable.length) {
      return setFieldValue('recently.recentlyList', [])
    } else {
      setFieldValue(
        'recently.recentlyList',
        props.dataTable.map((data: any) => `${data[props.primaryKey]}`),
      )
    }
  }

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <section className={styles.containerModal}>
        <div className={styles.wrapperCard}>
          <div className={styles.head}>
            <h3>{t<string>('common.recentDelete')}</h3>
            <img src={closeSVG} alt="closeSVG" onClick={props.onClose} />
          </div>

          <div className={styles.containerShowData}>
            <div className={styles.buttonWrapper}>
              <Button
                typeButton={'button'}
                type={'primary'}
                disabled={values.recently.recentlyList.length === 0}
                name="Recover"
                functionOnClick={props.onClickRecover}
              />
            </div>
            <div className={styles.wrapperTable}>
              <table className="tableMain">
                {props.widthCol && (
                  <colgroup>
                    <col style={{ width: '5%' }} />
                    {Object.keys(props.widthCol).map((key, index) => (
                      <col key={index} style={{ width: props.widthCol[key] }} />
                    ))}
                  </colgroup>
                )}

                <thead>
                  <tr>
                    <th>
                      <div className={styles.checkboxTH}>
                        <Checkbox
                          name={`recently.selectAll`}
                          value={'selectAll'}
                          onChange={handleSelectAll}
                          checked={
                            props.dataTable.length === 0
                              ? false
                              : values.recently.recentlyList.length === props.dataTable.length
                          }
                        />
                      </div>
                    </th>
                    {Object.keys(props.keyTable).map((key, index) => (
                      <th key={index}>
                        <h6>{props.keyTable[key]}</h6>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {props.dataTable.length <= 0 ? (
                    <tr>
                      <td colSpan={Object.keys(props.keyTable).length + 1}>No Data</td>
                    </tr>
                  ) : (
                    props.dataTable.map((data: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className={styles.checkboxTH}>
                              <Checkbox
                                name={`recently.recentlyList`}
                                value={data[props.primaryKey]}
                                checked={values.recently.recentlyList.includes(`${data[props.primaryKey]}`)}
                              />
                            </div>
                          </td>
                          {Object.keys(props.keyTable).map((key, indexKey) => (
                            <td key={indexKey}>{data[key]}</td>
                          ))}
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  )
}
export default ModalRecentlyDeleted
