// Lib
import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// Include in project
import styles from './index.module.scss'
import { TableLevelSpecialCampaign, TableCustomerSpecialCampaign, TableNormalCampaign } from 'container'
import { ECampaignType } from 'utils/generated'

const TargetOfCondition: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  const LevelTarget = ['Level 1', 'Level 2', 'Level 3']
  const LevelMonth = useMemo(() => {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const monthDifference = moment(values.campaign.timeDuration[1])
      .endOf('month')
      .diff(moment(values.campaign.timeDuration[0]).startOf('month'), 'months')

    const listMonth = []
    const startMonth = moment(values.campaign.timeDuration[0]).month()
    for (let index = startMonth; index <= startMonth + monthDifference; index++) {
      if (index > 11) {
        listMonth.push(month[index - 12])
      } else {
        listMonth.push(month[index])
      }
    }
    return listMonth
  }, [values.campaign.timeDuration])

  const renderTable = (typeCampaign: string): JSX.Element => {
    switch (typeCampaign) {
      case ECampaignType.SpecialPlusBonusPoint:
        return <TableLevelSpecialCampaign level={['Level1']} />
      case ECampaignType.SpecialStepLevelTargeting:
        return (
          <section className={styles.tableCard}>
            <TableLevelSpecialCampaign level={LevelTarget} />
            <TableCustomerSpecialCampaign level={LevelTarget} />
          </section>
        )
      case ECampaignType.SpecialAchievedTargeting:
        return (
          <section className={styles.tableCard}>
            <TableLevelSpecialCampaign level={['Level1']} />
            <TableCustomerSpecialCampaign level={['Level1']} />
          </section>
        )
      case ECampaignType.SpecialMonthlyEachTargeting:
      case ECampaignType.SpecialMonthlyAccTargeting:
        return (
          <section className={styles.tableCard}>
            <TableLevelSpecialCampaign level={LevelMonth} />
            <TableCustomerSpecialCampaign level={LevelMonth} />
          </section>
        )
      default:
        return <TableNormalCampaign />
    }
  }

  return (
    <section className={styles.container}>
      <h5>{t<string>('createNormalCampaign.target')}</h5>
      {renderTable(values.campaign.campaignType)}
    </section>
  )
}
export default TargetOfCondition
