// Lib
import React from 'react'

// Include in project
import styles from './index.module.scss'
import { Props } from './config'

const CardTotalPointHistory: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.cardTotal}>
      <h6>{props.title}</h6>
      <h3>
        {props.summary} <pre className={styles.text}>{props.unit}</pre>
      </h3>
    </div>
  )
}
export default CardTotalPointHistory
