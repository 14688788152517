// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'

// Include in project
import Layout from 'layouts'
import { HeadFilterByStatus } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { useCampaignList, useInfiniteScroll } from 'hooks'
import { ECampaignListType } from 'utils/generated'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'

const ReviewCampaignList: React.FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()

  const { data: resCampaignsList, refetch } = useCampaignList({
    pageSize: 25,
    status: values.status,
    type: ECampaignListType.All,
    nextToken: '',
  })

  const { dataList } = useInfiniteScroll({
    result: resCampaignsList,
    refetch: refetch,
    variables: {
      pageSize: 25,
      status: values.status,
      type: ECampaignListType.All,
      nextToken: resCampaignsList?.nextToken,
    },
    filter: values,
    key: 'campaignsList',
  })

  const campaignList = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.ListCampaign,
  })

  return (
    <Layout open={false} section={t('ReviewCampaign.section')}>
      <div className={styles.container}>
        <HeadFilterByStatus />
        <Table
          colSize={{
            campaignID: '150px',
            campaignName: '200px',
            campaignShopType: 'auto',
            targetTypeValueHTML: 'auto',
            campaignType: 'auto',
            timeline: '150px',
            countryName: 'auto',
            lastUpdateFormat: '150px',
            campaignStatusHTML: 'auto',
            view: 'auto',
          }}
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            campaignShopType: 'Target Type',
            targetTypeValueHTML: 'Target Type Value',
            campaignType: 'Campaign Type',
            timeline: 'Timeline',
            countryName: 'Country',
            lastUpdateFormat: 'Last Updated',
            campaignStatusHTML: 'Status',
            view: 'Data',
          }}
          data={campaignList || []}
        />
      </div>
    </Layout>
  )
}

const EnhancedCampaignList = withFormik({
  mapPropsToValues: () => ({
    status: 'ALL',
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(ReviewCampaignList)
export default EnhancedCampaignList
