// Lib
import React from 'react'

// Include in project
import { ChipInput } from 'component'
import { Props } from './config'
import { EUrBoxStatus } from 'utils/generated'

const ChipByStatusUrBox: React.FC<Props> = (props: Props): JSX.Element => {
  switch (props.status.toLocaleUpperCase()) {
    case EUrBoxStatus.Success:
      return <ChipInput color="success" label="Success" />
    case EUrBoxStatus.WaitingReviewPoint:
      return <ChipInput color="warning" label="Waiting Review Point" />
    case EUrBoxStatus.Ready:
      return <ChipInput color="info" label="Ready" />
    case EUrBoxStatus.NoData:
      return <ChipInput color="disabled" label="No Data" />
    case EUrBoxStatus.Pending:
      return <ChipInput color="disabled" label="Pending" />
    case EUrBoxStatus.Failed:
      return <ChipInput color="danger" label="Failed" />
    default:
      return <></>
  }
}

export default ChipByStatusUrBox
