// Lib
import React, { useRef } from 'react'

//Images
import calendarSVG from 'images/commons/calendar.svg'

//Include Project
import styles from './index.module.scss'
import { CampaignWithMonthSpan } from 'pages/CampaignCalendar'

const PRE_WIDTH = 60
const COLUMN_WIDTH = 120
const ROW_HEIGHT = 70
const BORDER_PX = 2.4

const Calendar: React.FC<{
  optionList: Record<string, string | number>[]
  campaignCalendar: Record<string, CampaignWithMonthSpan[][]>
}> = ({ optionList, campaignCalendar }) => {
  const provinceID2Code = optionList.reduce<Record<string, string>>((prev, curr) => {
    prev[curr.value] = curr.label as string
    return prev
  }, {})
  const campaignFlatten = Object.keys(campaignCalendar).flatMap((targetTypeID) => [...campaignCalendar[targetTypeID]])

  const tableRef = useRef<HTMLTableElement>(null)
  const tableBBox = tableRef.current?.getBoundingClientRect()

  return (
    <div className={styles.scrollX}>
      <table
        ref={tableRef}
        className={styles.tableMain}
        style={{
          gridTemplateColumns: `${PRE_WIDTH}px repeat(12, ${COLUMN_WIDTH}px)`,
          gridAutoRows: `${ROW_HEIGHT}px`,
        }}
      >
        {/* Head to get Month Row */}
        <thead>
          <tr>
            <th></th>
            {monthList.map((mo, i) => (
              <th key={i}>
                <h5>{mo}</h5>
              </th>
            ))}
          </tr>
        </thead>
        {/* Actual Calendar */}
        <tbody>
          {Object.keys(campaignCalendar).flatMap((targetTypeID, targetTypeIdx) => {
            // Start with 2 because it's 1-index and 1 is header!
            const rowStart = Object.keys(campaignCalendar)
              .slice(0, targetTypeIdx)
              .reduce<number>((prev, curr) => prev + campaignCalendar[curr].length, 2)
            const rowSpan = campaignCalendar[targetTypeID].length
            return [
              <tr key={targetTypeID}>
                <th style={{ gridRow: `${rowStart} / span ${rowSpan}`, wordBreak: 'break-word' }}>
                  {provinceID2Code[targetTypeID]}
                </th>
                {[...Array(12 * rowSpan).keys()].map((idx) => (
                  <td key={`${targetTypeID}-${idx}`} aria-label={`${idx}`}></td>
                ))}
              </tr>,
            ]
          })}
        </tbody>
      </table>
      {/* Actual Span of Events */}
      {tableBBox &&
        campaignFlatten.map((rowList, rowIdx) =>
          rowList.map((campaign, campaignIdx) => {
            const colorIdx = (rowIdx * 3 + campaignIdx) % listColor.length
            const color = listColor[colorIdx].name
            return (
              <div
                key={`${rowIdx}-${campaignIdx}`}
                style={{
                  position: 'absolute',
                  width: `${COLUMN_WIDTH * campaign.monthSpan}px`,
                  height: `${ROW_HEIGHT - BORDER_PX * 2}px`,
                  top: ROW_HEIGHT + ROW_HEIGHT * rowIdx - BORDER_PX / 2,
                  left: PRE_WIDTH + COLUMN_WIDTH * campaign.startMonth + BORDER_PX / 2,
                  zIndex: 10,
                }}
              >
                <RenderSchedue
                  rangeDate={`${String(campaign.startDay).padStart(2, '0')}/${String(campaign.startMonth + 1).padStart(
                    2,
                    '0',
                  )} - ${String(campaign.endDay).padStart(2, '0')}/${String(campaign.endMonth + 1).padStart(2, '0')}`}
                  detail={campaign.campaignName as string}
                  color={color as 'red' | 'green' | 'orange' | 'purple'}
                />
              </div>
            )
          }),
        )}
    </div>
  )
}

export default Calendar

const RenderSchedue: React.FC<{ rangeDate: string; detail: string; color: 'red' | 'green' | 'orange' | 'purple' }> = ({
  rangeDate,
  detail,
  color,
}) => {
  const codeBG = color2Code[color]
  const codeBorder = color2Border[color]

  return (
    <div
      style={{
        background: codeBG,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        height: '100%',
        borderRadius: '4px',
        borderLeft: `3px solid ${codeBorder}`,
        color: codeBorder,
        padding: '2px',
      }}
    >
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <p>{rangeDate}</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: codeBorder,
            borderRadius: '100px',
            padding: '3px',
            width: 12,
            height: 12,
          }}
        >
          <img src={calendarSVG} alt="calendarSVG" />
        </div>
      </div>

      <p>{detail}</p>
    </div>
  )
}

const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

interface Color {
  name: string
  code: string
  codeBorder: string
}

const listColor: Color[] = [
  {
    name: 'red',
    code: '#FFE4E6',
    codeBorder: '#F43F5E',
  },
  {
    name: 'green',
    code: '#E8F8F3',
    codeBorder: '#10B981',
  },
  {
    name: 'orange',
    code: '#FEF6E7',
    codeBorder: '#F59E0B',
  },
  {
    name: 'purple',
    code: '#F4EFFF',
    codeBorder: '#8B5CF6',
  },
]
const color2Code = listColor.reduce<Record<string, string>>((prev, curr) => {
  prev[curr.name] = curr.code
  return prev
}, {})
const color2Border = listColor.reduce<Record<string, string>>((prev, curr) => {
  prev[curr.name] = curr.codeBorder
  return prev
}, {})
