import { useMutation } from '@apollo/client'
import { UPDATE_USER_PERMISSTION } from 'queries/userPermission.queries'
import { useHandleResponseAPI } from 'hooks'

function useUserPermission() {
  const [updateUserPermission, optionUpdate] = useMutation(UPDATE_USER_PERMISSTION)

  const errMsg = () => {
    if (optionUpdate?.error || optionUpdate.data?.updateUserPermission?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateUserPermission?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: optionUpdate.loading,
    errMsg: errMsg(),
  })

  return { updateUserPermission }
}

export default useUserPermission
