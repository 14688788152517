// Lib
import React from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import parse from 'html-react-parser'

// Include in project
import { TimelineCampaign } from 'container'
import { ViewInTable, ChipByStatusCampaign, ChipInput, ChipByStatusPoint } from 'component'
import {
  Campaign,
  CampaignRes,
  CampaignTargetTypeValueGet,
  Product,
  RewardPoint,
  Tags,
  TargetProductList,
  UserPermissionInput,
  User,
  Zone,
  Province,
  District,
  Segment,
  Brand,
  Unit,
  ECampaignStatus,
  BigRetailer,
  ShopRetailer,
  DataSource,
  EPointStatus,
  ECampaignType,
} from 'utils/generated'
import { convertDateFormatTimeStamp } from 'utils/convertDate'
import moment from 'moment'
// import { IOption } from 'utils/interface'

export enum ETranformationDataIntoTable {
  ListNormalCampaign = 'LIST_NORMAL_CAMPAIGN',
  ListSpecialCampaign = 'LIST_SPECIAL_CAMPAIGN',
  ListUser = 'LIST_USER',
  ListZone = 'LIST_ZONE',
  ListProvince = 'LIST_PROVINCE',
  ListDistrict = 'LIST_DISTRICT',
  ListSegment = 'LIST_SEGMENT',
  ListBrand = 'LIST_BRAND',
  ListProduct = 'LIST_PRODUCT',
  ListProductTrash = 'LIST_PRODUCT_TRASH',
  ListUnit = 'LIST_UNIT',
  ListTags = 'LIST_TAGS',
  ListCampaign = 'LIST_CAMPAIGN',
  ListShopBigRetailer = 'LIST_SHOP_BIG_RETAILER',
  ListShopBigRetailerTrash = 'LIST_SHOP_BIG_RETAILER_TRASH',
  ListShopRetailer = 'LIST_SHOP_RETAILER',
  ListShopRetailerTrash = 'LIST_SHOP_RETAILER_TRASH',
  ListDataFromDMS = 'LIST_DATA_FROM_DMS',
  ListCampaignReview = 'LIST_CAMPAIGN_REVIEW',
  ListGetCampaignReview = 'LIST_GET_CAMPAIGN_REVIEW',
  ListDetailPoint = 'LIST_DETAIL_POINT',
  ListPointTransaction = 'LIST_POINT_TRANSACTION',
  ListSendPoint = 'LIST_SEND_POINT',
  ListCampaignApprovePoint = 'LIST_CAMPAIGN_APPROVE_POINT',
  ListGetCampaignApprovePoint = 'LIST_GET_CAMPAIGN_APPROVE_POINT',
  ListApproveCalculatePoint = 'LIST_APPROVE_CALCULATE_POINT',
}

export const transformationDataIntoTable = ({
  dataList,
  type,
  handleOpen,
}: {
  dataList:
    | Campaign[]
    | User[]
    | Zone[]
    | Province[]
    | District[]
    | Segment[]
    | Brand[]
    | Product[]
    | Unit[]
    | Tags[]
    | BigRetailer[]
    | ShopRetailer[]
    | DataSource[]
    | any
  type: ETranformationDataIntoTable
  handleOpen?: (
    data:
      | User
      | Zone
      | Province
      | District
      | Segment
      | Brand
      | Product
      | Unit
      | Tags
      | BigRetailer
      | ShopRetailer
      | DataSource
      | any,
  ) => void
}) => {
  const navigate = useNavigate()

  if (!dataList) return []

  switch (type) {
    case ETranformationDataIntoTable.ListNormalCampaign:
      return (dataList as Campaign[]).map((data) => {
        return {
          ...data,
          promotion: data.promotion ? parse((data.promotion as string[]).join('<br />')) : '',
          campaignStatusHTML: <ChipByStatusCampaign status={data.campaignStatus as string} />,
          timeline: <TimelineCampaign startDate={data.restrictionStartDate} endDate={data.restrictionEndDate} />,
          view: (
            <ViewInTable
              label="View"
              funtiononClick={() => navigate(`/campaign/list-normal/create?campaignID=${data.campaignID}`)}
            />
          ),
        }
      })

    case ETranformationDataIntoTable.ListSpecialCampaign:
      return (dataList as Campaign[]).map((data) => {
        return {
          ...data,
          promotion: data.promotion ? parse((data.promotion as string[]).join('<br />')) : '',
          campaignStatusHTML: <ChipByStatusCampaign status={data.campaignStatus as string} />,
          targetTypeValueHTML: (
            <div className="groupChip">
              {(data.campaignTargetTypeValue as CampaignTargetTypeValueGet[]).map(
                (item: CampaignTargetTypeValueGet, index: number) => {
                  return <ChipInput key={index} color={'primary'} label={item.targetTypeName as string} />
                },
              )}
            </div>
          ),
          timeline: <TimelineCampaign startDate={data.restrictionStartDate} endDate={data.restrictionEndDate} />,
          view: (
            <ViewInTable
              label="View"
              funtiononClick={() => navigate(`/campaign/list-special/create?campaignID=${data.campaignID}`)}
            />
          ),
        }
      })

    case ETranformationDataIntoTable.ListUser:
      return (dataList as User[]).map((data) => {
        return {
          ...data,
          actionView: <ViewInTable label="View" funtiononClick={() => handleOpen?.(data)} />,
          statusHTML: data.isActive ? <p className="textGreen">Active</p> : <p className="textGray">Inactive</p>,
        }
      })

    case ETranformationDataIntoTable.ListZone:
    case ETranformationDataIntoTable.ListProvince:
    case ETranformationDataIntoTable.ListDistrict:
    case ETranformationDataIntoTable.ListSegment:
    case ETranformationDataIntoTable.ListBrand:
    case ETranformationDataIntoTable.ListUnit:
    case ETranformationDataIntoTable.ListTags:
      return (dataList as Zone[] | Province[] | District[] | Segment[] | Brand[] | Unit[] | Tags[]).map((data) => {
        return {
          ...data,
          actionView: <ViewInTable label="View" funtiononClick={() => handleOpen?.(data)} />,
        }
      })
    case ETranformationDataIntoTable.ListProduct:
      return (dataList as Product[]).map((data) => {
        return {
          ...data,
          action: (
            <ViewInTable
              label="View"
              funtiononClick={() =>
                handleOpen?.({
                  ...data,
                  tagList: (data.tagList as Tags[]).map((tag: Tags) => ({
                    value: tag.tagID,
                    label: tag.tagName,
                  })),
                })
              }
            />
          ),
        }
      })
    case ETranformationDataIntoTable.ListShopBigRetailer:
    case ETranformationDataIntoTable.ListShopRetailer:
      return (dataList as BigRetailer[] | ShopRetailer[]).map((data) => {
        return {
          ...data,
          isLineVerify: (
            <div className="groupChip">
              <ChipInput color={data?.isLineVerify ? 'success' : 'primary'} label={`${data?.isLineVerify}`} />
            </div>
          ),
          tagListHTML: (
            <div className="groupChip">
              {(data.tagList as Tags[]).map((element, index: number) => {
                return <ChipInput key={index} color={'primary'} label={element.tagName} />
              })}
            </div>
          ),
          action: (
            <ViewInTable
              label="View"
              funtiononClick={() =>
                handleOpen?.({
                  ...data,
                  tagList: (data.tagList as Tags[]).map((tag: Tags) => ({
                    value: tag.tagID,
                    label: tag.tagName,
                  })),
                })
              }
            />
          ),
        }
      })
    case ETranformationDataIntoTable.ListProductTrash:
    case ETranformationDataIntoTable.ListShopBigRetailerTrash:
    case ETranformationDataIntoTable.ListShopRetailerTrash:
      return (dataList as Product[] | BigRetailer[] | ShopRetailer[]).map((data) => {
        return {
          ...data,
          tagListHTML: (
            <div className="groupChip">
              {(data.tagList as Tags[]).map((element: any, index: number) => {
                return <ChipInput key={index} color={'primary'} label={element.tagName} />
              })}
            </div>
          ),
        }
      })
    case ETranformationDataIntoTable.ListDataFromDMS:
      return (dataList as DataSource[]).map((data) => {
        return {
          ...data,
          inputDateFormat: moment(data.inputDate).format('l'),
          actionView: <ViewInTable label="Adjust" funtiononClick={() => handleOpen?.(data)} />,
        }
      })

    case ETranformationDataIntoTable.ListCampaign:
      return (dataList as Campaign[]).map((data) => {
        return {
          ...data,
          campaignStatusHTML: <ChipByStatusCampaign status={data.campaignStatus as string} />,
          targetTypeValueHTML: (
            <div className="groupChip">
              {data.campaignTargetTypeValue ? (
                (data.campaignTargetTypeValue as CampaignTargetTypeValueGet[]).map(
                  (item: CampaignTargetTypeValueGet, index: number) => {
                    return <ChipInput key={index} color="primary" label={item.targetTypeName as string} />
                  },
                )
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          timeline: <TimelineCampaign startDate={data.restrictionStartDate} endDate={data.restrictionEndDate} />,
          lastUpdateFormat: <p>{convertDateFormatTimeStamp(data.updatedAt)}</p>,
          view: (
            <ViewInTable
              label="View"
              funtiononClick={() =>
                navigate(
                  `/review-and-approval/review-campaign-list/detail?campaignID=${data.campaignID}&campaignType=${data.campaignType}`,
                )
              }
            />
          ),
        }
      })

    case ETranformationDataIntoTable.ListCampaignReview:
      return (dataList as Campaign[]).map((data) => {
        return {
          ...data,
          pointApprovalStatusHTML: <ChipByStatusPoint status={data.pointStatus as string} />,
          campaignStatusHTML: <ChipByStatusCampaign status={data.campaignStatus as string} />,
          updatedAtHTML: convertDateFormatTimeStamp(data.updatedAt),
          targetTypeValueHTML: (
            <div className="groupChip">
              {data.campaignTargetTypeValue ? (
                (data.campaignTargetTypeValue as CampaignTargetTypeValueGet[]).map(
                  (item: CampaignTargetTypeValueGet, index: number) => {
                    return <ChipInput key={index} color="primary" label={item.targetTypeName as string} />
                  },
                )
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          shopTagsListHTML: (
            <div className="groupChip">
              {data.shopTagsList?.length !== 0 ? (
                (data.shopTagsList as Tags[]).map((item: Tags, index: number) => {
                  return <ChipInput key={index} color="primary" label={item.tagName as string} />
                })
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          timeline: <TimelineCampaign startDate={data.restrictionStartDate} endDate={data.restrictionEndDate} />,
          lastUpdateFormat: <p>{convertDateFormatTimeStamp(data.updatedAt)}</p>,
          view: (
            <ViewInTable
              label="View"
              funtiononClick={() =>
                navigate(
                  `/check-point/campaign-list/detail?campaignID=${data.campaignID}&campaignType=${data.campaignType}`,
                )
              }
            />
          ),
        }
      })

    case ETranformationDataIntoTable.ListGetCampaignReview:
    case ETranformationDataIntoTable.ListGetCampaignApprovePoint:
      return (dataList as Campaign[]).map((data) => {
        return {
          ...data,
          campaignStatusHTML: <ChipByStatusCampaign status={data.campaignStatus as string} />,
          updatedAtHTML: convertDateFormatTimeStamp(data.updatedAt),
          pointApprovalStatusHTML: <ChipByStatusPoint status={data.pointStatus as string} />,
          targetTypeValueHTML: (
            <div className="groupChip">
              {data.campaignTargetTypeValue ? (
                (data.campaignTargetTypeValue as CampaignTargetTypeValueGet[]).map(
                  (item: CampaignTargetTypeValueGet, index: number) => {
                    return <ChipInput key={index} color="primary" label={item.targetTypeName as string} />
                  },
                )
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          shopTagsListHTML: (
            <div className="groupChip">
              {data.shopTagsList?.length !== 0 ? (
                (data.shopTagsList as Tags[])?.map((item: Tags, index: number) => {
                  return <ChipInput key={index} color="primary" label={item.tagName as string} />
                })
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          timeline: <TimelineCampaign startDate={data.restrictionStartDate} endDate={data.restrictionEndDate} />,
          lastUpdateFormat: <p>{convertDateFormatTimeStamp(data.updatedAt)}</p>,
        }
      })

    case ETranformationDataIntoTable.ListDetailPoint:
    case ETranformationDataIntoTable.ListSendPoint:
    case ETranformationDataIntoTable.ListApproveCalculatePoint:
      return dataList.map((data: any) => {
        return {
          ...data,
          lastUpdateFormat: moment(data.updatedAt).format('l'),
          AccPointHTML: <p>{data.point} Star</p>,
          TotalPointHTML: <p>{data?.totalPoint} Star</p>,
          actionView: <ViewInTable label="View" funtiononClick={() => handleOpen?.(data)} />,
        }
      })
    case ETranformationDataIntoTable.ListPointTransaction:
      return dataList.map((data: any) => {
        return {
          ...data,
          timelineFormat: convertDateFormatTimeStamp(data.inputDate),
        }
      })
    case ETranformationDataIntoTable.ListCampaignApprovePoint:
      return (dataList as Campaign[]).map((data) => {
        return {
          ...data,
          targetTypeValueHTML: (
            <div className="groupChip">
              {data.campaignTargetTypeValue ? (
                (data.campaignTargetTypeValue as CampaignTargetTypeValueGet[]).map(
                  (item: CampaignTargetTypeValueGet, index: number) => {
                    return <ChipInput key={index} color="primary" label={item.targetTypeName as string} />
                  },
                )
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          shopTagsListHTML: (
            <div className="groupChip">
              {data.shopTagsList?.length !== 0 ? (
                (data.shopTagsList as Tags[]).map((item: Tags, index: number) => {
                  return <ChipInput key={index} color="primary" label={item.tagName as string} />
                })
              ) : (
                <p>-</p>
              )}
            </div>
          ),
          timeline: <TimelineCampaign startDate={data.restrictionStartDate} endDate={data.restrictionEndDate} />,
          lastUpdateFormat: <p>{convertDateFormatTimeStamp(data.updatedAt)}</p>,
          pointApprovalStatusHTML: <ChipByStatusPoint status={data.pointStatus as string} />,
          campaignStatusHTML: <ChipByStatusCampaign status={data.campaignStatus as string} />,
          actionView: (
            <ViewInTable
              label="View"
              funtiononClick={() =>
                navigate(
                  `/approve-point/campaign-list/detail?campaignID=${data.campaignID}&campaignType=${data.campaignType}`,
                )
              }
            />
          ),
        }
      })

    default:
      return []
  }
}

export enum ETransformationDataToServer {
  UpdateNormalCampaign = 'UPDATE_NORMAL_CAMPAIGN',
  UpdateSpecialCampaign = 'UPDATE_SPECIAL_CAMPAIGN',
  RequestApproveCampaign = 'REQUEST_APPROVE_CAMPAIGN',
  RequestApproveCalculatePoint = 'REQUEST_APPROVE_CALCULATE_POINT',
  UpdateUserPermission = 'UPDATE_USER_PERMISSION',
  UpdateUser = 'UPDATE_USER',
  UpdateZone = 'UPDATE_ZONE',
  UpdateProvince = 'UPDATE_PROVINCE',
  UpdateDistrict = 'UPDATE_DISTRICT',
  UpdateSegment = 'UPDATE_SEGMENT',
  UpdateBrand = 'UPDATE_BRAND',
  UpdateProduct = 'UPDATE_PRODUCT',
  UpdateUnit = 'UPDATE_UNIT',
  UpdateTags = 'UPDATE_TAGS',
  DeleteMasterData = 'DELETE_MASTER_DATA',
  RecoveryMasterData = 'RECOVERY_MASTER_DATA',
  ApproveCampaign = 'APPROVE_CAMPAIGN',
  RejectCampaign = 'REJECT_CAMPAIGN',
  UpdateShopBigRetailer = 'UPDATE_SHOP_BIG_RETAILER',
  UpdateShopRetailer = 'UPDATE_SHOP_RETAILER',
  AdjustSaleVolume = 'ADJUST_SALE_VOLUME',
  AdHocData = 'ADHOC_DATA',
  CreatePointAdjustmen = 'CREATE_POINT_ADJUSTMENT',
  TopUpThePoint = 'TOP_UP_THE_POINT',
  ApprovePointCampaign = 'APPROVE_POINT_CAMPAIGN',
  RejectPointCampaign = 'REJECT_POINT_CAMPAIGN',
  ReadNotification = 'READ_NOTIFICATION',
  ManualImportData = 'MANUAL_IMPORT_DATA',
}

export const transformationDataToServer = ({ values, type }: { values: any; type: ETransformationDataToServer }) => {
  switch (type) {
    case ETransformationDataToServer.UpdateNormalCampaign:
      return {
        input: {
          campaignID: values.campaignID,
          campaignName: values.campaignName,
          campaignDescription: values.campaignDescription,
          campaignGoal: values.campaignGoal,
          productList: packValueProductList(values.productList),
          restrictionStartDate: new Date(
            new Date(values.timeDuration[0]).setMinutes(
              new Date(values.timeDuration[0]).getMinutes() - new Date(values.timeDuration[0]).getTimezoneOffset(),
            ),
          ).toISOString(),
          restrictionEndDate: new Date(
            new Date(values.timeDuration[1]).setMinutes(
              new Date(values.timeDuration[1]).getMinutes() - new Date(values.timeDuration[1]).getTimezoneOffset(),
            ),
          ).toISOString(),
          documents: values.imageList,
        },
      }
    case ETransformationDataToServer.UpdateSpecialCampaign:
      const month = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const monthDifference = moment(values.timeDuration[1])
        .endOf('month')
        .diff(moment(values.timeDuration[0]).startOf('month'), 'months')
      const listMonth: string[] = []
      const startMonth = moment(values.timeDuration[0]).month()
      for (let index = startMonth; index <= startMonth + monthDifference; index++) {
        if (index > 11) {
          listMonth.push(month[index - 12])
        } else {
          listMonth.push(month[index])
        }
      }

      return {
        input: {
          campaignID: values.campaignID,
          campaignType: values.campaignType,
          campaignName: values.campaignName,
          campaignDescription: values.campaignDescription,
          campaignGoal: values.campaignGoal,
          campaignShopType: values.shopType,
          campaignTargetType: values.targetType,
          campaignTargetTypeValue: values.targetTypeValueDummy.map((data: any) => ({
            targetTypeID: data.value,
          })),
          targetProductList: values.targetProduct.map((data: any) => {
            if (
              values.campaignType === 'SPECIAL_MonthlyAccTargeting' ||
              values.campaignType === 'SPECIAL_MonthlyEachTargeting'
            ) {
              const levelList = []
              for (let iLevel = 0; iLevel < monthDifference + 1; iLevel++) {
                levelList.push({
                  level: `${listMonth[iLevel]}`,
                  point: data.levelList[iLevel].point,
                })
              }

              return {
                tagID: data.value,
                levelList: levelList,
              }
            } else {
              return {
                tagID: data.value,
                levelList: data.levelList.map((level: any, index: number) => ({
                  level: `Level ${index + 1}`,
                  point: level.point,
                })),
              }
            }
          }),
          shopTagsList: values.tagRetainer.map((data: any) => ({
            tagID: data.value,
          })),
          rewardPointList: values.targetTypeValue.map((data: any) => ({
            targetTypeID: data.value,
            pointReward: data.productList.map((product: any) => ({
              tagID: product.productID,
              levelList: product.targetList.map((target: any, index: number) => {
                if (
                  values.campaignType === 'SPECIAL_MonthlyAccTargeting' ||
                  values.campaignType === 'SPECIAL_MonthlyEachTargeting'
                ) {
                  return {
                    level: `${listMonth[index]}`,
                    target: target.amount,
                  }
                } else {
                  return {
                    level: `Level ${index + 1}`,
                    target: target.amount,
                  }
                }
              }),
            })),
          })),
          restrictionStartDate: new Date(
            new Date(values.timeDuration[0]).setMinutes(
              new Date(values.timeDuration[0]).getMinutes() - new Date(values.timeDuration[0]).getTimezoneOffset(),
            ),
          ).toISOString(),
          restrictionEndDate: new Date(
            new Date(values.timeDuration[1]).setMinutes(
              new Date(values.timeDuration[1]).getMinutes() - new Date(values.timeDuration[1]).getTimezoneOffset(),
            ),
          ).toISOString(),
          // restrictionStartDate: convertDateToISO(values.timeDuration[0]),
          // restrictionEndDate: convertDateToISO(values.timeDuration[1]),
          isSubRTLCal: values.isSubRTLCal,
          documents: values.imageList,
        },
      }
    case ETransformationDataToServer.RequestApproveCampaign:
      return {
        input: {
          id: values.campaignID,
        },
      }
    case ETransformationDataToServer.UpdateUserPermission:
      return {
        input: {
          items: values.map((data: UserPermissionInput) => {
            return _.omit(data, '__typename')
          }),
        },
      }
    case ETransformationDataToServer.UpdateUser:
      return {
        input: {
          userID: values.userID,
          countryID: values.countryID,
          name: values.name,
          email: values.email,
          userRoleID: values.userRoleID,
          isActive: values.isActive,
          password: values.password,
        },
      }
    case ETransformationDataToServer.UpdateZone:
      return {
        input: {
          zoneID: values.zoneID,
          zoneName: values.zoneName,
        },
      }
    case ETransformationDataToServer.UpdateProvince:
      return {
        input: {
          provinceID: values.provinceID,
          provinceCode: values.provinceCode,
          provinceName: values.provinceName,
          zoneID: values.zoneID,
        },
      }
    case ETransformationDataToServer.UpdateDistrict:
      return {
        input: {
          districtID: values.districtID,
          districtName: values.districtName,
          provinceID: values.provinceID,
        },
      }
    case ETransformationDataToServer.UpdateSegment:
      return {
        input: {
          segmentID: values.segmentID,
          segmentName: values.segmentName,
        },
      }
    case ETransformationDataToServer.UpdateBrand:
      return {
        input: {
          productBrandID: values.productBrandID,
          brandName: values.brandName,
        },
      }
    case ETransformationDataToServer.UpdateProduct:
      return {
        input: {
          productID: values.productID,
          productName: values.productName,
          productBrandID: values.productBrandID,
          productUnit_id: values.productUnit_id,
          tagList: packTagList(values.tagList),
        },
      }
    case ETransformationDataToServer.UpdateUnit:
      return {
        input: {
          id: values.id,
          unitName: values.unitName,
        },
      }
    case ETransformationDataToServer.UpdateTags:
      return {
        input: {
          tagID: values.tagID,
          tagName: values.tagName,
          typeTag: values.typeTag,
          tagColor: 'RED', // values.tagColor,
        },
      }
    case ETransformationDataToServer.DeleteMasterData:
      return {
        input: {
          ID: values,
        },
      }
    case ETransformationDataToServer.RecoveryMasterData:
      return {
        input: {
          listID: values.map((data: string) => parseInt(data)),
        },
      }
    case ETransformationDataToServer.UpdateShopBigRetailer:
      return {
        input: {
          bigRetailerID: values.bigRetailerID,
          bigRetailerName: values.bigRetailerName,
          bigRetailerCode: values.bigRetailerCode,
          districtID: values.districtID,
          email: values.email,
          phoneNumber: values.phoneNumber,
          provinceID: values.provinceID,
          segmentID: values.segmentID,
          ownRetailerID: values.ownRetailerID,
          tagList: packTagList(values.tagList),
          zaloID: values.zaloID,
          zoneID: values.zoneID,
          isActive: true,
          addressID: values.addressID,
          lineUserID: values.lineUserID,
          isLineVerify: values.isLineVerify,
        },
      }
    case ETransformationDataToServer.UpdateShopRetailer:
      return {
        input: {
          districtID: values.districtID,
          email: values.email,
          isActive: true,
          phoneNumber: values.phoneNumber,
          provinceID: values.provinceID,
          retailerCode: values.retailerCode,
          retailerID: values.retailerID,
          retailerName: values.retailerName,
          segmentID: values.segmentID,
          zaloID: values.zaloID,
          tagList: packTagList(values.tagList),
          zoneID: values.zoneID,
          addressID: values.addressID,
          lineUserID: values.lineUserID,
          isLineVerify: values.isLineVerify,
        },
      }
    case ETransformationDataToServer.AdjustSaleVolume:
      return {
        input: {
          dataSourceID: values.dataSourceID,
          adjustmentReason: values.adjustmentReason,
          saleVolume: values.saleVolume,
        },
      }

    case ETransformationDataToServer.ApproveCampaign:
      return {
        input: {
          campaignID: values.campaignID,
          campaignStatus: ECampaignStatus.Active,
        },
      }
    case ETransformationDataToServer.RejectCampaign:
      return {
        input: {
          campaignID: values.campaignID,
          campaignStatus: ECampaignStatus.Rejected,
          remark: values.remark,
        },
      }

    case ETransformationDataToServer.AdHocData:
      return {
        input: {
          month: values.month,
          year: values.year,
        },
      }

    case ETransformationDataToServer.RequestApproveCalculatePoint:
      if (values.campaignType === ECampaignType.Normal) {
        return {
          input: {
            campaignID: values.campaignID,
            month: values.month,
            year: values.year,
          },
        }
      } else {
        // This is case campaign special
        return {
          input: {
            campaignID: values.campaignID,
            month: null,
            year: null,
          },
        }
      }
    case ETransformationDataToServer.CreatePointAdjustmen:
      return {
        input: {
          adjustmentReason: values.pointAdjustment.adjustmentReason,
          campaignID: values.campaignDetail[0].campaignID,
          month: values.filter.month,
          point: values.pointAdjustment.point,
          shopID: values.pointHistoryDetail.shopID,
          shopType: values.pointHistoryDetail.shopType,
          year: values.filter.year,
        },
      }
    case ETransformationDataToServer.TopUpThePoint:
      return {
        year: values.year,
        month: values.month,
      }
    case ETransformationDataToServer.ApprovePointCampaign:
      return {
        input: {
          campaignID: values.campaignID,
          pointStatus: EPointStatus.Approved,
        },
      }
    case ETransformationDataToServer.RejectPointCampaign:
      return {
        input: {
          campaignID: values.campaignID,
          pointStatus: EPointStatus.Rejected,
          remarkPoint: values.remark,
        },
      }

    case ETransformationDataToServer.ReadNotification:
      return {
        input: {
          notificationID: null,
          permission: values,
        },
      }

    case ETransformationDataToServer.ManualImportData:
      return {
        input: {
          month: values.month,
          year: values.year,
          items: values.items.map((item: any) => ({
            sourceType: 'EXCEL',
            inputDate: item['input_date'],
            orderCode: item['order_code'],
            dmsCode: item['dms_code'],
            code: item['code'],
            retailerName: item['name'],
            year: parseInt(item['year']),
            month: parseInt(item['month']),
            productBrand: item['brand'],
            productName: item['product_name'],
            saleVolume: parseFloat(item['sale_volume']),
            unitOrder: item['status_of_order'],
            zone: item['zone'],
            province: item['province'],
            district: item['district'],
            segment: item['segment'],
            retailerLevel1Name: item['rtl_level_1'],
            shopType: item['shop_type'],
            phoneNumber: item['phone_number'],
            zaloID: item['zaloid'],
            email: item['email'],
            dataSourceStatus: '',
            countryID: 1,
            adjustmentByName: null,
            adjustmentByUserID: null,
            adjustmentReason: null,
          })),
        },
      }
    default:
      break
  }
}

export enum ETranformationDataIntoClient {
  DetailNormalCampaign = 'DETAIL_NORMAL_CAMPAIGN',
  DetailSpecialCampaign = 'DETAIL_SPECIAL_CAMPAIGN',
}

export const tranformationDataIntoClient = ({
  values,
  type,
}: {
  values: CampaignRes
  type: ETranformationDataIntoClient
}) => {
  switch (type) {
    case ETranformationDataIntoClient.DetailNormalCampaign:
      return {
        campaignID: values.campaignID,
        campaignName: values.campaignName,
        campaignDescription: values.campaignDescription,
        campaignStatus: values.campaignStatus,
        campaignGoal: values.campaignGoal,
        campaignType: values.campaignType,
        productList: unPackValueProductList(values.productList as Product[]),
        productListDummy: tranformDataToOption(values.productList as Product[]),
        timeDuration: [new Date(values.restrictionStartDate), new Date(values.restrictionEndDate)],
        imageList: values.documents || [],
        remark: values.remark,
      }

    case ETranformationDataIntoClient.DetailSpecialCampaign:
      return {
        // Value in container SetupNewCampaignModeEdit
        campaignName: values.campaignName,
        campaignDescription: values.campaignDescription,
        campaignType: values.campaignType,
        campaignGoal: values.campaignGoal,

        // Value in container CampaignRestrictionsSpecialModeEdit
        shopType: values.campaignShopType || '',
        targetType: values.campaignTargetType,
        targetTypeValue: (values.rewardPointList as RewardPoint[]).map((data: any) => ({
          value: data.targetTypeID,
          label: data.targetTypeName,
          productList: data.pointReward.map((product: any) => ({
            productID: product.tagID,
            productName: product.tagName,
            targetList: product.levelList.map((target: any) => ({ amount: target.target })),
          })),
        })),
        targetTypeValueDummy: (values.rewardPointList as RewardPoint[]).map((data: any) => ({
          value: data.targetTypeID,
          label: data.targetTypeName,
        })), // Hack AutoComplete because filterSelectedOptions Not Work if Option and value not match
        tagRetainer: (values.shopTagsList as Tags[]).map((data: any) => ({
          value: data.tagID,
          label: data.tagName,
        })),
        targetProduct: (values.targetProductList as TargetProductList[]).map((data: any) => ({
          value: data.tagID,
          label: data.tagName,
          levelList: data.levelList.map((level: any) => ({ point: level.point })),
        })),
        targetProductDummy: (values.targetProductList as TargetProductList[]).map((data: any) => ({
          value: data.tagID,
          label: data.tagName,
        })), // Hack AutoComplete because filterSelectedOptions Not Work if Option and value not match
        timeDuration: [new Date(values.restrictionStartDate), new Date(values.restrictionEndDate)],
        isSubRTLCal: false,
        imageList: values.documents || [],

        remark: values.remark,

        campaignID: values.campaignID,
        campaignStatus: values.campaignStatus,
      }
    default:
      return
  }
}

export const unPackValueProductList = (valueList: Product[]) => {
  // Receive data from server and tranform data for render in client
  const packVal = valueList.map((element) => ({
    value: element.productID,
    label: element.productName,
    unitName: element.unitName,
    levelList: element.levelList,
  }))
  return packVal
}

export const packValueProductList = (
  valueList: {
    label: string
    unitName: string
    value: number
    levelList: { level: string; point: number }[]
  }[],
) => {
  // Pack data for send to server
  const packVal = valueList.map((element) => ({
    productID: element.value,
    productName: element.label,
    unitName: element.unitName,
    levelList: element.levelList.map((item, index) => ({ level: `Level ${index + 1}`, point: item.point })),
  }))
  return packVal
}

export const tranformDataToOption = (data: Product[]) => {
  return data.map((ele) => ({
    label: ele.productName,
    value: ele.productID,
  }))
}

const packTagList = (tagList: []) => {
  //TODO
  const packVal = tagList.map((element: any) => {
    if (!element.tagID) {
      const value: any = {
        tagID: element.value,
      }
      return value
    } else {
      const value: any = {
        tagID: element.tagID,
      }
      return value
    }
  })
  return packVal
}
