import { gql } from '@apollo/client'

export const GET_LIST_USER_LIST = gql`
  query ListUser($pageSize: Int!, $nextToken: String, $filter: FilterListUserInput) {
    listUser(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      items {
        userID
        name
        countryID
        countryName
        email
        userRole
        createdAt
        updatedAt
        isActive
        userRoleID
      }
      nextToken
    }
  }
`
export const CREATE_USER_LIST = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      res_code
      res_desc
      createdAt
      email
      isActive
      name
      updatedAt
      userID
      userRole
      userRoleID
    }
  }
`

export const UPDATE_USER_LIST = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      countryID
      countryName
      createdAt
      email
      isActive
      name
      res_code
      res_desc
      updatedAt
      userID
      userRole
      userRoleID
    }
  }
`

export const DELETE_USER_LIST = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_USER_LITS = gql`
  mutation RecoveryUser($input: RecoveryUserInput!) {
    recoveryUser(input: $input) {
      res_code
      res_desc
    }
  }
`
