// Lib
import React, { CSSProperties, useEffect, useMemo } from 'react'
import { Snackbar, Slide, Backdrop } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'

//Images
import { ReactComponent as Cross } from 'images/commons/cross.svg'
import { ReactComponent as Danger } from 'images/commons/danger.svg'
import { ReactComponent as Success } from 'images/commons/success.svg'
import { ReactComponent as Info } from 'images/commons/info.svg'
import { ReactComponent as Warning } from 'images/commons/warning.svg'
import { ReactComponent as SendData } from 'images/commons/sendData.svg'

// Include in project
import Color from 'abstracts/export.module.scss'
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseNotification } from 'states/slice/notification'

const AlertIcon: React.FC<{ type: string }> = ({ type }): JSX.Element => {
  switch (type) {
    case 'danger':
      return <Danger className={`${styles.iconAlert}`} />
    case 'success':
      return <Success className={`${styles.iconAlert}`} />
    case 'warning':
      return <Warning className={`${styles.iconAlert}`} />
    case 'info':
      return <Info className={`${styles.iconAlert}`} />
    case 'loading':
      return <SendData className={`${styles.iconAlert}`} />
    default:
      return <></>
  }
}

const NotificationWithRedux: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const notificationReducer = useSelector((state: RootState) => state.notification)

  if (!notificationReducer) return <></>
  else {
    const borderColor = useMemo(() => {
      switch (notificationReducer.typeNotification) {
        case 'danger':
          return `${Color.dangerBorder}`
        case 'success':
          return `${Color.successBorder}`
        case 'warning':
          return `${Color.warningBorder}`
        case 'info':
          return `${Color.infoBorder}`
        case 'loading':
          return `${Color.successBorder}`
        default:
          return ''
      }
    }, [notificationReducer.typeNotification])

    const background = useMemo(() => {
      switch (notificationReducer.typeNotification) {
        case 'danger':
          return `${Color.dangerSecond}`
        case 'success':
          return `${Color.successSecond}`
        case 'warning':
          return `${Color.warningSecond}`
        case 'info':
          return `${Color.infoSecond}`
        case 'loading':
          return `${Color.successSecond}`
      }
    }, [notificationReducer.typeNotification])

    useEffect(() => {
      if (!notificationReducer.isLoading) {
        dispatch(setCloseNotification())
      }
    }, [notificationReducer.isLoading])

    if (notificationReducer.typeNotification === 'loading') {
      return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <Snackbar
            open={notificationReducer.isOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            TransitionComponent={Slide}
            autoHideDuration={null}
          >
            <div
              style={
                {
                  '--background': background,
                  '--borderColor': borderColor,
                } as CSSProperties
              }
              className={`${styles.main}`}
            >
              <div className={`${styles.head}`}>
                <div className={`${styles.titleBox}`}>
                  <AlertIcon type={notificationReducer.typeNotification} />
                  <h5>{notificationReducer.title}</h5>
                </div>
              </div>
              {notificationReducer.isLoading === true && (
                <LinearProgress sx={{ borderRadius: 10, background: 'none' }} color="success" />
              )}

              <div className={styles.text}>
                <p>{notificationReducer.description}</p>
              </div>
            </div>
          </Snackbar>
        </Backdrop>
      )
    } else {
      return (
        <Snackbar
          open={notificationReducer.isOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          TransitionComponent={Slide}
          autoHideDuration={5000}
        >
          <div
            style={
              {
                '--background': background,
                '--borderColor': borderColor,
              } as CSSProperties
            }
            className={`${styles.main}`}
          >
            <div className={`${styles.head}`}>
              <div className={`${styles.titleBox}`}>
                <AlertIcon type={notificationReducer.typeNotification} />
                <h5>{notificationReducer.title}</h5>
              </div>

              <Cross onClick={() => dispatch(setCloseNotification())} className={`${styles.cross}`} />
            </div>

            <div className={styles.padding}>
              <p>{notificationReducer.description}</p>
            </div>
          </div>
        </Snackbar>
      )
    }
  }
}

export default NotificationWithRedux
