import Color from 'abstracts/export.module.scss'

export interface Props {
  typeButton: 'button' | 'submit'
  type: 'primary' | 'secondary' | 'outline' | 'textBtn' | 'darkBtn'
  name?: string
  icon?: string
  functionOnClick?: () => void
  disabled: boolean
  fullWidth?: boolean
}

export const colorButton = {
  primary: {
    backgroundColor: `${Color.primaryMain}`,
    fontWeight: 'Bold',
    '&:hover': {
      backgroundColor: `${Color.primaryHover}`,
    },
    '&:pressed': {
      backgroundColor: `${Color.primaryPressed}`,
    },
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${Color.primaryFocus}`,
    },
  },
  secondary: {
    color: `${Color.primaryMain}`,
    backgroundColor: `${Color.primarySecond}`,
    fontWeight: 'Bold',
    '&:hover': {
      color: `${Color.primaryHover}`,
      backgroundColor: `${Color.primarySecond}`,
    },
    '&:pressed': {
      color: `${Color.primaryPressed}`,
      backgroundColor: `${Color.primarySecond}`,
    },
  },
  outline: {
    color: `${Color.primaryMain}`,
    backgroundColor: `${Color.primarySecond}`,
    border: `1px solid ${Color.primaryMain}`,
    fontWeight: 'Bold',
    '&:hover': {
      color: `${Color.primaryHover}`,
      backgroundColor: `${Color.primarySecond}`,
      border: `1px solid ${Color.primaryHover}`,
    },
    '&:pressed': {
      color: `${Color.primaryPressed}`,
      backgroundColor: `${Color.primarySecond}`,
      border: `1px solid ${Color.primaryPressed}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${Color.grey500}`,
    },
  },
  textBtn: {
    color: `${Color.primaryMain}`,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontWeight: 'Bold',
    '&:hover': {
      color: `${Color.primaryHover}`,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:pressed': {
      color: `${Color.primaryPressed}`,
      backgroundColor: 'transparent',
      boxShadow: `0px 0px 0px 3px ${Color.primaryFocus}`,
    },
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${Color.primaryFocus}`,
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  darkBtn: {
    backgroundColor: `${Color.black}`,
    fontWeight: 'Bold',
    '&:hover': {
      backgroundColor: `${Color.black}`,
    },
    '&:pressed': {
      backgroundColor: `${Color.black}`,
    },
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${Color.black}`,
    },
  },
}
