// Lib
import React, { useState } from 'react'
import { withFormik, useFormikContext, Form } from 'formik'
import Smiley from '@mui/icons-material/SentimentSatisfiedRounded'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Images
import Test from 'images/commons/arrow.svg'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import {
  Accoradion,
  AutoComplete,
  Button,
  ChipInput,
  Radio,
  Rating,
  SelectMutiple,
  SelectSingle,
  Switch,
  TextArea,
  TextField,
  Table,
  Calendar,
  UploadFile,
  StepProgressBar,
  Checkbox,
  DatePickers,
  TextFieldPassword,
} from 'component'
import type { RootState } from 'states/store'
import { setCloseDialog, setOpenDialog, setOpenDialogWithInput } from 'states/slice/modalDialog'
import { setOpenNotification, setOpenNotificationLoading } from 'states/slice/notification'
import { setMsg } from 'states/slice/test'
import { useStepProgressBar } from 'hooks'

const Demo: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const testReducer = useSelector((state: RootState) => state.test)
  const [message, setMessage] = useState<string>('')
  const { activeStep, handleNext, handleBack } = useStepProgressBar()

  return (
    <Layout open={false} section={'Demo Component'}>
      <Form>
        <div className={styles.box}>
          <h3 className={styles.title}>Tag</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Disabled</td>
                  <td>
                    <ChipInput
                      label="Tag"
                      icon={<Smiley />}
                      onDelete={() => console.info('test')}
                      color={'disabled'}
                      disabled={true}
                    />
                    <ChipInput label="Tag" onDelete={() => console.info('test')} color={'disabled'} disabled={true} />
                  </td>
                </tr>
                <tr>
                  <td>Primary</td>
                  <td>
                    <ChipInput
                      label="Tag"
                      icon={<Smiley />}
                      onDelete={() => console.info('test')}
                      color={'primary'}
                      disabled={false}
                    />
                    <ChipInput label="Tag" onDelete={() => console.info('test')} color={'primary'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Danger</td>
                  <td>
                    <ChipInput
                      label="Tag"
                      icon={<Smiley />}
                      onDelete={() => console.info('test')}
                      color={'danger'}
                      disabled={false}
                    />
                    <ChipInput label="Tag" onDelete={() => console.info('test')} color={'danger'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Info</td>
                  <td>
                    <ChipInput
                      label="Tag"
                      icon={<Smiley />}
                      onDelete={() => console.info('test')}
                      color={'info'}
                      disabled={false}
                    />
                    <ChipInput label="Tag" onDelete={() => console.info('test')} color={'info'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Success</td>
                  <td>
                    <ChipInput
                      label="Tag"
                      icon={<Smiley />}
                      onDelete={() => console.info('test')}
                      color={'success'}
                      disabled={false}
                    />
                    <ChipInput label="Tag" onDelete={() => console.info('test')} color={'success'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Warning</td>
                  <td>
                    <ChipInput
                      label="Tag"
                      icon={<Smiley />}
                      onDelete={() => console.info('test')}
                      color={'warning'}
                      disabled={false}
                    />
                    <ChipInput label="Tag" onDelete={() => console.info('test')} color={'warning'} disabled={false} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Accordion</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Off</td>
                  <td>
                    <Accoradion title={'Accordion title'} expanded={false}>
                      <p>True love for whatever you are doing is the answer to everything</p>
                    </Accoradion>
                  </td>
                </tr>
                <tr>
                  <td>On</td>
                  <td>
                    <Accoradion title={'Accordion title'} expanded={true}>
                      <p>True love for whatever you are doing is the answer to everything</p>
                    </Accoradion>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Buttons</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Primary</td>
                  <td>
                    <Button name="Button" typeButton={'submit'} type={'primary'} disabled={false} />
                    <Button name="Button" icon={Test} typeButton={'button'} type={'primary'} disabled={false} />
                    <Button icon={Test} typeButton={'button'} type={'primary'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Secondary</td>
                  <td>
                    <Button name="Button" typeButton={'button'} type={'secondary'} disabled={false} />
                    <Button name="Button" icon={Test} typeButton={'button'} type={'secondary'} disabled={false} />
                    <Button icon={Test} typeButton={'button'} type={'secondary'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Outline</td>
                  <td>
                    <Button name="Button" typeButton={'button'} type={'outline'} disabled={false} />
                    <Button name="Button" icon={Test} typeButton={'button'} type={'outline'} disabled={false} />
                    <Button icon={Test} typeButton={'button'} type={'outline'} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>Text Btn</td>
                  <td>
                    <Button name="Button" typeButton={'button'} type={'textBtn'} disabled={false} />
                    <Button name="Button" icon={Test} typeButton={'button'} type={'textBtn'} disabled={false} />
                    <Button icon={Test} typeButton={'button'} type={'textBtn'} disabled={false} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Modal Dialog With Redux</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Default</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenDialog({
                            title: 'Modal Window',
                            description:
                              'Sometimes people let the same problem make them miserable for years when they could just say, “So what." That’s one of my favorite things to say. “So what."',
                            status: 'default',
                            functionOnSubmit: () => {
                              dispatch(setCloseDialog())
                            },
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'primary'}
                      disabled={false}
                      name={'Modal Dialog'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Danger</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenDialog({
                            title: 'Modal Window',
                            description:
                              'Sometimes people let the same problem make them miserable for years when they could just say, “So what." That’s one of my favorite things to say. “So what."',
                            status: 'danger',
                            functionOnSubmit: () => {
                              dispatch(setCloseDialog())
                            },
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'primary'}
                      disabled={false}
                      name={'Modal Dialog'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Success</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenDialog({
                            title: 'Modal Window',
                            description:
                              'Sometimes people let the same problem make them miserable for years when they could just say, “So what." That’s one of my favorite things to say. “So what."',
                            status: 'success',
                            functionOnSubmit: () => {
                              dispatch(setCloseDialog())
                            },
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'primary'}
                      disabled={false}
                      name={'Modal Dialog'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Warning</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenDialog({
                            title: 'Modal Window',
                            description:
                              'Sometimes people let the same problem make them miserable for years when they could just say, “So what." That’s one of my favorite things to say. “So what."',
                            status: 'warning',
                            functionOnSubmit: () => {
                              dispatch(setCloseDialog())
                            },
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'primary'}
                      disabled={false}
                      name={'Modal Dialog'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Info</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenDialog({
                            title: 'Modal Window',
                            description:
                              'Sometimes people let the same problem make them miserable for years when they could just say, “So what." That’s one of my favorite things to say. “So what."',
                            status: 'info',
                            functionOnSubmit: () => {
                              dispatch(setCloseDialog())
                            },
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'primary'}
                      disabled={false}
                      name={'Modal Dialog'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>With Input</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenDialogWithInput({
                            title: 'Modal Window',
                            description:
                              'Sometimes people let the same problem make them miserable for years when they could just say, “So what." That’s one of my favorite things to say. “So what."',
                            status: 'default',
                            titleInput: 'Comment Text',
                            functionOnSubmit: () => {
                              dispatch(setCloseDialog())
                            },
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'primary'}
                      disabled={false}
                      name={'Modal Dialog'}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Notification With Redux</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Danger</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenNotification({
                            title: 'Modal Window',
                            description:
                              'The function of the artist in a disturbed society is to give awareness of the universe, to ask the right questions, and to elevate the mind.',
                            typeNotification: 'danger',
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'secondary'}
                      disabled={false}
                      name={'Notification'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Success</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenNotification({
                            title: 'Modal Window',
                            description:
                              'The function of the artist in a disturbed society is to give awareness of the universe, to ask the right questions, and to elevate the mind.',
                            typeNotification: 'success',
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'secondary'}
                      disabled={false}
                      name={'Notification'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Warning</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenNotification({
                            title: 'Modal Window',
                            description:
                              'The function of the artist in a disturbed society is to give awareness of the universe, to ask the right questions, and to elevate the mind.',
                            typeNotification: 'warning',
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'secondary'}
                      disabled={false}
                      name={'Notification'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Info</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenNotification({
                            title: 'Modal Window',
                            description:
                              'The function of the artist in a disturbed society is to give awareness of the universe, to ask the right questions, and to elevate the mind.',
                            typeNotification: 'info',
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'secondary'}
                      disabled={false}
                      name={'Notification'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Loading</td>
                  <td>
                    <Button
                      functionOnClick={() =>
                        dispatch(
                          setOpenNotificationLoading({
                            title: 'Send Data',
                            description: 'Sending....',
                            typeNotification: 'loading',
                            isLoading: true,
                          }),
                        )
                      }
                      typeButton={'button'}
                      type={'secondary'}
                      disabled={false}
                      name={'Notification'}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Input</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Type Text</td>
                  <td>
                    <TextField
                      label={'Input_label'}
                      placeholder={'placeholder'}
                      type={'text'}
                      name="textInput"
                      require
                    />
                  </td>
                </tr>
                <tr>
                  <td>Type Number</td>
                  <td>
                    <TextField label={'Input_label'} type={'number'} name="number" require />
                  </td>
                </tr>
                <tr>
                  <td>Type Password</td>
                  <td>
                    <TextFieldPassword label={'Input_label'} name="password" require />
                  </td>
                </tr>
                <tr>
                  <td>Type Disabled</td>
                  <td>
                    <TextField disabled={true} label={'Input_label'} type={'number'} name="disabled" require />
                  </td>
                </tr>
                <tr>
                  <td>Date Range</td>
                  <td>
                    <DatePickers name="dateRange" label="Input_Label" require />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Auto Complete</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Auto Complete</td>
                  <td>
                    <AutoComplete
                      placeholder={'placeholder'}
                      name="autoTest"
                      label={'Label'}
                      option={values.selectOption}
                      value={values.autoTest}
                      require
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Select</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Single Select</td>
                  <td>
                    <SelectSingle
                      name="selectData"
                      label={'Single Select'}
                      option={values.selectOption}
                      disabled={false}
                      require
                    />
                  </td>
                </tr>
                <tr>
                  <td>Muti Select</td>
                  <td>
                    <SelectMutiple
                      label={'Muti Select'}
                      option={values.selectOption}
                      disabled={false}
                      name="multiSelect"
                      value={values.multiSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Textarea</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Textarea</td>
                  <td>
                    <TextArea
                      name="address"
                      label="label"
                      placeholder="placeholder"
                      maxLength={100}
                      helperText="Helper Text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Switch</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>With Label</td>
                  <td>
                    <Switch label="With Label" name={'open'} checked={values.open} disabled={false} />
                  </td>
                </tr>
                <tr>
                  <td>No Label</td>
                  <td>
                    <Switch name={'open'} checked={false} disabled={true} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Checkbox</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>With Label</td>
                  <td>
                    <Checkbox checked={values.color.includes('red')} value="red" label="red" name="color" />
                    <Checkbox checked={values.color.includes('green')} value="green" label="green" name="color" />
                  </td>
                </tr>
                <tr>
                  <td>No Label</td>
                  <td>
                    <Checkbox checked={values.color.includes('blue')} value="blue" name="color" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Radio</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>With Label</td>
                  <td>
                    <Radio value="male" label="ชาย" disabled={false} name="gender" />
                    <Radio value="female" label="หญิง" disabled={false} name="gender" />
                  </td>
                </tr>
                <tr>
                  <td>No Label</td>
                  <td>
                    <Radio value="data" disabled={false} name="test" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Rating</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Rating</td>
                  <td>
                    <Rating
                      name="rating"
                      maxValue={5}
                      currentValue={0}
                      value={values.rating}
                      imgActive={
                        <FavoriteIcon style={{ color: '#BA1717', width: '32px', height: '32px' }} fontSize="inherit" />
                      }
                      imgInActive={
                        <FavoriteIcon style={{ color: '#E2A1A1', width: '32px', height: '32px' }} fontSize="inherit" />
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Table</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Table</td>
                  <td>
                    <Table
                      objectKey={{
                        date: 'Input date',
                        representative: 'Name of sales representative',
                        supervisor: 'Name of sales supervisor',
                        asm: 'Name of ASM',
                        zone: 'Zone',
                        province: 'province',
                        district: 'district',
                        volume: 'Sales volume',
                      }}
                      data={mockUpData || []}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Calendar</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Calendar</td>
                  <td>{/* <Calendar /> */}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.box}>
          <h3 className={styles.title}>Redux Action</h3>
          <div className={styles.body}>
            <table>
              <tbody>
                <tr>
                  <td>Redux Action</td>
                  <td>
                    <h1>
                      {t('common.hello')}, msg form redux: {testReducer.msg}
                    </h1>
                    <input type={'text'} value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button type="button" onClick={() => dispatch(setMsg(message))}>
                      save to redux
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.box}>
          <h3 className={styles.title}>StepProgressBar </h3>
          <div className={styles.body}>
            <StepProgressBar activeStep={activeStep} />
            <div>
              <button onClick={handleBack}>Back</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <h3 className={styles.title}>UploadFile </h3>
          <div className={styles.body}>
            <UploadFile />
          </div>
        </div>
      </Form>
    </Layout>
  )
}

const EnhancedDemo = withFormik({
  mapPropsToValues: () => ({
    campaign: {
      imageList: ['https://storage.cloud.google.com/bucket_scg_file/483944/Screen Shot 2566-02-14 at 10.05.24.png'],
    },
    selectOption: [
      { label: 'test1', value: 'test1' },
      { label: 'test2', value: 'test2' },
      { label: 'test3', value: 'test3' },
      { label: 'test4', value: 'test4' },
      { label: 'test5', value: 'test5' },
      { label: 'test6', value: 'test6' },
      { label: 'test7', value: 'test7' },
      { label: 'test8', value: 'test8' },
      { label: 'test9', value: 'test9' },
      { label: 'test10', value: 'test10' },
      { label: 'test11', value: 'test11' },
      { label: 'test12', value: 'test12' },
      { label: 'test13', value: 'test13' },
      { label: 'test14', value: 'test14' },
      { label: 'test15', value: 'test15' },
      { label: 'test16', value: 'test16' },
      { label: 'test17', value: 'test17' },
      { label: 'test18', value: 'test18' },
      { label: 'test19', value: 'test19' },
      { label: 'test20', value: 'test20' },
    ],
    selectData: '',
    textInput: '',
    number: 0,
    password: '',
    dateRange: [],
    address: '',
    autoTest: [],
    multiSelect: [],
    gender: '',
    color: ['red'],
    open: true,
    rating: 4,
    comment: '',
  }),
  validate: (values) => {
    const errors: any = {}
    if (!values.textInput) {
      errors.textInput = 'กรอกข้อมูล Text Input'
    }
    if (!values.number) {
      errors.number = 'กรอกข้อมูล Number Input'
    }
    if (!values.password) {
      errors.password = 'กรอกข้อมูล Password Input'
    }
    if (!values.selectData) {
      errors.selectData = 'เลือกรายการ'
    }
    if (values.dateRange.length !== 2) {
      errors.dateRange = 'err'
    }
    if (values.autoTest.length === 0) {
      errors.autoTest = 'err'
    }
    return errors
  },
  handleSubmit: (values) => {
    console.log('submit', values)
  },
})(Demo)

export default EnhancedDemo

const mockUpData: any = [
  {
    date: '2022-01-01',
    representative: 'Lê Quang Son',
    supervisor: 'SUP QTR',
    asm: 'Phan Thái Tiến',
    zone: 'CTR4',
    province: 'QTI',
    district: 'TRIEUPHONG',
    volume: '20',
  },
  {
    date: '2022-01-01',
    representative: 'Lê Quang Son',
    supervisor: 'SUP QTR',
    asm: 'Phan Thái Tiến',
    zone: 'CTR4',
    province: 'QTI',
    district: 'TRIEUPHONG',
    volume: '20',
  },
  {
    date: '2022-01-01',
    representative: 'Lê Quang Son',
    supervisor: 'SUP QTR',
    asm: 'Phan Thái Tiến',
    zone: 'CTR4',
    province: 'QTI',
    district: 'TRIEUPHONG',
    volume: '20',
  },
]
