// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'
import { setOpenDialogWithInput, setOpenDialog, setCloseDialog } from 'states/slice/modalDialog'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/tranformData'

interface Props {
  approveCampaign: any
}

const FooterButtonReviewCampaign: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const dispatch = useDispatch()

  const onApprove = () => {
    const packData = transformationDataToServer({
      values: values.campaign,
      type: ETransformationDataToServer.ApproveCampaign,
    })
    props.approveCampaign({ variables: packData }).then((res: any) => {
      if (res.data.approveCampaign.res_code === '99') return

      dispatch(setCloseDialog())
      navigate(-1)
    })
  }

  const onReject = (comment: string | undefined) => {
    const packData = transformationDataToServer({
      values: { ...values.campaign, remark: comment },
      type: ETransformationDataToServer.RejectCampaign,
    })
    props.approveCampaign({ variables: packData }).then((res: any) => {
      if (res.data.approveCampaign.res_code === '99') return

      dispatch(setCloseDialog())
      navigate(-1)
    })
  }

  return (
    <section className={styles.container}>
      <Button
        typeButton="button"
        type="outline"
        fullWidth
        name={t<string>('ReviewCampaign.reject')}
        disabled={false}
        functionOnClick={() =>
          dispatch(
            setOpenDialogWithInput({
              title: t<string>('ReviewCampaign.rejectConfirm'),
              description: `Are you sure to reject the campaign ID ${values.campaign.campaignID} "${values.campaign.campaignName}"`,
              status: 'danger',
              titleInput: 'Comment and Detail',
              functionOnSubmit: (comment) => {
                onReject(comment)
              },
            }),
          )
        }
      />
      <Button
        typeButton="button"
        type="primary"
        fullWidth
        name={t<string>('ReviewCampaign.approve')}
        disabled={false}
        functionOnClick={() =>
          dispatch(
            setOpenDialog({
              title: t<string>('ReviewCampaign.approveConfirm'),
              description: `Are you sure to approve the campaign ID ${values.campaign.campaignID} "${values.campaign.campaignName}"`,
              status: 'success',
              functionOnSubmit: () => {
                onApprove()
              },
            }),
          )
        }
      />
    </section>
  )
}
export default FooterButtonReviewCampaign
