import Color from 'abstracts/export.module.scss'

export interface Props {
  label?: string
  value?: string
  disabled?: boolean
  name: string
}

export const RadioSx = {
  color: `${Color.primaryMain}`,
  '&.Mui-checked': {
    color: `${Color.primaryMain}`,
  },
  '&.Mui-disabled': {
    color: `${Color.grey300}`,
  },
}
