import { ApolloClient, InMemoryCache, createHttpLink, Observable } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { queryClient } from './react-query'
import { refreshToken as refreshTokenService } from 'services/auth.service'
import ApolloLinkTimeout from 'apollo-link-timeout'

const httpLink = createHttpLink({
  uri: `${process.env['REACT_APP_END_POINT']}/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, forward, operation, response }: any) => {
  if (graphQLErrors) {
    return forward(operation)
  }
  if (networkError) {
    if (networkError.statusCode === 405) {
      const expiredLogin = localStorage.getItem('expiredLogin')
      const dateNow = new Date().getTime()

      // If user not check remember me much check time for handle call api refresh token
      if (!expiredLogin || parseInt(expiredLogin) > dateNow) {
        const refreshToken = localStorage.getItem('refreshToken')
        const retryObservable = new Observable((subscriber) => {
          if (subscriber.closed || !refreshToken) {
            return
          }
          refreshTokenService(refreshToken)
            .then((result: any) => {
              if (!result) {
                operation.setContext({
                  response: {
                    errors: null,
                  },
                })
                subscriber.complete()
                localStorage.clear()
              } else {
                const token = result?.data
                if (token.accessToken) {
                  localStorage.setItem('accessToken', token.accessToken)
                  localStorage.setItem('refreshToken', token.refreshToken)

                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${token.accessToken}`,
                    },
                  })
                  subscriber.next(operation)
                  subscriber.complete()
                }
              }
            })
            .catch(() => {
              localStorage.clear()
              client.clearStore()
              queryClient.clear()
              location.reload()
              return
            })
        })
        return retryObservable.flatMap((newOperation: any) => forward(newOperation as any))
      } else {
        localStorage.clear()
        location.reload()
      }
    }
  }
})

const timeoutLink = new ApolloLinkTimeout(900000) // 900 second timeout

const client = new ApolloClient({
  link: authLink.concat(timeoutLink).concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
})

export { client }

// const timeoutLink = new ApolloLinkTimeout(10000) // 10 second timeout

// const httpLink = createHttpLink({ uri: '/graphql' })

// const timeoutHttpLink = timeoutLink.concat(httpLink)

// const apolloClient = new ApolloClient({ link: timeoutHttpLink })
