import { useMemo } from 'react'
import useHandleResponseAPI from 'hooks/useHandleResponseAPI'
import { useMutation } from 'react-query'
import {
  sendOTP as sendOTPFetch,
  verifyOTP as verifyOTPFetch,
  changePassword as changePasswordFetch,
} from 'services/auth.service'

function useForgotPassword(
  onSuccessSendOTP?: (data: any) => void,
  onSuccessVerifyOTP?: (data: any) => void,
  onSuccessChangePassword?: (data: any) => void,
) {
  const {
    mutate: mutateSendOTP,
    error: errorSendOTP,
    isLoading: isLoadingSendOTP,
  } = useMutation(({ email }: { email: string }) => sendOTPFetch({ email }), {
    onSuccess: (result) => {
      onSuccessSendOTP?.(result)
    },
  })

  const {
    mutate: mutateVerifyOTP,
    error: errorVerifyOTP,
    isLoading: isLoadingVerifyOTP,
  } = useMutation(({ email, otpCode }: { email: string; otpCode: string }) => verifyOTPFetch({ email, otpCode }), {
    onSuccess: (result) => {
      onSuccessVerifyOTP?.(result)
    },
  })

  const {
    mutate: mutateChangePassword,
    error: errorChangePassword,
    isLoading: isLoadingChangePassword,
  } = useMutation(({ email, hash }: { email: string; hash: string }) => changePasswordFetch({ email, hash }), {
    onSuccess: (result) => {
      onSuccessChangePassword?.(result)
    },
  })

  const errMsg = useMemo(() => {
    if (errorSendOTP) return (errorSendOTP as any)?.response?.data?.res_desc
    else if (errorVerifyOTP) return (errorVerifyOTP as any)?.response?.data?.res_desc
    else if (errorChangePassword) return (errorChangePassword as any)?.response?.data?.res_desc
    return ''
  }, [errorSendOTP, errorVerifyOTP, errorChangePassword])

  useHandleResponseAPI({
    isLoading: isLoadingSendOTP || isLoadingVerifyOTP || isLoadingChangePassword,
    errMsg: errMsg,
  })

  return {
    sendOTP: mutateSendOTP,
    verifyOTP: mutateVerifyOTP,
    changePassword: mutateChangePassword,
  }
}

export default useForgotPassword
