import { gql } from '@apollo/client'

export const LIST_SEND_POINT = gql`
  query SendPointList($month: Int!, $year: Int!) {
    sendPointList(month: $month, year: $year) {
      res_code
      res_desc
      urBoxStatus
      items {
        requestID
        requestTime
        saleVolume
        shopID
        shopName
        shopType
        status
        updatedAt
        adjustmentPoint
        amount
        message
        phoneNumber
        point
        rawPoint
        month
        year
        twilioTemplate
      }
    }
  }
`
export const TOP_UP_POINT = gql`
  mutation TopUpThePoint($year: Int!, $month: Int!) {
    topUpThePoint(year: $year, month: $month) {
      res_code
      res_desc
      urBoxStatus
    }
  }
`
