// Lib
import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Legend,
  ChartDataLabels,
  Tooltip,
)

// Images

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import BarGraphTotalStar from './BarGraphTotalStar'
import MultiAxis from './MultiAxis'
import BarGraphRTL from './BarGraphRTL'
import BarGraphProvinceStar from './BarGraphProvinceStar'
import BarGraphCostPerTon from './BarGraphCostPerTon'

const Dashboard: React.FC = () => {
  return (
    <Layout open={false} section={'Dashboard'}>
      <div className={styles.wrapperGraph}>
        <div className={styles.graph}>
          <BarGraphTotalStar />
        </div>
        <div className={styles.graph}>
          <MultiAxis />
        </div>
        <div className={styles.graph}>
          <BarGraphRTL />
        </div>
        <div className={styles.graph}>
          <BarGraphProvinceStar />
        </div>
        <div className={styles.graph}>
          <BarGraphCostPerTon />
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard
