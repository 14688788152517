// Lib
import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useFormikContext } from 'formik'

// Images
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Props } from './config'
import { HeadOfPointHistory, CardTotalPointHistory, PointTransaction, CardPointSummary } from 'container'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'
import { ECampaignType } from 'utils/generated'

const ModalOfApprovePoints: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const [dataList, setDataList] = useState<any>('')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!props.isOpen) return

    const variables: {
      campaignID?: string
      shopID?: number
      shopType?: string
      campaignCalculationID?: number
      month?: number
      year?: number
    } = {}
    variables.campaignID = searchParams.get('campaignID') as string
    variables.shopID = values.approveCalculatePointDetail.shopID
    variables.shopType = values.approveCalculatePointDetail.shopType
    variables.campaignCalculationID = values.approveCalculatePointDetail.campaignCalculationID
    if (values.campaignDetail[0].campaignType === ECampaignType.Normal) {
      variables.month = values.filter.month
      variables.year = values.filter.year
    }

    props
      .getApprovePointHistory({
        variables: variables,
      })
      .then((res: any) => {
        if (res.data.approvePointHistoryDetail.res_code === '99') return

        setDataList(res.data.approvePointHistoryDetail)
      })
  }, [props.isOpen])

  const dataTransaction = transformationDataIntoTable({
    type: ETranformationDataIntoTable.ListPointTransaction,
    dataList: dataList?.pointTransaction,
  })

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <section className={styles.containerModal}>
        <div className={styles.wrapperCard}>
          <div className={styles.head}>
            <h3>{t<string>('checkPointCamapaign.pointHistory')}</h3>
            <img src={closeSVG} alt="closeSVG" onClick={props.onClose} />
          </div>

          <section className={styles.groupCard}>
            <div className={styles.card}>
              <HeadOfPointHistory data={dataList} />
              <CardPointSummary dataList={dataList} />
            </div>
            <div className={styles.wrapperCardList}>
              <CardTotalPointHistory
                title={t<string>('checkPointCamapaign.pointTotal')}
                unit={t<string>('common.star')}
                summary={dataList?.point}
              />
            </div>
          </section>

          <div className={styles.card}>
            <PointTransaction
              campaignName={dataList.campaignName}
              dataList={dataTransaction}
              fileName={`${searchParams.get('campaignID')}-${values.approveCalculatePointDetail.shopName}-${
                values.approveCalculatePointDetail.shopType
              }`}
            />
          </div>
        </div>
      </section>
    </Modal>
  )
}
export default ModalOfApprovePoints
