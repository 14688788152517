import { gql } from '@apollo/client'

export const GET_LIST_CAMPAIGN = gql`
  query CampaignsList(
    $status: ECampaignListStatus!
    $type: ECampaignListType!
    $targetType: ECampaignTargetType
    $year: Int
    $pageSize: Int!
    $nextToken: String
  ) {
    campaignsList(
      status: $status
      type: $type
      targetType: $targetType
      year: $year
      pageSize: $pageSize
      nextToken: $nextToken
    ) {
      res_code
      res_desc
      nextToken
      items {
        campaignID
        campaignType
        campaignName
        promotion
        restrictionStartDate
        restrictionEndDate
        campaignStatus
        countryName
        campaignTargetType
        campaignTargetTypeValue {
          targetTypeID
          targetTypeName
        }
        campaignShopType
      }
    }
  }
`

export const GET_CAMPAIGN_DETAIL = gql`
  query CampaignDetail($campaignId: ID!, $type: ECampaignDetailType!) {
    campaignDetail(campaignID: $campaignId, type: $type) {
      res_code
      res_desc
      campaignID
      campaignType
      campaignName
      campaignDescription
      campaignGoal
      campaignTargetType
      campaignTargetTypeValue {
        targetTypeID
        targetTypeName
      }
      targetProductList {
        tagID
        tagName
        levelList {
          level
          point
        }
      }
      shopTagsList {
        tagID
        tagName
        # tagColor
      }
      rewardPointList {
        rewardPointID
        targetTypeID
        targetTypeName
        pointReward
      }
      campaignShopType
      restrictionStartDate
      restrictionEndDate
      isSubRTLCal
      campaignStatus
      pointStatus
      documents
      remark
      remarkPoint
    }
  }
`

export const CREATE_SPECIAL_CAMPAIGN = gql`
  mutation CreateSpecialCampaign($input: CreateSpecialCampaignInput!) {
    createSpecialCampaign(input: $input) {
      res_code
      res_desc
      campaignID
    }
  }
`

export const UPDATE_SPECIAL_CAMPAIGN = gql`
  mutation UpdateSpecialCampaign($input: UpdateSpecialCampaignInput!) {
    updateSpecialCampaign(input: $input) {
      res_code
      res_desc
      campaignID
    }
  }
`

export const REQUEST_APPROVE_CAMPAIGN = gql`
  mutation RequestApproveCampaign($input: RequestApproveCampaignInput!) {
    requestApproveCampaign(input: $input) {
      res_code
      res_desc
      campaignID
    }
  }
`
