import { useMutation, useQuery } from '@apollo/client'
import { CREATE_UNIT, DELETE_UNIT, GET_LIST_UNIT, RECOVER_UNIT, UPDATE_UNIT } from 'queries/unit.queries'
import { useHandleResponseAPI } from 'hooks'

function useUnit(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_UNIT, { fetchPolicy: 'no-cache', variables: variables })

  const [createUnit, optionCreate] = useMutation(CREATE_UNIT)
  const [updateUnit, optionUpdate] = useMutation(UPDATE_UNIT)
  const [deleteUnit, optionDelete] = useMutation(DELETE_UNIT)
  const [recoverUnit, optionRecovery] = useMutation(RECOVER_UNIT)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listUnit?.res_code === '99') return JSON.stringify(errorQuery || data?.listUnit?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createUnit?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createUnit?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateUnit?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateUnit?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteUnit?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteUnit?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryUnit?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryUnit?.res)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listUnit,
    unitLoading: loading,
    refetch,
    createUnit,
    updateUnit,
    deleteUnit,
    recoverUnit,
  }
}

export default useUnit
