// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { convertDateFormat } from 'utils/convertDate'

interface Props {
  startDate: string
  endDate: string
}

const TimelineCampaign: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <p>
        {t<string>('common.start')} : {convertDateFormat(props.startDate)}
      </p>
      <p>
        {t<string>('common.end')} : {convertDateFormat(props.endDate)}
      </p>
    </section>
  )
}
export default TimelineCampaign
