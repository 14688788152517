// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { ChipByStatusCampaign, Table } from 'component'
import { SearchYearAndMonth } from 'container'
import { useCampaignList, useInfiniteScroll } from 'hooks'
import { ECampaignListType, ECampaignStatus } from 'utils/generated'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'
import { getDefaultMonthYear } from 'utils/other'

const CheckPointCampaignList: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  // Campaign Status Active
  const { data: resCampaignActive } = useCampaignList({
    pageSize: 9999,
    status: ECampaignStatus.Active,
    type: ECampaignListType.All,
  })
  const campaignList = transformationDataIntoTable({
    dataList: resCampaignActive?.items || [],
    type: ETranformationDataIntoTable.ListCampaignReview,
  })

  // Campaign Status Expired
  const { data: resCampaignExpired, refetch: refetchCampaignExpired } = useCampaignList({
    pageSize: 25,
    status: ECampaignStatus.Expired,
    type: ECampaignListType.All,
    nextToken: '',
    year: values.filter.year,
    month: values.filter.month,
  })
  const { dataList: dataListExpired } = useInfiniteScroll({
    result: resCampaignExpired,
    refetch: refetchCampaignExpired,
    variables: {
      pageSize: 25,
      status: ECampaignStatus.Active,
      type: ECampaignListType.All,
      nextToken: resCampaignExpired?.nextToken,
      year: values.filter.year,
      month: values.filter.month,
    },
    filter: values.filter,
    key: 'campaignsList',
  })
  const campaignListExpired = transformationDataIntoTable({
    dataList: dataListExpired,
    type: ETranformationDataIntoTable.ListCampaignReview,
  })

  const handleExcelReviewPointCampaign = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `ReviewPointCampaign-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.reviewPointCampaign({
        activeCampaign: resCampaignActive?.items || [],
        expiredCampaign: dataListExpired,
      })
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={false} section={t('checkPointCamapaign.section')}>
      <section className={styles.container}>
        <SearchYearAndMonth isShowButton titleButton="Export File" onClickButton={handleExcelReviewPointCampaign} />
        <section className={styles.head}>
          <h4>{t<string>('common.campaign')}</h4>
          <ChipByStatusCampaign status="ACTIVE" />
        </section>

        <Table
          colSize={{
            campaignID: '150px',
            campaignName: '200px',
            campaignTargetType: 'auto',
            targetTypeValueHTML: 'auto',
            shopTagsListHTML: 'auto',
            campaignType: 'auto',
            timeline: '150px',
            countryName: 'auto',
            updatedAtHTML: '180px',
            campaignStatusHTML: 'auto',
            view: 'auto',
          }}
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            campaignTargetType: 'Target Type',
            targetTypeValueHTML: 'Target Type Value',
            shopTagsListHTML: 'Tags Retailer',
            campaignType: 'Campaign Type',
            timeline: 'Timeline',
            countryName: 'Country',
            updatedAtHTML: 'Last Update',
            campaignStatusHTML: 'Status',
            view: 'Data',
          }}
          data={campaignList}
        />

        <section className={styles.head}>
          <h4>{t<string>('common.campaign')}</h4>
          <ChipByStatusCampaign status="EXPIRED" />
        </section>

        <Table
          colSize={{
            campaignID: '150px',
            campaignName: '200px',
            campaignTargetType: 'auto',
            // targetTypeValueHTML: 'auto',
            shopTagsListHTML: 'auto',
            campaignType: 'auto',
            timeline: '150px',
            countryName: 'auto',
            updatedAtHTML: '180px',
            campaignStatusHTML: 'auto',
            pointApprovalStatusHTML: 'auto',
            view: 'auto',
          }}
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            campaignTargetType: 'Target Type',
            // targetTypeValueHTML: 'Target Type Value',
            shopTagsListHTML: 'Tags Retailer',
            campaignType: 'Campaign Type',
            timeline: 'Timeline',
            countryName: 'Country',
            updatedAtHTML: 'Last Update',
            campaignStatusHTML: 'Status',
            pointApprovalStatusHTML: 'Point Approval',
            view: 'Data',
          }}
          data={campaignListExpired}
        />
      </section>
    </Layout>
  )
}

const EnhancedCheckPointCampaignList = withFormik({
  mapPropsToValues: () => ({
    filter: {
      year: getDefaultMonthYear().defaultYear,
      month: getDefaultMonthYear().defaultMonth,
    },
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(CheckPointCampaignList)
export default EnhancedCheckPointCampaignList
