import { useQuery, useMutation } from '@apollo/client'
import {
  GET_LIST_DISTRICT,
  UPDATE_DISTRICT,
  CREATE_DISTRICT,
  RECOVERY_DISTRICT,
  DELETE_DISTRICT,
} from 'queries/district.queries'
import { useHandleResponseAPI } from 'hooks'

function useDistrict(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_DISTRICT, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createDistrict, optionCreate] = useMutation(CREATE_DISTRICT)
  const [updateDistrict, optionUpdate] = useMutation(UPDATE_DISTRICT)
  const [deleteDistrict, optionDelete] = useMutation(DELETE_DISTRICT)
  const [recoveryDistrict, optionRecovery] = useMutation(RECOVERY_DISTRICT)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listDistrict?.res_code === '99')
      return JSON.stringify(errorQuery || data?.listDistrict?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createDistrict?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createDistrict?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateDistrict?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateDistrict?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteDistrict?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteDistrict?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryDistrict?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryDistrict?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listDistrict,
    districtLoading: loading,
    refetch,
    createDistrict,
    updateDistrict,
    deleteDistrict,
    recoveryDistrict,
  }
}

export default useDistrict
