import * as Yup from 'yup'

const yupForgotPassword = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
  optCode: Yup.string().when('stepNo', {
    is: 1,
    then: Yup.string().required('Please enter your otp'),
  }),
  newHash: Yup.string().when('stepNo', {
    is: 2,
    then: Yup.string().required('Please enter your New Password'),
  }),
  reNewHash: Yup.string().when('stepNo', {
    is: 2,
    then: Yup.string().oneOf([Yup.ref('newHash'), null], 'Password must match'),
  }),
})

export default yupForgotPassword
