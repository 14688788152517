// Lib
import React from 'react'
import { getIn, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash'

//Images
import calendarSVG from 'images/commons/calendarRed.svg'

// Include in project
import styles from './index.module.scss'
import { Props } from './config'

const Datepickers: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, touched, errors, setFieldValue } = useFormikContext<any>()

  return (
    <div className={styles.wapper}>
      <p>
        {props.label}
        <span className={'startRequire'}>{props.require && '*'}</span>
      </p>
      <div className={styles.dateArea}>
        <DatePicker
          className={`${styles.customInput} ${
            getIn(touched, props.name) && Boolean(getIn(errors, props.name)) && styles.customInputError
          }`}
          name={props.name}
          selectsRange={true}
          value={getIn(values, props.name)}
          startDate={_.get(values, props.name)[0]}
          endDate={_.get(values, props.name)[1]}
          onChange={(date: any) => {
            setFieldValue(`${props.name}`, date)
          }}
        />
        <img src={calendarSVG} alt="calendarSVG" className={styles.icon} />
      </div>
      <pre className={styles.errorMsg}>
        {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
      </pre>
    </div>
  )
}

export default Datepickers
