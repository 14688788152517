// Lib
import React from 'react'
import { Route, Routes } from 'react-router-dom'

// Images

// Include in project
import {
  Demo,
  Login,
  ForgotPassword,
  ListOfSpecialCampaigns,
  ListOfNormalCampaigns,
  CallFromDMS,
  CampaignCalendar,
  UserRoleManagement,
  UserListManagement,
  CreateNormalCampaign,
  CreateSpecialCampaign,
  BrandManagement,
  TagsManagement,
  UnitManagement,
  ProductManagement,
  ProvinceManagement,
  ShopManagement,
  ReviewCampaignList,
  ReviewCampaignDetail,
  SendPointToUrbox,
  CheckPointCampaignList,
  CheckPointCampaignDetail,
  ApprovePointCampaignList,
  ApprovePointCampaignDetail,
  SegmentManagement,
  ZoneManagement,
  DistrictManagement,
  ShopBigRetailerManagement,
  Dashboard,
} from 'pages'
import { ModalDialogWithRedux, NotificationWithRedux, ProtectedRoute } from 'component'
import 'i18n/i18n'

const App: React.FC = () => {
  console.log('ENV', process.env.REACT_APP_END_POINT_STAGE)

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="" element={<ProtectedRoute />}>
          <Route path="/demo" element={<Demo />} />

          <Route path="/" element={<Dashboard />} />

          <Route path="/call-from-dms" element={<CallFromDMS />} />

          <Route path="/campaign/calendar" element={<CampaignCalendar />} />
          <Route path="/campaign/list-normal" element={<ListOfNormalCampaigns />} />
          <Route path="/campaign/list-normal/create" element={<CreateNormalCampaign />} />
          <Route path="/campaign/list-special" element={<ListOfSpecialCampaigns />} />
          <Route path="/campaign/list-special/create" element={<CreateSpecialCampaign />} />

          <Route path="/review-and-approval/review-campaign-list" element={<ReviewCampaignList />} />
          <Route path="/review-and-approval/review-campaign-list/detail" element={<ReviewCampaignDetail />} />

          <Route path="/check-point/campaign-list" element={<CheckPointCampaignList />} />
          <Route path="/check-point/campaign-list/detail" element={<CheckPointCampaignDetail />} />

          <Route path="/approve-point/campaign-list" element={<ApprovePointCampaignList />} />
          <Route path="/approve-point/campaign-list/detail" element={<ApprovePointCampaignDetail />} />

          <Route path="/send-point" element={<SendPointToUrbox />} />

          {/* Master Data */}
          <Route path="/master-data/zone" element={<ZoneManagement />} />
          <Route path="/master-data/province" element={<ProvinceManagement />} />
          <Route path="/master-data/district" element={<DistrictManagement />} />
          <Route path="/master-data/segment" element={<SegmentManagement />} />
          <Route path="/master-data/brand" element={<BrandManagement />} />
          <Route path="/master-data/product" element={<ProductManagement />} />
          <Route path="/master-data/unit" element={<UnitManagement />} />
          <Route path="/master-data/shop" element={<ShopManagement />} />
          <Route path="/master-data/big-retailer" element={<ShopBigRetailerManagement />} />
          <Route path="/master-data/tags" element={<TagsManagement />} />

          {/* User Management */}
          <Route path="/user-management/role" element={<UserRoleManagement />} />
          <Route path="/user-management/user" element={<UserListManagement />} />
        </Route>
      </Routes>
      <ModalDialogWithRedux />
      <NotificationWithRedux />
    </>
  )
}

export default App
