import { useMutation } from '@apollo/client'
import { MUTATION_READ_NOTI } from 'queries/notification.queries'
import { useHandleResponseAPI } from 'hooks'

function useReadNotification() {
  const [readNotification, option] = useMutation(MUTATION_READ_NOTI)

  const errMsg = () => {
    if (option?.error || option.data?.readNotification?.res_code === '99')
      return JSON.stringify(option?.error || option.data?.readNotification?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: option.loading,
    errMsg: errMsg(),
  })

  return { readNotification }
}

export default useReadNotification
