// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import { HeadFilterByStatus } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { useCampaignList, useInfiniteScroll } from 'hooks'
import { EPointStatus, ECampaignListType, ECampaignListStatus } from 'utils/generated'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'

const ApprovePointCampaignList: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  const { data: resCampaignList, refetch: refetchCampaignList } = useCampaignList({
    pageSize: 25,
    type: ECampaignListType.All,
    nextToken: '',
    pointStatus: values.pointStatus === 'ALL' ? null : values.pointStatus,
    status: ECampaignListStatus.All,
  })

  const { dataList: dataListCampaign } = useInfiniteScroll({
    result: resCampaignList,
    refetch: refetchCampaignList,
    variables: {
      pageSize: 25,
      pointStatus: values.pointStatus === 'ALL' ? null : values.pointStatus,
      status: ECampaignListStatus.All,
      type: ECampaignListType.All,
      nextToken: resCampaignList?.nextToken,
    },
    filter: values.pointStatus,
    key: 'campaignsList',
  })

  const campaignList = transformationDataIntoTable({
    dataList: dataListCampaign,
    type: ETranformationDataIntoTable.ListCampaignApprovePoint,
  })

  const handleExcelApprovePointCampaign = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `ApprovePointCampaign-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.approvePointCampaign(values.pointStatus, dataListCampaign)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={false} section={t('approvePoint.section')}>
      <div className={styles.container}>
        <HeadFilterByStatus
          isShowButton
          isPointStatus
          titleButton="Export File"
          onClickButton={handleExcelApprovePointCampaign}
        />
        <Table
          colSize={{
            campaignID: '150px',
            campaignName: '200px',
            campaignTargetType: 'auto',
            targetTypeValueHTML: 'auto',
            shopTagsListHTML: 'auto',
            campaignType: 'auto',
            timeline: '150px',
            countryName: 'auto',
            lastUpdateFormat: '180px',
            campaignStatusHTML: 'auto',
            pointApprovalStatusHTML: 'auto',
            actionView: 'auto',
          }}
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            campaignTargetType: 'Target Type',
            targetTypeValueHTML: 'Target Type Value',
            shopTagsListHTML: 'Tags Retailer',
            campaignType: 'Campaign Type',
            timeline: 'Timeline',
            countryName: 'Country',
            lastUpdateFormat: 'Last Updated',
            campaignStatusHTML: 'Status',
            pointApprovalStatusHTML: 'Point Approval',
            actionView: 'Data',
          }}
          data={campaignList || []}
        />
      </div>
    </Layout>
  )
}

const EnhancedApprovePointCampaignList = withFormik({
  mapPropsToValues: () => ({
    pointStatus: 'ALL',
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(ApprovePointCampaignList)
export default EnhancedApprovePointCampaignList
