// Lib
import React from 'react'
import Rating from '@mui/material/Rating'
import { useFormikContext } from 'formik'

//Images

// Include in project
import { Props } from './config'

const RatingComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext<any>()
  return (
    <Rating
      value={props.value}
      size="large"
      defaultValue={props.currentValue}
      max={props.maxValue}
      onClick={(e: any) => setFieldValue(`${props.name}`, e.target.value)}
      icon={props.imgActive}
      emptyIcon={props.imgInActive}
    />
  )
}
export default RatingComponent
