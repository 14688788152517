import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  msg: '',
}

export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setMsg: (state, action: PayloadAction<any>) => {
      state.msg += action.payload.msg
    },
  },
})

// Action creators are generated for each case reducer function
export const { setMsg } = testSlice.actions

export default testSlice.reducer
//  -----
