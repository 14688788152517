// Lib
import React, { useState } from 'react'
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import { getIn, useFormikContext } from 'formik'
import { Visibility, VisibilityOff } from '@mui/icons-material'

//Images

// Include in project
import styles from './index.module.scss'
import { Props } from './config'
import Color from 'abstracts/export.module.scss'

const TextFieldPassword: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  return (
    <div className={styles.wapper}>
      <p>
        {props.label}
        <span className={'startRequire'}>{props.require && '*'}</span>
      </p>
      <div style={{ backgroundColor: Color.white }}>
        <OutlinedInput
          fullWidth
          inputProps={{
            sx: {
              marginTop: '5px',
              fontSize: '14px',
            },
          }}
          size="small"
          name={props.name}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={getIn(values, props.name)}
          onChange={handleChange}
          error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
        />
      </div>
      <pre className={styles.errorMsg}>
        {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
      </pre>
    </div>
  )
}

export default TextFieldPassword
