// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import { useModalForm, useModalRecently } from 'hooks'
import { HeadOfMasterData, ModalOfShopBigRetailer, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { RootState } from 'states/store'
import useShopBigRetailer from 'hooks/api/useShopBigRetailer'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'
import yupShopBigRetailer from 'validations/yupShopBigRetailer.validate'

const ShopBigRetailerManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values } = useFormikContext<any>()

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    bigRetailerID: '',
    bigRetailerName: '',
    bigRetailerCode: '',
    provinceID: '',
    districtID: '',
    segmentID: '',
    phoneNumber: '',
    zoneID: '',
    tagList: [],
    zaloID: '',
    email: '',
    ownRetailerID: '',
    lineUserID: '',
    isLineVerify: false,
  })

  const getShopBigRetailerReducer = useSelector((state: RootState) => state.shopBigRetailer)

  const {
    data: resTrashShopBigRetailer,
    refetch: refetchTrashShopBigRetailer,
    createShopBigRetailer,
    updateShopBigRetailer,
    deleteShopBigRetailer,
    recoveryShopBigRetailer,
  } = useShopBigRetailer({
    pageSize: 9999,
    filter: {
      isActive: false,
    },
  })

  const shopBigRetailerList = transformationDataIntoTable({
    dataList: getShopBigRetailerReducer.shopBigRetialerList,
    type: ETranformationDataIntoTable.ListShopBigRetailer,
    handleOpen,
  })

  const shopBigRetailerTrashList = transformationDataIntoTable({
    dataList: resTrashShopBigRetailer?.items,
    type: ETranformationDataIntoTable.ListShopBigRetailerTrash,
    handleOpen,
  })

  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoveryShop,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoveryShopBigRetailer,
    refetchMasterDataTrash: refetchTrashShopBigRetailer,
    refetchMasterData: getShopBigRetailerReducer.refetch,
  })

  const keyOfTable = {
    bigRetailerCode: 'DMS Code',
    bigRetailerName: 'Name',
    zoneName: 'Zone',
    provinceName: 'Province',
    districtName: 'District',
    segmentName: 'Segment',
    phoneNumber: 'Phone Number',
    tagListHTML: 'Tags',
    zaloID: 'Zalo ID',
    email: 'Email',
    lineUserID: 'LineUserID',
    isLineVerify: 'LineVerify',
    action: 'Action',
  }

  const handleExcelBigRetailer = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `BigRetailer-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.bigCustomerExcel(getShopBigRetailerReducer.shopBigRetialerList)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={false} section={t('shopBigRetailerManagement.title')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('shopManagement.recentlyDeleted')}
          buttonName={t('shopManagement.button')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
          isShowButtonExport
          titleButtonExport="Export File"
          onClickButtonExport={handleExcelBigRetailer}
        />

        <Table objectKey={keyOfTable} data={shopBigRetailerList || []} />

        <ModalOfShopBigRetailer
          openModal={isModalOpen}
          closeModal={handleClose}
          createShop={createShopBigRetailer}
          updateShop={updateShopBigRetailer}
          deleteShop={deleteShopBigRetailer}
          refetch={getShopBigRetailerReducer.refetch}
          refetchTrash={refetchTrashShopBigRetailer}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          keyTable={_.omit(keyOfTable, 'action')}
          dataTable={shopBigRetailerTrashList || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('shopManagement.recentlyTitle'),
                description: t<string>('shopManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  onRecoveryShop()
                  dispatch(setCloseDialog())
                },
              }),
            )
          }
          primaryKey="bigRetailerID"
        />
      </div>
    </Layout>
  )
}

const EnhancedShopBigRetailerManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      bigRetailerName: '',
      bigRetailerCode: '',
      bigRetailerID: '',
      zoneID: '',
      ownRetailerID: '',
      provinceID: '',
      districtID: '',
      segmentID: '',
      phoneNumber: '',
      tagList: [],
      zaloID: '',
      email: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupShopBigRetailer,
  handleSubmit: () => {
    console.log('SKIP')
  },
})(ShopBigRetailerManagement)
export default EnhancedShopBigRetailerManagement
