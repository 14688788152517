// Lib
import { useState } from 'react'
import { useFormikContext } from 'formik'

const useModalForm = (initialObject: any) => {
  const { setFieldValue } = useFormikContext<any>()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpen = (data: any) => {
    if (data) setFieldValue('formInput', data)
    else setFieldValue('formInput', initialObject)
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
    setFieldValue('formInput', initialObject)
  }

  return { isModalOpen, handleOpen, handleClose }
}

export default useModalForm
