// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { TextField } from 'component'

const TableNormalCampaign: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  return (
    <div className={styles.wrapperTable}>
      {values.campaign.productList &&
        values.campaign.productList.map((item: any, index: number) => (
          <table className="tableMain" key={index}>
            <thead>
              <tr>
                <th>{item.label}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {values.activeStep === 0 ? (
                    <div className={styles.flexData}>
                      <p>{t<string>('createNormalCampaign.basicStar')}</p>
                      <TextField name={`campaign.productList[${index}].levelList[${0}].point`} type={'number'} />
                    </div>
                  ) : (
                    <p>{values.campaign.productList[index]?.levelList[0]?.point}</p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        ))}
    </div>
  )
}
export default TableNormalCampaign
