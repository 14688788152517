// Lib
import React from 'react'

//Images

// Include in project
import { Props } from './config'
import styles from './index.module.scss'

const ViewInTable: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <p onClick={props.funtiononClick} className={styles.textLink}>
      {props.label}
    </p>
  )
}

export default ViewInTable
