import { gql } from '@apollo/client'

export const GET_LIST_PROVINCE = gql`
  query ListProvince($pageSize: Int!, $nextToken: String, $filter: FilterListProvinceInput) {
    listProvince(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      items {
        zoneName
        zoneID
        provinceName
        provinceID
        provinceCode
        isActive
        countryID
      }
      nextToken
      res_code
      res_desc
    }
  }
`

export const UPDATE_PROVINCE = gql`
  mutation UpdateProvince($input: UpdateProvinceInput!) {
    updateProvince(input: $input) {
      res_code
      res_desc
    }
  }
`

export const CREATE_PROVINCE = gql`
  mutation Mutation($input: CreateProvinceInput!) {
    createProvince(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_PROVINCE = gql`
  mutation RecoveryProvince($input: RecoveryProvinceInput!) {
    recoveryProvince(input: $input) {
      res_code
      res_desc
    }
  }
`

export const DELETE_PROVINCE = gql`
  mutation DeleteProvince($input: DeleteProvinceInput!) {
    deleteProvince(input: $input) {
      res_code
      res_desc
    }
  }
`
