// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { AutoComplete, DatePickers } from 'component'
import type { RootState } from 'states/store'

const CampaignRestrictionsModeEdit: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()
  const getProductListReducer = useSelector((state: RootState) => state.product)

  return (
    <section className={styles.container}>
      <h5>{t<string>('createNormalCampaign.campaignRestric')}</h5>
      <div className={styles.wrapperInput}>
        <AutoComplete
          name={'campaign.productListDummy'}
          label={t<string>('common.product')}
          value={values.campaign.productListDummy}
          option={getProductListReducer.productListOption}
          require
        />

        <DatePickers name="campaign.timeDuration" label={t<string>('common.timeDuration')} require />
      </div>
    </section>
  )
}
export default CampaignRestrictionsModeEdit
