// Lib
import React, { useMemo } from 'react'
import { Chip } from '@mui/material'

//Images

// Include in project
import { Props, ThemeColor } from './config'

const ChipInput: React.FC<Props> = (props: Props): JSX.Element => {
  const seletedColor = useMemo(() => ThemeColor[props.disabled ? 'disabled' : props.color], [props.color])

  return (
    <Chip
      deleteIcon={props.icon}
      sx={seletedColor}
      label={props.label}
      onDelete={props.onDelete && props.icon && props.onDelete}
      disabled={props.disabled}
    />
  )
}

export default ChipInput
