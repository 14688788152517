import { gql } from '@apollo/client'

export const GET_LIST_USER_PERMISSION = gql`
  query UserPermission($nextToken: String, $pageSize: Int!) {
    userPermission(nextToken: $nextToken, pageSize: $pageSize) {
      items {
        countryID
        permission
        userRole
        userRoleID
        isActive
      }
      nextToken
      res_code
      res_desc
    }
  }
`

export const UPDATE_USER_PERMISSTION = gql`
  mutation UpdateUserPermission($input: UpdateUserPermissionInput!) {
    updateUserPermission(input: $input) {
      res_code
      res_desc
      nextToken
    }
  }
`
