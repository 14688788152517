// Lib
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { Button, Table } from 'component'
import { ModalOfCheckPoints, ModalPointAdjustment, SearchYearAndMonth } from 'container'
import yupPointAdjustment from 'validations/yupPointAdjustment.validate'
import { useCampaignNormal, useCampaignSpecial, useReviewCalculatePoint, useReviewPointHistoryDetail } from 'hooks'
import { ECampaignListType, ECampaignStatus, EPointStatus } from 'utils/generated'
import {
  ETranformationDataIntoTable,
  ETransformationDataToServer,
  transformationDataIntoTable,
  transformationDataToServer,
} from 'utils/tranformData'
import { getDefaultMonthYear } from 'utils/other'

const CheckPointCampaignDetail: React.FC = () => {
  const { t } = useTranslation()
  const { values, setFieldValue, errors } = useFormikContext<any>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { getCampaign: getCampaignNoraml, refetch } = useCampaignNormal()
  const { getCampaign: getCampaignSpecial } = useCampaignSpecial()
  const { creatPointAdjustment } = useReviewPointHistoryDetail()

  const campaignType = useMemo(() => {
    if (searchParams.get('campaignType') === ECampaignListType.Normal) return ECampaignListType.Normal
    else return ECampaignListType.Special
  }, [searchParams])

  const {
    data: resCampaignDetailPoint,
    requestApproveCalculatePoint,
    refetch: refetchCampaignDetailPoint,
  } = useReviewCalculatePoint({
    pageSize: 25,
    nextToken: null,
    filter: {
      campaignID: searchParams.get('campaignID'),
      month: campaignType === ECampaignListType.Normal ? values.filter.month : null,
      year: campaignType === ECampaignListType.Normal ? values.filter.year : null,
    },
  })

  const dataDetailPointList = transformationDataIntoTable({
    dataList: resCampaignDetailPoint?.items,
    type: ETranformationDataIntoTable.ListDetailPoint,
    handleOpen(data) {
      setFieldValue('modal', { ...values.modal, isOpenModalCheckPoint: !values.modal.isOpenModalCheckPoint })
      setFieldValue('pointAdjustment.point', data.adjustmentPoint)
      setFieldValue('pointHistoryDetail', data)
    },
  })

  //Campaign Detail
  useEffect(() => {
    if (campaignType === ECampaignListType.Normal) {
      getCampaignNoraml({ variables: { campaignId: searchParams.get('campaignID'), type: `${campaignType}` } }).then(
        (res) => {
          const detailCampaign = [res.data.campaignDetail]
          setFieldValue('campaignDetail', detailCampaign)
        },
      )
    } else if (campaignType === ECampaignListType.Special) {
      getCampaignSpecial({ variables: { campaignId: searchParams.get('campaignID'), type: `${campaignType}` } }).then(
        (res) => {
          const detailCampaign = [res.data.campaignDetail]
          setFieldValue('campaignDetail', detailCampaign)
        },
      )
    }
  }, [campaignType])

  const dataList = transformationDataIntoTable({
    dataList: values.campaignDetail,
    type: ETranformationDataIntoTable.ListGetCampaignReview,
  })

  const onRequestApprove = () => {
    const packData = transformationDataToServer({
      values: {
        campaignID: searchParams.get('campaignID'),
        campaignType: dataList[0]?.campaignType,
        year: values.filter.year,
        month: values.filter.month,
      },
      type: ETransformationDataToServer.RequestApproveCalculatePoint,
    })
    requestApproveCalculatePoint({ variables: packData }).then((res: any) => {
      if (res.data.requestApproveCalculatePoint.res_code === '99') return

      refetch()
      navigate('/check-point/campaign-list')
    })
  }

  const onPointAdjustment = () => {
    if (!_.isEmpty(errors)) return
    const packData = transformationDataToServer({
      type: ETransformationDataToServer.CreatePointAdjustmen,
      values: values,
    })
    if (values.campaignDetail[0].campaignType !== 'NORMAL') {
      delete packData?.input?.month
      delete packData?.input?.year
    }

    creatPointAdjustment({ variables: packData }).then((res: any) => {
      if (res.data.createPointAdjustment.res_code === '99') return

      setFieldValue('modal', {
        ...values.modal,
        isOpenModalCheckPoint: !values.modal.isOpenModalCheckPoint,
        isOpenModalPointAdjustment: !values.modal.isOpenModalPointAdjustment,
      })
      refetchCampaignDetailPoint()
    })
  }

  const handleExcelReviewCampaignCalculationPoint = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `ReviewPointCalculationPoint-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.reviewPointStar(values.campaignDetail[0], resCampaignDetailPoint?.items)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={true} section={t('checkPointCamapaign.section')}>
      <section className={styles.container}>
        <Table
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            campaignTargetType: 'Target Type',
            targetTypeValueHTML: 'Target Type Value',
            shopTagsListHTML: 'Tags Retailer',
            campaignType: 'Campaign Type',
            campaignShopType: 'Shop Type',
            timeline: 'Timeline',
            campaignStatusHTML: 'Status',
            pointApprovalStatusHTML: 'Point Approval',
            remarkPoint: 'Remark Point',
            lastUpdateFormat: 'Last Updated',
          }}
          data={dataList || []}
        />
        <div className={styles.groupButton}>
          <div className={styles.flexGroup}>
            {dataList[0]?.campaignType === 'NORMAL' ? <SearchYearAndMonth /> : <div></div>}
            <div className={styles.row}>
              <Button
                typeButton="button"
                type="primary"
                disabled={false}
                name="Export File"
                functionOnClick={handleExcelReviewCampaignCalculationPoint}
              />
              {dataList[0]?.campaignStatus === ECampaignStatus.Expired &&
              dataList[0]?.pointStatus !== EPointStatus.WaitingApprove ? (
                <div className={styles.wrapperButton}>
                  <Button
                    typeButton="button"
                    type="primary"
                    disabled={false}
                    name={t<string>('createNormalCampaign.requestApprove')}
                    functionOnClick={() => onRequestApprove()}
                  />
                  <pre className={styles.text}>{t<string>('checkPointCamapaign.description')} ....</pre>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <Table
          objectKey={{
            shopCode: 'Customer Code',
            shopType: 'Customer Type',
            shopName: 'Customer Name',
            phoneNumber: 'Tel.',
            saleVolume: 'Sale Volume',
            rawPoint: 'Raw Point',
            adjustmentPoint: 'Adj. Point',
            AccPointHTML: 'Acc. Point',
            TotalPointHTML: 'Total Point',
            lastUpdateFormat: 'Last Update',
            actionView: 'Detail',
          }}
          data={dataDetailPointList || []}
        />
      </section>

      <ModalOfCheckPoints
        onClose={() =>
          setFieldValue('modal', {
            ...values.modal,
            isOpenModalCheckPoint: !values.modal.isOpenModalCheckPoint,
          })
        }
        isOpen={values.modal.isOpenModalCheckPoint}
      />
      <ModalPointAdjustment
        onClose={() =>
          setFieldValue('modal', {
            ...values.modal,
            isOpenModalPointAdjustment: !values.modal.isOpenModalPointAdjustment,
          })
        }
        isOpen={values.modal.isOpenModalPointAdjustment}
        onPointAdjustment={() => onPointAdjustment()}
      />
    </Layout>
  )
}

const EnhancedCheckPointCampaignDetail = withFormik({
  mapPropsToValues: () => ({
    filter: {
      year: getDefaultMonthYear().defaultYear,
      month: getDefaultMonthYear().defaultMonth,
    },
    modal: {
      isOpenModalCheckPoint: false,
      isOpenModalPointAdjustment: false,
    },
    pointAdjustment: {
      point: 0,
      adjustmentReason: '',
    },
    pointHistoryDetail: '',
    campaignDetail: [],
    totalPoint: 0,
    detail: '',
  }),
  validationSchema: yupPointAdjustment,
  handleSubmit: () => {
    console.log('SKIP ')
  },
})(CheckPointCampaignDetail)
export default EnhancedCheckPointCampaignDetail
