import Color from 'abstracts/export.module.scss'

export interface Props {
  name?: string
  checked: boolean
  onChange?: () => void
  disabled: boolean
  label?: string
}

export const SwitchSx = {
  width: 50,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px 4px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: `${Color.white}`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${Color.primaryMain}`,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: `${Color.warningBorder}`,
      border: `6px solid ${Color.white}`,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: `${Color.grey100}`,
    opacity: 1,
    transition: 'background-color 0.5s',
  },
}
