// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { HeadOfMasterData, ModalOfDistrict, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import { useDistrict, useModalForm, useModalRecently } from 'hooks'
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import yupDistrict from 'validations/yupDistrict.validate'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const DistrictManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()
  const getDistrictListReducer = useSelector((state: RootState) => state.district)

  // DistrictList Hook and HandleFunction
  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    districtID: '',
    districtName: '',
    provinceID: '',
  })
  const districtList = transformationDataIntoTable({
    dataList: getDistrictListReducer.districtList,
    type: ETranformationDataIntoTable.ListDistrict,
    handleOpen,
  })

  // DistrictTrashList Hook and HandleFunction
  const {
    data: resDistrictTrashList,
    refetch: refetchDistrictTrashList,
    recoveryDistrict,
    createDistrict,
    updateDistrict,
    deleteDistrict,
  } = useDistrict({
    pageSize: 9999,
    nextToken: '',
    filter: {
      isActive: false,
    },
  })
  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoverDistrict,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoveryDistrict,
    refetchMasterDataTrash: refetchDistrictTrashList,
    refetchMasterData: getDistrictListReducer.refetch,
  })

  return (
    <Layout open={false} section={t('districtManagement.section')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('common.recentDelete')}
          buttonName={t('districtManagement.buttonopenModol')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
        />
        <Table
          objectKey={{
            districtName: 'District Name',
            provinceID: 'Province Name',
            actionView: 'Action',
          }}
          data={districtList || []}
        />

        <ModalOfDistrict
          openModal={isModalOpen}
          closeModal={handleClose}
          refetchDistrictList={getDistrictListReducer.refetch}
          refetchDistrictTrashList={refetchDistrictTrashList}
          createDistrict={createDistrict}
          updateDistrict={updateDistrict}
          deleteDistrict={deleteDistrict}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{}}
          keyTable={{
            districtName: 'District Name',
            provinceID: 'Province Name',
          }}
          dataTable={resDistrictTrashList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('districtManagement.recentlyTitle'),
                description: t<string>('districtManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  dispatch(setCloseDialog())
                  onRecoverDistrict()
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="districtID"
        />
      </div>
    </Layout>
  )
}

const EnhancedDistrictManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      districtID: '',
      districtName: '',
      provinceID: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupDistrict,
  handleSubmit: () => {
    console.log('Skip Handle Sumit of formik')
  },
})(DistrictManagement)

export default EnhancedDistrictManagement
