// Lib
import React, { useState, useMemo, useEffect } from 'react'
import { Chart } from 'react-chartjs-2'
import { withFormik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

// Images

// Include in project
import { SelectSingle, Notification } from 'component'
import Color from 'abstracts/export.module.scss'
import styles from './index.module.scss'
import { useFilterYearMonth } from 'utils/other'
import type { RootState } from 'states/store'
import { useDashboard } from 'hooks'
import { IOption } from 'utils/interface'
import { MonthlySaleVolume, MonthlyCostPerTon } from 'utils/generated'

const MultiAxis: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { yearsListOption } = useFilterYearMonth()

  const getZoneListReducer = useSelector((state: RootState) => state.zone)
  const getProvinceListReducer = useSelector((state: RootState) => state.province)
  const getDistrictListReducer = useSelector((state: RootState) => state.district)
  const getSegmentListReducer = useSelector((state: RootState) => state.segment)
  const getShopListReducer = useSelector((state: RootState) => state.shop)
  const getShopBigListReducer = useSelector((state: RootState) => state.shopBigRetailer)

  const optionOfTargetTypeValue = useMemo(() => {
    switch (values.type) {
      case 'ZONE':
        return getZoneListReducer.zoneListOption

      case 'PROVINCE':
        return getProvinceListReducer.provinceListOption

      case 'DISTRICT':
        return getDistrictListReducer.districtListOption

      case 'SEGMENT':
        return getSegmentListReducer.segmentListOption

      case 'RETAILER':
        return getShopListReducer.retialerListOption

      case 'BIG_RETAILER':
        return getShopBigListReducer.bigRetialerListOption

      default:
        return []
    }
  }, [
    values.type,
    getZoneListReducer,
    getProvinceListReducer,
    getDistrictListReducer,
    getSegmentListReducer,
    getShopListReducer,
    getShopBigListReducer,
  ])

  const { getMonthlySale, getMonthlyCostPerTon, dataMonthlySale, dataMonthlyCostPerTon, errMsg } = useDashboard()
  useEffect(() => {
    if (!optionOfTargetTypeValue) return

    getMonthlySale({
      variables: {
        year: values.year,
        type: values.type,
        value: optionOfTargetTypeValue.find((ele: IOption) => ele.value === values.value)?.label || '',
      },
    })
    getMonthlyCostPerTon({
      variables: {
        year: values.year,
        type: values.type,
        value: optionOfTargetTypeValue.find((ele: IOption) => ele.value === values.value)?.label || '',
      },
    })
  }, [values.year, values.type, values.value, optionOfTargetTypeValue])

  const labels = []
  for (let index = 0; index < 10; index++) {
    labels.push(index + 1)
  }

  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Cost Per Ton',
        borderColor: Color.primaryMain,
        borderWidth: 2,
        fill: false,
        data: dataMonthlyCostPerTon?.items?.map((data: MonthlyCostPerTon) => data.costPerTon) || [],
        yAxisID: 'y',
      },
      {
        type: 'bar' as const,
        label: 'Sale Volumn',
        backgroundColor: Color.infoBorder,
        data: dataMonthlySale?.items?.map((data: MonthlySaleVolume) => data.saleVolume) || [],
        yAxisID: 'y1',
      },
    ],
  }

  const options: any = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Multi Axis',
      },
      datalabels: {
        color: Color.black,
        anchor: 'end',
        offset: -20,
        align: 'start',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'VND/Ton',
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Ton',
        },
      },
    },
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  useEffect(() => {
    if (errMsg) return setIsOpen(true)
  }, [errMsg])
  return (
    <>
      <div className={styles.filter}>
        <SelectSingle name="year" label={'Year'} option={yearsListOption} disabled={false} require />
        <SelectSingle
          name="type"
          label={'Type'}
          option={[
            { label: 'Zone', value: 'ZONE' },
            { label: 'Province', value: 'PROVINCE' },
            { label: 'District', value: 'DISTRICT' },
            { label: 'Retailer', value: 'RETAILER' },
            { label: 'Big Retailer', value: 'BIG_RETAILER' },
          ]}
          disabled={false}
          require
        />
        <SelectSingle name="value" label={'Value'} option={optionOfTargetTypeValue} disabled={false} require />
      </div>
      <Chart type="bar" data={data} options={options} />
      <Notification
        title="Error From Server"
        description={errMsg}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        type="danger"
      />
    </>
  )
}

const EnhancedMultiAxis = withFormik({
  mapPropsToValues: () => ({
    year: new Date().getFullYear(),
    type: 'ZONE',
    value: 1,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(MultiAxis)

export default EnhancedMultiAxis
