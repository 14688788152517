// Lib
import { useState } from 'react'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/tranformData'

const useModalRecently = ({
  recentlyList,
  reconveryMasterData,
  refetchMasterDataTrash,
  refetchMasterData,
}: {
  recentlyList?: string[]
  reconveryMasterData?: any
  refetchMasterDataTrash?: any
  refetchMasterData?: any
}) => {
  const [isOpenModalRecently, setIsOpenModalRecently] = useState(false)

  const handleCloseModalRecently = () => setIsOpenModalRecently(false)
  const handleOpenModalRecently = () => setIsOpenModalRecently(true)

  const onRecover = () => {
    const packData = transformationDataToServer({
      values: recentlyList,
      type: ETransformationDataToServer.RecoveryMasterData,
    })

    reconveryMasterData({ variables: packData }).then((res: any) => {
      if (Object.keys(res.data).length !== 1) return

      if (res.data[Object.keys(res.data)[0]].res_code === '99') return

      refetchMasterDataTrash()
      refetchMasterData()
    })
  }

  return { isOpenModalRecently, handleCloseModalRecently, handleOpenModalRecently, onRecover }
}

export default useModalRecently
