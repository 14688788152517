import { useState } from 'react'

const useStepProgressBar = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    if (activeStep === 2) return
    setActiveStep((prev) => prev + 1)
  }
  const handleBack = () => {
    if (activeStep === 0) return
    setActiveStep((prev) => prev - 1)
  }

  return {
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
  }
}

export default useStepProgressBar
