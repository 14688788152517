import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  permissionList: [],
  permissionListOption: [],
  refetch: null,
}

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermission: (state, action: PayloadAction<any>) => {
      state.permissionList = action.payload.items
      state.permissionListOption = action.payload.items.map((data: any) => ({
        value: data.userRoleID,
        label: data.userRole,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPermission } = permissionSlice.actions

export default permissionSlice.reducer
