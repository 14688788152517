import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  listNotification: [],
  refetchList: null,
  nextToken: '',
  listNotificationByPermission: [],
  refetchListByPermission: null,
}

export const notificationBellSlice = createSlice({
  name: 'notificationBell',
  initialState,
  reducers: {
    setListNotification: (state, action: PayloadAction<any>) => {
      if (!action.payload.items[0]) return

      if (state.listNotification.find((ele: any) => ele.notificationID === action.payload.items[0].notificationID)) {
        state.listNotification = action.payload.items
      } else {
        state.listNotification = [...state.listNotification, ...action.payload.items]
      }
      state.refetchList = action.payload.refetch
      state.nextToken = action.payload.nextToken
    },
    setListNotificationByPermission: (state, action: PayloadAction<any>) => {
      state.listNotificationByPermission = action.payload.items
      state.refetchListByPermission = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setListNotification, setListNotificationByPermission } = notificationBellSlice.actions

export default notificationBellSlice.reducer
