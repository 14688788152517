// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'

// Include in project
import { Checkbox, Switch } from 'component'
import styles from './index.module.scss'

const TableUserManagement: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>()

  const handleSelectAll = (index: number) => {
    const objPermissionClone = { ...values.userPermissionList[index].permission }
    if (Object.values(objPermissionClone).every((value: any) => value)) {
      Object.keys(objPermissionClone).forEach((key) => (objPermissionClone[key] = false))
      setFieldValue(`userPermissionList[${index}].permission`, objPermissionClone)
    } else {
      Object.keys(objPermissionClone).forEach((key) => (objPermissionClone[key] = true))
      setFieldValue(`userPermissionList[${index}].permission`, objPermissionClone)
    }
  }

  return (
    <table className="tableMain">
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '65%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>
      <thead>
        <tr>
          {['User', 'Permissions', 'Status'].map((item: string, index: number) => (
            <th key={index}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.userPermissionList?.map((item: any, index: number) => (
          <tr key={index}>
            <td>{item.userRole}</td>
            <td>
              <div className={styles.flexData}>
                <div className={styles.leftData}>
                  <Checkbox
                    name={`selectAll`}
                    value={`selectAll`}
                    label="Select all"
                    checked={Object.values(item.permission).every((value: any) => value)}
                    onChange={() => handleSelectAll(index)}
                    disabled={!item.isActive}
                  />
                </div>
                <div className={styles.gridData}>
                  {permissionList.map((ele: any, indexPermission: number) => (
                    <Checkbox
                      key={indexPermission}
                      name={`userPermissionList[${index}].permission[${`${ele.name}`}]`}
                      value={`${ele.value}`}
                      label={`${ele.label}`}
                      checked={_.get(values, `userPermissionList[${index}].permission[${`${ele.name}`}]`) === ele.value}
                      disabled={!item.isActive}
                    />
                  ))}
                </div>
              </div>
            </td>
            <td>
              <Switch name={`userPermissionList[${index}].isActive`} checked={item.isActive} disabled={false} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
export default TableUserManagement

const permissionList = [
  {
    label: 'Calendar',
    name: 'calendar',
    value: true,
  },
  {
    label: 'Normal Campaign',
    name: 'normalCampaign',
    value: true,
  },
  {
    label: 'Special Campaign',
    name: 'specialCampaign',
    value: true,
  },
  {
    label: 'Call From DMS',
    name: 'callFromDMS',
    value: true,
  },
  {
    label: 'Approve Campaign',
    name: 'approveCampaign',
    value: true,
  },
  { label: 'Approve Point', name: 'approvePoint', value: true },
  {
    label: 'Review Point',
    name: 'reviewPoint',
    value: true,
  },
  { label: 'Send Point', name: 'sendPoint', value: true },
  { label: 'Zone', name: 'zone', value: true },
  { label: 'Province', name: 'province', value: true },
  { label: 'District', name: 'district', value: true },
  { label: 'Segment', name: 'segment', value: true },
  { label: 'Brand', name: 'brand', value: true },
  { label: 'Product', name: 'product', value: true },
  { label: 'Unit', name: 'unit', value: true },
  { label: 'Shop Retailer', name: 'shopRetailer', value: true },
  { label: 'Shop Big Retailer', name: 'shopBigRetailer', value: true },
  { label: 'Tag', name: 'tag', value: true },
  { label: 'User Role', name: 'userRole', value: true },
  { label: 'User List', name: 'userList', value: true },
]
