// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'

// Images
import deleteSVG from 'images/commons/delete.svg'
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField, SelectSingle, AutoComplete } from 'component'
import { setOpenDialog } from 'states/slice/modalDialog'
import { RootState } from 'states/store'
import { ETransformationDataToServer } from 'utils/tranformData'
import { useHandleActionMasterData } from 'hooks'

interface Props {
  openModal: boolean
  closeModal: () => void
  createProduct: any
  updateProduct: any
  deleteProduct: any
  refetchProductList: any
  refetchProductTrashList: any
}

const ModalOfProduct: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, handleSubmit } = useFormikContext<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getTagsReducer = useSelector((state: RootState) => state.tags)
  const getBrandReducer = useSelector((state: RootState) => state.brand)
  const getUnitReducer = useSelector((state: RootState) => state.unit)

  const { handleUpdate, handleDelete } = useHandleActionMasterData({
    errors,
    values,
    createMasterData: props.createProduct,
    updateMasterData: props.updateProduct,
    typeUpdateMasterData: ETransformationDataToServer.UpdateProduct,
    deleteMasterData: props.deleteProduct,
    refetchMasterData: props.refetchProductList,
    refetchMasterDataTrashList: props.refetchProductTrashList,
    closeModal: props.closeModal,
    key: 'productID',
  })

  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <Form className={styles.containerModal}>
        <div className={styles.section}>
          <h3>{t('productManagement.titleModol')}</h3>
          <img className={styles.closeIcon} src={closeSVG} alt="closeSVG" onClick={props.closeModal} />
        </div>
        <SelectSingle
          name={'formInput.productBrandID'}
          option={getBrandReducer.brandOptionList}
          disabled={false}
          label="Brand"
          require
        />
        <TextField name={`formInput.productName`} type="text" label="Product Name" require />

        <AutoComplete
          name={'formInput.tagList'}
          label={'Tags'}
          value={values.formInput.tagList}
          option={getTagsReducer.tagsProductListOption}
        />

        <div className={styles.rowSelect}>
          <div className={styles.maxSelect}>
            <SelectSingle
              name={'formInput.productUnit_id'}
              option={getUnitReducer.unitListOption}
              disabled={false}
              label="Unit/Star"
              require
            />
          </div>
          <p className={styles.centerText}>= 1 Stars</p>
        </div>
        <div className={styles.rowButton}>
          {values.formInput.productID && (
            <div className={styles.button}>
              <Button
                typeButton="button"
                type={'outline'}
                disabled={false}
                name={t<string>('common.delete')}
                icon={deleteSVG}
                fullWidth
                functionOnClick={() =>
                  dispatch(
                    setOpenDialog({
                      title: t<string>('productManagement.deleteTitle'),
                      description: t<string>('productManagement.deleteDescription'),
                      status: 'danger',
                      functionOnSubmit: handleDelete,
                    }),
                  )
                }
              />
            </div>
          )}
          <div className={styles.button}>
            <Button
              typeButton="submit"
              type={'primary'}
              disabled={false}
              name={t<string>('common.save')}
              fullWidth
              functionOnClick={() => {
                handleSubmit()
                handleUpdate()
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalOfProduct
