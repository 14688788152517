// Lib
import React, { CSSProperties, useMemo, useState } from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { getIn, useFormikContext } from 'formik'

//Images

// Include in project
import styles from './index.module.scss'
import { Props } from './config'

const TextAreaComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()

  const [countText, setCountText] = useState(0)
  const justifyContent = useMemo(() => {
    switch ((props.label, props.helperText)) {
      case props.label && props.helperText:
        return 'space-between'
      case props.label && !props.helperText:
        return 'flex-start'
      case !props.label && props.helperText:
        return 'flex-end'
      default:
        return 'space-between'
    }
  }, [])

  return (
    <div className={`${styles.main}`} style={{ '--justifyContent': justifyContent } as CSSProperties}>
      <p className={`${styles.title}`}>{props.label}</p>
      <TextareaAutosize
        name={props.name}
        className={`fontRegular ${styles.content}`}
        value={getIn(values, props.name)}
        onChange={(e) => {
          setCountText(e.target.textLength)
          setFieldValue(`${props.name}`, e.target.value)
        }}
        placeholder={props.placeholder}
        maxLength={props.maxLength && props.maxLength}
        disabled={props.disabled}
      />
      {props.helperText && (
        <div className={`${styles.helperFooter}`}>
          <p>{props.helperText}</p>
          <p>
            {countText}/{props.maxLength}
          </p>
        </div>
      )}
    </div>
  )
}
export default TextAreaComponent
