import { gql } from '@apollo/client'

export const GET_LIST_SHOP_RETAILER = gql`
  query ListShopRetailer($nextToken: String, $pageSize: Int!, $filter: FilterListShopRetailerInput) {
    listShopRetailer(nextToken: $nextToken, pageSize: $pageSize, filter: $filter) {
      res_code
      res_desc
      nextToken
      items {
        retailerID
        retailerName
        sourceType
        zaloID
        tagList {
          tagID
          tagColor
          tagName
          typeTag
        }
        retailerCode
        phoneNumber
        isActive
        email
        countryID
        addressID
        zoneName
        provinceName
        districtName
        segmentName
        zoneID
        provinceID
        districtID
        segmentID
        lineUserID
        isLineVerify
      }
    }
  }
`
export const CREATE_SHOP_RETAILER = gql`
  mutation CreateShopRetailer($input: CreateShopRetailerInput!) {
    createShopRetailer(input: $input) {
      res_code
      res_desc
      addressID
      countryID
      email
      isActive
      phoneNumber
      retailerCode
      retailerID
      retailerName
      sourceType
      tagList {
        isActive
        tagColor
        tagID
        tagName
        typeTag
      }
      zaloID
      districtID
      districtName
      provinceID
      provinceName
      segmentID
      segmentName
      zoneID
      zoneName
      lineUserID
      isLineVerify
    }
  }
`

export const UPDATE_SHOP_RETAILER = gql`
  mutation UpdateShopRetailer($input: UpdateShopRetailerInput!) {
    updateShopRetailer(input: $input) {
      res_code
      res_desc
      countryID
      email
      isActive
      addressID
      zaloID
      phoneNumber
      retailerCode
      retailerID
      retailerName
      sourceType
      tagList {
        isActive
        tagColor
        tagID
        tagName
        typeTag
      }
      zaloID
      zoneID
      zoneName
      provinceID
      provinceName
      districtID
      districtName
      segmentID
      segmentName
      lineUserID
      isLineVerify
    }
  }
`

export const DELETE_SHOP_RETAILER = gql`
  mutation DeleteShopRetailer($input: DeleteShopRetailerInput!) {
    deleteShopRetailer(input: $input) {
      res_code
      res_desc
      retailerID
    }
  }
`

export const RECOVERY_SHOP_RETAILER = gql`
  mutation RecoveryShopRetailer($input: RecoveryShopRetailerInput!) {
    recoveryShopRetailer(input: $input) {
      res_code
      res_desc
    }
  }
`
