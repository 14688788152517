import Color from 'abstracts/export.module.scss'

export interface Props {
  label?: string
  option: {
    label: string
    value: string
  }[]
  disabled: boolean
  value: []
  name: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  sx: {
    height: '30px',
    '&& .Mui-selected': {
      backgroundColor: `${Color.primarySecond}`,
      '&:hover': {
        backgroundColor: `${Color.primaryHover}`,
      },
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const SelectSx = {
  '&': {
    backgroundColor: `${Color.white}`,
    marginTop: '5px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // border: `1px solid ${Color.primaryMain}`,
  },
}

export const CheckboxSx = {
  '&.Mui-checked': {
    color: `${Color.primaryMain}`,
  },
}

export const ListItemTextSx = {
  '&.Mui-selected': { color: `${Color.white}` },
}
