// Lib
import React, { useMemo, useState } from 'react'
import { Backdrop } from '@mui/material'
import { useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

// Images

// Include in project
import styles from './index.module.scss'
import { NavSideBar, NavTopBar } from 'container'
import Color from 'abstracts/export.module.scss'
import type { RootState } from 'states/store'
import { menuApp, IMenuApp, ISubMenuApp } from 'config'

interface Props {
  children?: JSX.Element | JSX.Element[]
  open: boolean
  section: string
}

const Layout: React.FC<Props> = (props: Props): JSX.Element => {
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(false)
  const sidebarClass = useMemo(() => (sideNavOpen ? 'gridNavbarOpen' : 'gridNavbar'), [sideNavOpen])
  const location = useLocation()
  const permission = useSelector((state: RootState) => state.decodeToken.permission)

  const flatMenu: IMenuApp[] & ISubMenuApp[] = []
  menuApp.map((data: IMenuApp) => {
    if (data.isSubMenu) {
      data.subMenu?.flat().map((dataSub) => flatMenu.push(dataSub))
    } else {
      flatMenu.push(data)
    }
  })
  const findLocationNowInFlatMenu = useMemo(
    () => flatMenu.find((ele) => ele.link === location.pathname),
    [location.pathname],
  )

  if (!permission) return <CircularProgress />
  if (!findLocationNowInFlatMenu || permission[findLocationNowInFlatMenu.accessKey as string]) {
    return (
      <div className={styles.gridLayout}>
        <div className={styles[sidebarClass]}>
          <NavSideBar sideNavOpen={sideNavOpen} setSideNavOpen={setSideNavOpen} />
        </div>
        <div className={styles.gridSection}>
          <NavTopBar
            section={props.section}
            open={props.open}
            sideNavOpen={sideNavOpen}
            setSideNavOpen={setSideNavOpen}
          />
          <Backdrop
            sx={{ color: Color.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={sideNavOpen}
            onClick={() => setSideNavOpen(false)}
          />
        </div>
        <div className={styles.gridData}>
          <>{props.children}</>
        </div>
      </div>
    )
  } else return <Navigate to="/" replace />
}

export default Layout
