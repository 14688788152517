// Lib
import React, { useMemo } from 'react'
import { Box, Button, Modal, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

//Images
import { ReactComponent as Cross } from 'images/commons/cross.svg'
import { ReactComponent as Danger } from 'images/commons/danger.svg'
import { ReactComponent as Success } from 'images/commons/success.svg'
import { ReactComponent as Info } from 'images/commons/info.svg'
import { ReactComponent as Warning } from 'images/commons/warning.svg'

// Include in project
import { cancelColorList, okeyColorList, ModalSx } from './config'
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseDialog, setCommentDialog } from 'states/slice/modalDialog'

const AlertIcon: React.FC<string> = (data: string): JSX.Element => {
  switch (data) {
    case 'danger':
      return <Danger className={`${styles.iconAlert}`} />
    case 'success':
      return <Success className={`${styles.iconAlert}`} />
    case 'warning':
      return <Warning className={`${styles.iconAlert}`} />
    case 'info':
      return <Info className={`${styles.iconAlert}`} />
    default:
      return <></>
  }
}

const ModalDialogWithRedux: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const modalDialogReducer = useSelector((state: RootState) => state.modalDialog)

  if (!modalDialogReducer) return <></>
  else {
    const cancelColor = useMemo(() => cancelColorList[modalDialogReducer.status], [modalDialogReducer.status])
    const okeyColor = useMemo(() => okeyColorList[modalDialogReducer.status], [modalDialogReducer.status])

    const isDisabledInput = () => {
      if (!modalDialogReducer.titleInput) return false
      if (modalDialogReducer?.comment) {
        return false
      } else {
        return true
      }
    }

    return (
      <Modal keepMounted sx={ModalSx} onClose={() => dispatch(setCloseDialog())} open={modalDialogReducer.isOpen}>
        <Box>
          <div className={`${styles.main}`}>
            <div className={`${styles.head}`}>
              <div className={`${styles.titleBox}`}>
                {modalDialogReducer.status !== 'default' && AlertIcon(modalDialogReducer.status)}
                <h5>{modalDialogReducer.title}</h5>
              </div>
              {modalDialogReducer.status == 'default' && (
                <Cross onClick={() => dispatch(setCloseDialog())} className={`${styles.cross}`} />
              )}
            </div>
            <div className={`${modalDialogReducer.status !== 'default' ? styles.body : styles.paddingDetail}`}>
              <p>{modalDialogReducer.description}</p>
            </div>

            {modalDialogReducer.titleInput && (
              <div className={styles.formInput}>
                <p>{modalDialogReducer.titleInput}</p>
                <TextField
                  fullWidth
                  size="small"
                  onChange={(e) => dispatch(setCommentDialog({ comment: e.target.value }))}
                />
              </div>
            )}

            <div className={`${styles.footer}`}>
              <Button
                sx={cancelColor}
                onClick={() => dispatch(setCloseDialog())}
                style={{ gap: '10px', textTransform: 'none' }}
                variant="contained"
                className="fontBold"
              >
                Cancle
              </Button>
              <Button
                sx={okeyColor}
                onClick={() => modalDialogReducer.functionOnSubmit(modalDialogReducer.comment)}
                style={{ gap: '10px', textTransform: 'none' }}
                variant="contained"
                className="fontBold"
                disabled={isDisabledInput()}
              >
                Okay
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }
}

export default ModalDialogWithRedux
