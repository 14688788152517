import { gql } from '@apollo/client'

export const LIST_APPROVE_CAMPAIGN_CALCULATE_POINT = gql`
  query ApproveCampaignCalculatePointList(
    $pageSize: Int!
    $nextToken: String
    $filter: FilterListCampaignCalculationPointInput
  ) {
    approveCampaignCalculatePointList(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      nextToken
      items {
        campaignCalculationID
        shopID
        shopType
        shopName
        phoneNumber
        saleVolume
        rawPoint
        adjustmentPoint
        point
        updatedAt
        shopCode
      }
    }
  }
`

export const GET_APPROVE_POINT_HISTORY_DETAIL = gql`
  query ApprovePointHistoryDetail(
    $campaignID: String!
    $campaignCalculationID: Int!
    $shopID: Int!
    $shopType: EShopType!
    $month: Int
    $year: Int
  ) {
    approvePointHistoryDetail(
      campaignID: $campaignID
      campaignCalculationID: $campaignCalculationID
      shopID: $shopID
      shopType: $shopType
      month: $month
      year: $year
    ) {
      res_code
      res_desc
      campaignID
      campaignName
      restrictionStartDate
      restrictionEndDate
      customerName
      phoneNumber
      point
      targetProductList {
        tagID
        tagName
        productID
        productName
        levelList {
          level
          point
          actualTarget
          target
          actualPoint
        }
      }
      pointTransaction {
        inputDate
        orderCode
        saleVolume
        targetProduct
        productName
        unitName
        point
        insertBy
      }
    }
  }
`

export const APPROVE_POINT_CAMPAIGN = gql`
  mutation Mutation($input: ApprovePointCampaignInput!) {
    approvePointCampaign(input: $input) {
      res_code
      res_desc
      campaignID
      pointStatus
      remarkPoint
    }
  }
`
