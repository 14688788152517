// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'

// Images

// Include in project
import Layout from 'layouts'
import { HeadOfTableCampaign } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { useCampaignSpecialList, useInfiniteScroll } from 'hooks'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'
import { ECampaignListStatus, ECampaignListType } from 'utils/generated'

const ListOfSpecialCampaigns: React.FC = () => {
  const { values } = useFormikContext<{ status: ECampaignListStatus }>()
  const { t } = useTranslation()

  const { data: resCampaignsList, refetch } = useCampaignSpecialList({
    pageSize: 25,
    status: values.status,
    type: ECampaignListType.Special,
    nextToken: null,
  })

  const { dataList } = useInfiniteScroll({
    result: resCampaignsList,
    refetch: refetch,
    variables: {
      pageSize: 25,
      status: values.status,
      type: ECampaignListType.Special,
      nextToken: resCampaignsList?.nextToken,
    },
    filter: values,
    key: 'campaignsList',
  })

  const campaignList = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.ListSpecialCampaign,
  })

  return (
    <Layout open={false} section={t('ListSpecialCampaigns.title')}>
      <div className={styles.container}>
        <HeadOfTableCampaign
          buttonName={t('ListSpecialCampaigns.create_special')}
          pathName="/campaign/list-special/create"
        />
        <Table
          colSize={{
            campaignID: '150px',
            campaignName: '200px',
            campaignTargetType: 'auto',
            // targetTypeValueHTML: 'auto',
            campaignType: 'auto',
            campaignShopType: 'auto',
            promotion: '350px',
            timeline: '150px',
            countryName: 'auto',
            campaignStatusHTML: 'auto',
            view: 'auto',
          }}
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            campaignTargetType: 'Target Type',
            // targetTypeValueHTML: 'Target Type Value',
            campaignType: 'Campaign Type',
            campaignShopType: 'Shop Type',
            promotion: 'Special Promotion',
            timeline: 'Timeline',
            countryName: 'Country',
            campaignStatusHTML: 'Status',
            view: 'Data',
          }}
          data={campaignList || []}
        />
      </div>
    </Layout>
  )
}

const EnhancedListOfSpecialCampaigns = withFormik({
  mapPropsToValues: () => ({
    status: 'ALL',
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(ListOfSpecialCampaigns)
export default EnhancedListOfSpecialCampaigns
