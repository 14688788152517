// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'
import { transformationDataToServer, ETransformationDataToServer } from 'utils/tranformData'
import { MutationCampaignRes } from 'utils/generated'

interface Props {
  type: 'NORMAL' | 'SPECIAL'
  requestApproveCampaign: any
  createNormalCampaign?: any
  updateNormalCampaign?: any
  createSpecialCampaign?: any
  updateSpecialCampaign?: any
}

const FooterButtonCreateCampaign: React.FC<Props> = ({
  type,
  requestApproveCampaign,

  // Normal
  createNormalCampaign,
  updateNormalCampaign,

  // Special
  createSpecialCampaign,
  updateSpecialCampaign,
}) => {
  const { t } = useTranslation()
  const { values, errors, setFieldValue, handleSubmit } = useFormikContext<any>()
  const navigate = useNavigate()

  const handleUpdateNormal = () => {
    if (!_.isEmpty(errors)) return
    if (values.activeStep === 0) {
      // Create Campaign Normal
      const packData: any = transformationDataToServer({
        values: values.campaign,
        type: ETransformationDataToServer.UpdateNormalCampaign,
      })
      // console.log('packData', packData)
      if (!values.campaign.campaignID) {
        delete packData.input.campaignID

        createNormalCampaign({ variables: packData }).then((res: any) => {
          const resCreate = res.data.createNormalCampaign
          if (resCreate.res_code === '99') return

          setFieldValue('activeStep', values.activeStep + 1)
          setFieldValue('campaign.campaignID', resCreate.campaignID)
        })
      } else {
        updateNormalCampaign({ variables: packData }).then(
          (res: { data: { updateNormalCampaign: MutationCampaignRes } }) => {
            if (res.data.updateNormalCampaign.res_code === '99') return

            setFieldValue('activeStep', values.activeStep + 1)
            setFieldValue('campaign.campaignID', res.data.updateNormalCampaign.campaignID)
          },
        )
      }
    } else if (values.activeStep === 1) {
      // Send Campaign to Approve
      const packData = transformationDataToServer({
        values: values.campaign,
        type: ETransformationDataToServer.RequestApproveCampaign,
      })
      // console.log(packData, 'packData')
      requestApproveCampaign({ variables: packData }).then((res: any) => {
        if (res.data.requestApproveCampaign.res_code === '99') return

        setFieldValue('activeStep', values.activeStep + 1)
        setFieldValue('campaign.campaignStatus', 'WAITING_APPROVE')
      })
    } else {
      navigate('/campaign/list-normal')
    }
  }

  const handleUpdateSpecial = () => {
    if (!_.isEmpty(errors)) return

    if (values.activeStep === 0) {
      // Create Campaign Special
      const packData: any = transformationDataToServer({
        values: values.campaign,
        type: ETransformationDataToServer.UpdateSpecialCampaign,
      })
      if (!packData.input.campaignShopType) delete packData.input.campaignShopType
      if (!values.campaign.campaignID) {
        delete packData.input.campaignID
        createSpecialCampaign({ variables: packData }).then((res: any) => {
          if (res.data.createSpecialCampaign.res_code === '99') return

          setFieldValue('activeStep', values.activeStep + 1)
          setFieldValue('campaign.campaignID', res.data.createSpecialCampaign.campaignID)
        })
      } else {
        updateSpecialCampaign({ variables: packData }).then((res: any) => {
          if (res.data.updateSpecialCampaign.res_code === '99') return

          setFieldValue('activeStep', values.activeStep + 1)
          setFieldValue('campaign.campaignID', res.data.updateSpecialCampaign.campaignID)
        })
      }
    } else if (values.activeStep === 1) {
      // Send Campaign to Approve
      const packData: any = transformationDataToServer({
        values: values.campaign,
        type: ETransformationDataToServer.RequestApproveCampaign,
      })
      // console.log(packData, 'packData')
      requestApproveCampaign({ variables: packData }).then((res: any) => {
        if (res.data.requestApproveCampaign.res_code === '99') return

        setFieldValue('activeStep', values.activeStep + 1)
        setFieldValue('campaign.campaignStatus', 'WAITING_APPROVE')
      })
    } else {
      navigate('/campaign/list-special')
    }
  }

  const handleBack = () => {
    if (values.activeStep === 0) {
      if (type === 'NORMAL') {
        navigate('/campaign/list-normal')
      } else {
        navigate('/campaign/list-special')
      }
    }
    setFieldValue('activeStep', values.activeStep - 1)
  }

  return (
    <section className={styles.container}>
      {values.activeStep === 2 ? (
        ''
      ) : (
        <Button
          typeButton={'button'}
          type={'secondary'}
          disabled={false}
          name={values.activeStep === 0 ? `${t<string>('common.cancel')}` : `Edit`}
          functionOnClick={() => handleBack()}
        />
      )}

      <Button
        typeButton={'submit'}
        type={'primary'}
        disabled={false}
        functionOnClick={() => {
          handleSubmit()
          if (type === 'NORMAL') return handleUpdateNormal()
          else return handleUpdateSpecial()
        }}
        name={
          values.activeStep === 0
            ? `${t<string>('common.save')}`
            : values.activeStep === 1
            ? `${t<string>('createNormalCampaign.requestApprove')}`
            : `${t<string>('common.done')}`
        }
      />
    </section>
  )
}
export default FooterButtonCreateCampaign
