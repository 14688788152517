import { useLazyQuery, useMutation } from '@apollo/client'
import { useHandleResponseAPI } from 'hooks'
import { APPROVE_CAMPAIGN, GET_CAMPAIGN_DETAIL } from 'queries/campaign.queries'

function useCampaign() {
  const [getCampaign, { data, loading, error }] = useLazyQuery(GET_CAMPAIGN_DETAIL, {
    fetchPolicy: 'no-cache',
  })

  const [approveCampaign, optionApprove] = useMutation(APPROVE_CAMPAIGN)

  const isLoading = loading || optionApprove.loading

  const errMsg = () => {
    // LazyQuery
    if (error || data?.campaignDetail?.res_code === '99') return JSON.stringify(error || data?.campaignDetail?.res_desc)

    // optionApprove
    if (optionApprove?.error || optionApprove?.data?.approveCampaign?.res_code === '99')
      return JSON.stringify(optionApprove?.error || optionApprove?.data?.approveCampaign?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    getCampaign,
    approveCampaign,
  }
}

export default useCampaign
