import Color from 'abstracts/export.module.scss'

export interface Props {
  children: JSX.Element | JSX.Element[]
  title: string
  expanded?: boolean
}

export const ThemeColor = {
  backgroundColor: `${Color.grey100}`,
}
