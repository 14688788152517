import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCloseNotification, setOpenNotificationLoading, setOpenNotification } from 'states/slice/notification'

interface Props {
  isLoading: boolean
  errMsg: string
}

const useHandleResponseAPI = ({ isLoading, errMsg }: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (errMsg) {
      dispatch(
        setOpenNotification({
          title: 'Error From Server',
          description: errMsg,
          typeNotification: 'danger',
        }),
      )
    } else if (isLoading) {
      dispatch(
        setOpenNotificationLoading({
          title: 'Processing loading or sending from client',
          description: 'loading....',
          typeNotification: 'loading',
          isLoading: true,
        }),
      )
    } else {
      // TODO Check redux typeNotification === danger ห้ามเคลีย
      dispatch(setCloseNotification())
    }
  }, [isLoading, errMsg])
}

export default useHandleResponseAPI
