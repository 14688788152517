// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { convertRangeDate } from 'utils/convertDate'

// Include in project
import styles from './index.module.scss'

interface Props {
  data: any
}

const HeadOfPointHistory: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <div className={styles.wrapperText}>
        <h4>{t<string>('common.campaign')}</h4>
        <div className={styles.gridText}>
          <h6 className={styles.text}>
            {t<string>('createNormalCampaign.name')} :{' '}
            <pre className={styles.textDetail}>{props.data.campaignName}</pre>
          </h6>
          <h6 className={styles.text}>
            {t<string>('common.timeline')} :{' '}
            <pre className={styles.textDetail}>
              {convertRangeDate([props.data.restrictionStartDate, props.data.restrictionEndDate])}
            </pre>
          </h6>
        </div>
      </div>

      <div className={styles.wrapperText}>
        <h4>{t<string>('common.shop')}</h4>
        <div className={styles.gridText}>
          <h6 className={styles.text}>
            {t<string>('common.customerName')} : <pre className={styles.textDetail}>{props.data.customerName}</pre>
          </h6>
          <h6 className={styles.text}>
            {t<string>('common.contact')} : <pre className={styles.textDetail}>{props.data.phoneNumber || '-'}</pre>
          </h6>
        </div>
      </div>
    </section>
  )
}
export default HeadOfPointHistory
