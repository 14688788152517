// Lib
import React from 'react'

//Images

// Include in project
import { ChipInput } from 'component'
import { Props } from './config'
import { EPointStatus } from 'utils/generated'

const ChipByStatusPoint: React.FC<Props> = (props: Props): JSX.Element => {
  switch (props.status.toLocaleUpperCase()) {
    case EPointStatus.Approved:
      return <ChipInput color="success" label="Approved" />
    case EPointStatus.WaitingApprove:
      return <ChipInput color="warning" label="Waiting Approve" />
    case EPointStatus.WaitingReview:
      return <ChipInput color="info" label="Waiting Review" />
    case EPointStatus.Rejected:
      return <ChipInput color="danger" label="Rejected" />

    default:
      return <></>
  }
}

export default ChipByStatusPoint
