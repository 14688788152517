import { useQuery } from '@apollo/client'
import { useHandleResponseAPI } from 'hooks'
import { GET_LIST_CAMPAIGN } from 'queries/campaign.queries'

function useCampaignList(variables?: any) {
  const { data, loading, error, refetch } = useQuery(GET_LIST_CAMPAIGN, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const errMsg = () => {
    if (error || data?.campaignsList?.res_code === '99') return JSON.stringify(error || data?.campaignsList?.res_desc)
    return ''
  }

  useHandleResponseAPI({
    isLoading: loading,
    errMsg: errMsg(),
  })

  return {
    data: data?.campaignsList,
    refetch,
  }
}

export default useCampaignList
