// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'

// Images
import deleteSVG from 'images/commons/delete.svg'
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField } from 'component'
import { setOpenDialog } from 'states/slice/modalDialog'
import { ETransformationDataToServer } from 'utils/tranformData'
import { useHandleActionMasterData } from 'hooks'

interface Props {
  openModal: boolean
  closeModal: () => void
  createUnit: any
  updateUnit: any
  deleteUnit: any
  refetchUnitList: any
  refetchUnitTrashList: any
}

const ModalOfUnit: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, handleSubmit } = useFormikContext<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { handleUpdate, handleDelete } = useHandleActionMasterData({
    errors,
    values,
    createMasterData: props.createUnit,
    updateMasterData: props.updateUnit,
    typeUpdateMasterData: ETransformationDataToServer.UpdateUnit,
    deleteMasterData: props.deleteUnit,
    refetchMasterData: props.refetchUnitList,
    refetchMasterDataTrashList: props.refetchUnitTrashList,
    closeModal: props.closeModal,
    key: 'id',
  })

  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <Form className={styles.containerModal}>
        <div className={styles.section}>
          <h3>{t('unitManagement.titleModol')}</h3>
          <img className={styles.closeIcon} src={closeSVG} alt="closeSVG" onClick={props.closeModal} />
        </div>
        <TextField name={`formInput.unitName`} type="text" label="Unit" require />
        <div className={styles.rowButton}>
          {values.formInput.id && (
            <div className={styles.button}>
              <Button
                typeButton="button"
                type={'outline'}
                disabled={false}
                name={t<string>('common.delete')}
                icon={deleteSVG}
                fullWidth
                functionOnClick={() =>
                  dispatch(
                    setOpenDialog({
                      title: t<string>('unitManagement.deleteTitle'),
                      description: t<string>('unitManagement.deleteDescription'),
                      status: 'danger',
                      functionOnSubmit: handleDelete,
                    }),
                  )
                }
              />
            </div>
          )}
          <div className={styles.button}>
            <Button
              typeButton="submit"
              type={'primary'}
              disabled={false}
              name={t<string>('common.save')}
              fullWidth
              functionOnClick={() => {
                handleSubmit()
                handleUpdate()
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalOfUnit
