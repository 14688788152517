//Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

//Includes Project
import { TextField } from 'component'
import styles from './index.module.scss'

interface Props {
  level: string[]
}
const TableCustomerSpecialCampaign: React.FC<Props> = (props: Props) => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  return (
    <section className={styles.overflowTable}>
      {values.campaign.targetTypeValue.length > 0 && (
        <table className={styles.tableMain}>
          <thead>
            <tr>
              <th rowSpan={2}>
                <p>{t<string>('common.targetTypeValue')}</p>
              </th>
              <th rowSpan={2}>
                <p>{t<string>('createNormalCampaign.promotion')}</p>
              </th>
              {props.level.map((ele: any, index: number) => (
                <th key={index}>
                  <p>{ele}</p>
                </th>
              ))}
            </tr>
            <tr>
              {props.level.map((ele: any, index: number) => (
                <th key={index}>
                  <p>{t<string>('common.target')}</p>
                </th>
              ))}
            </tr>
          </thead>
          {values.campaign.targetTypeValue &&
            values.campaign.targetTypeValue.map((item: any, index: number) => (
              <tbody key={index}>
                <tr key={index}>
                  <td className={styles.thRow} rowSpan={item.productList.length}>
                    {item.label}
                  </td>
                  <td>{item.productList[0]?.productName}</td>
                  {item.productList[0].targetList.map((ele: any, indexKey: number) => (
                    <td key={indexKey}>
                      {values.activeStep === 0 ? (
                        <div className={styles.flexData}>
                          <TextField
                            name={`campaign.targetTypeValue[${index}].productList[0].targetList[${indexKey}].amount`}
                            type={'number'}
                          />
                          <p>{t<string>('common.ton')}</p>
                        </div>
                      ) : (
                        <div className={styles.flexData}>
                          <p>{ele.amount}</p>
                          <p>{t<string>('common.ton')}</p>
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
                {item.productList.length > 1 &&
                  item.productList.slice(1).map((product: any, indexP: number) => (
                    <tr key={indexP}>
                      <td>{product.productName}</td>
                      {product.targetList.map((ele: any, indexKey: number) => (
                        <td key={indexKey}>
                          {values.activeStep === 0 ? (
                            <div className={styles.flexData}>
                              <TextField
                                name={`campaign.targetTypeValue[${index}].productList[${
                                  indexP + 1
                                }].targetList[${indexKey}].amount`}
                                type={'number'}
                              />
                              <p>{t<string>('common.ton')}</p>
                            </div>
                          ) : (
                            <div className={styles.flexData}>
                              <p>{ele.amount}</p>
                              <p>{t<string>('common.ton')}</p>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            ))}
        </table>
      )}
    </section>
  )
}

export default TableCustomerSpecialCampaign
