import { gql } from '@apollo/client'

export const GET_LIST_ZONE = gql`
  query ListZone($pageSize: Int!, $nextToken: String, $filter: FilterListZoneInput) {
    listZone(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      items {
        zoneID
        zoneName
        countryID
        isActive
      }
    }
  }
`

export const UPDATE_ZONE = gql`
  mutation UpdateZone($input: UpdateZoneInput!) {
    updateZone(input: $input) {
      res_code
      res_desc
    }
  }
`

export const CREATE_ZONE = gql`
  mutation CreateZone($input: CreateZoneInput!) {
    createZone(input: $input) {
      res_desc
      res_code
    }
  }
`

export const RECOVERY_ZONE = gql`
  mutation RecoveryZone($input: RecoveryZoneInput) {
    recoveryZone(input: $input) {
      res_code
      res_desc
    }
  }
`

export const DELETE_ZONE = gql`
  mutation DeleteZone($input: DeleteZoneInput) {
    deleteZone(input: $input) {
      res_code
      res_desc
    }
  }
`
