// Lib
import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { ThemeProvider } from '@mui/material/styles'
import { getIn, useFormikContext } from 'formik'

//Images

// Include in project
import { Props, ColorThemeProvider, ColorThemeTextField } from './config'
import styles from './index.module.scss'

const AutoCompleteComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { setFieldValue, errors, touched } = useFormikContext<any>()
  return (
    // <ThemeProvider theme={ColorThemeProvider}>
    <div>
      <p>
        {props.label}
        <span className={'startRequire'}>{props.require && '*'}</span>
      </p>
      <Autocomplete
        size="small"
        id={props.name}
        fullWidth
        multiple
        value={props.value}
        options={props.option}
        filterSelectedOptions
        disabled={props.disabled}
        onChange={(event, value, reason, details) => {
          if (reason === 'selectOption') {
            // Add
            setFieldValue(props.name, [...props.value, details?.option], false)
          } else if (reason === 'removeOption') {
            // Remove
            const editArr = props.value.filter((value: any, index: any) => {
              return details?.option.label !== value.label
            })
            setFieldValue(props.name, editArr, false)
          } else if (reason === 'clear') {
            // Clear
            setFieldValue(props.name, [], false)
          }
        }}
        renderOption={(props, option: { value: string; label: string }) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => {
          return (
            <TextField
              color="primary"
              //  label={props.label}
              error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
              placeholder={props.placeholder}
              sx={ColorThemeTextField}
              {...params}
              helperText={
                <pre className="errorMsg">
                  {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
                </pre>
              }
            />
          )
        }}
      />
    </div>

    // </ThemeProvider>
  )
}

export default AutoCompleteComponent
