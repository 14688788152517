// Lib
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { Button, Table } from 'component'
import { FooterButtonApprovePoints, ModalOfApprovePoints, SearchYearAndMonth } from 'container'
import { ECampaignListType, EPointStatus } from 'utils/generated'
import { useCampaignApproveCalculatePointList, useCampaignNormal, useCampaignSpecial } from 'hooks'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'
import { getDefaultMonthYear } from 'utils/other'

const ApprovePointCampaignDetail: React.FC = () => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const [searchParams] = useSearchParams()

  //get Campaign
  const { getCampaign: getCampaignNoraml } = useCampaignNormal()
  const { getCampaign: getCampaignSpecial } = useCampaignSpecial()

  const {
    data: resCampaignApproveCalPointList,
    getApprovePointHistory,
    approvePointCampaign,
  } = useCampaignApproveCalculatePointList({
    pageSize: 25,
    nextToken: null,
    filter: {
      campaignID: searchParams.get('campaignID'),
      month: searchParams.get('campaignType') === ECampaignListType.Normal ? values.filter.month : null,
      year: searchParams.get('campaignType') === ECampaignListType.Normal ? values.filter.year : null,
    },
  })

  const campaignType = useMemo(() => {
    if (searchParams.get('campaignType') === ECampaignListType.Normal) return ECampaignListType.Normal
    return ECampaignListType.Special
  }, [searchParams])

  //Query Get Campaign
  useEffect(() => {
    if (campaignType === ECampaignListType.Normal) {
      getCampaignNoraml({
        variables: {
          campaignId: searchParams.get('campaignID'),
          type: `${campaignType}`,
          month: values.filter.month,
          year: values.filter.year,
        },
      }).then((res) => {
        const detailCampaign = [res.data.campaignDetail]
        setFieldValue('campaignDetail', detailCampaign)
      })
    } else if (campaignType === ECampaignListType.Special) {
      getCampaignSpecial({ variables: { campaignId: searchParams.get('campaignID'), type: `${campaignType}` } }).then(
        (res) => {
          const detailCampaign = [res.data.campaignDetail]
          setFieldValue('campaignDetail', detailCampaign)
        },
      )
    }
  }, [])

  //Top Table
  const campaignDetail = transformationDataIntoTable({
    dataList: values.campaignDetail,
    type: ETranformationDataIntoTable.ListGetCampaignApprovePoint,
  })

  //Bottom Table
  const approveCampaignCalPointListData = transformationDataIntoTable({
    dataList: resCampaignApproveCalPointList?.items,
    type: ETranformationDataIntoTable.ListApproveCalculatePoint,
    handleOpen(data) {
      setFieldValue('modal', { ...values.modal, isOpenModalApprovePoint: !values.modal.isOpenModalApprovePoint })
      setFieldValue('approveCalculatePointDetail', data)
    },
  })

  const handleExcelApproveCampaignCalculationPoint = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `ApprovePointCalculationPoint-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.approvePointStar(values.campaignDetail[0], resCampaignApproveCalPointList?.items)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={true} section={t('approvePoint.section')}>
      <section className={styles.container}>
        <div className={styles.wrapperTable}>
          <div style={{ textAlign: 'end' }}>
            <Button
              typeButton="button"
              type="primary"
              disabled={false}
              name="Export Excel"
              functionOnClick={handleExcelApproveCampaignCalculationPoint}
            />
          </div>
          <Table
            objectKey={{
              campaignID: 'Campaigns ID',
              campaignName: 'Campaigns Name',
              campaignTargetType: 'Target Type',
              targetTypeValueHTML: 'Target Type Value',
              shopTagsListHTML: 'Tags Retailer',
              campaignType: 'Campaign Type',
              shopType: 'Shop Type',
              timeline: 'Timeline',
              campaignStatusHTML: 'Status',
              pointApprovalStatusHTML: 'Point Approval',
              lastUpdateFormat: 'Last Updated',
            }}
            data={campaignDetail}
          />
          {campaignType === ECampaignListType.Normal ? <SearchYearAndMonth /> : <div></div>}
          <Table
            objectKey={{
              shopType: 'Customer Type',
              shopName: 'Customer Name',
              phoneNumber: 'Tel.',
              saleVolume: 'Sale Volume',
              rawPoint: 'Raw Point',
              adjustmentPoint: 'Adj. Point',
              AccPointHTML: 'Acc. Point',
              lastUpdateFormat: 'Last Update',
              actionView: 'Detail',
            }}
            data={approveCampaignCalPointListData}
          />
        </div>

        {campaignDetail?.[0]?.pointStatus === EPointStatus.WaitingApprove && (
          <FooterButtonApprovePoints approvePointCampaign={approvePointCampaign} />
        )}
      </section>

      <ModalOfApprovePoints
        onClose={() =>
          setFieldValue('modal', {
            ...values.modal,
            isOpenModalApprovePoint: !values.modal.isOpenModalApprovePoint,
          })
        }
        isOpen={values.modal.isOpenModalApprovePoint}
        getApprovePointHistory={getApprovePointHistory}
      />
    </Layout>
  )
}

const EnhancedApprovePointCampaignDetail = withFormik({
  mapPropsToValues: () => ({
    filter: {
      year: getDefaultMonthYear().defaultYear,
      month: getDefaultMonthYear().defaultMonth,
    },
    modal: {
      isOpenModalApprovePoint: false,
      isModalApproveConfirm: false,
    },
    totalPoint: 0,
    approveCalculatePointDetail: '',
    campaignDetail: [],
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(ApprovePointCampaignDetail)
export default EnhancedApprovePointCampaignDetail
