// Lib
import React from 'react'
import TextField from '@mui/material/TextField'
import { getIn, useFormikContext } from 'formik'

//Images

// Include in project
import styles from './index.module.scss'
import { Props } from './config'
import Color from 'abstracts/export.module.scss'
import { InputAdornment } from '@mui/material'

const TextFieldComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <div className={styles.wapper}>
      <p>
        {props.label}
        <span className={'startRequire'}>{props.require && '*'}</span>
      </p>
      <TextField
        InputProps={{
          sx: {
            marginTop: '5px',
            fontSize: '14px',
            backgroundColor: Color.white,
          },
          startAdornment: props.dataAdornment && (
            <InputAdornment position="start">
              <img src={props.dataAdornment} alt="" />
            </InputAdornment>
          ),
        }}
        size="small"
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={getIn(values, props.name)}
        onChange={handleChange}
        error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
        helperText={
          <pre className="errorMsg">
            {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
          </pre>
        }
        fullWidth
      />
    </div>
  )
}

export default TextFieldComponent
