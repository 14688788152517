// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import { HeadOfSendPoint } from 'container'
import { Table } from 'component'
import { useInfiniteScroll, useSendPoint } from 'hooks'
import {
  ETranformationDataIntoTable,
  ETransformationDataToServer,
  transformationDataIntoTable,
  transformationDataToServer,
} from 'utils/tranformData'
import { getDefaultMonthYear } from 'utils/other'

const SendPointToUrbox: React.FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()

  const {
    data: resSendPointList,
    refetch,
    topUpThePoint,
  } = useSendPoint({
    month: values.filter.month,
    year: values.filter.year,
  })

  const { dataList } = useInfiniteScroll({
    result: resSendPointList,
    refetch: refetch,
    variables: {
      month: values.filter.month,
      year: values.filter.year,
    },
    filter: null,
    key: 'sendPointList',
  })

  const sendPointList = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.ListSendPoint,
  })

  const onTopUpThePoint = () => {
    const packData = transformationDataToServer({
      values: values.filter,
      type: ETransformationDataToServer.TopUpThePoint,
    })
    topUpThePoint({ variables: packData }).then((res: any) => {
      if (res.data.topUpThePoint.res_code === '99') return

      refetch()
    })
  }

  const handleExcelRequestUrBox = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `RequestToUrBox-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.requestUrBoxExcel(0, 2023, resSendPointList?.items)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={false} section={t('sendPointToUrbox.section')}>
      <section className={styles.container}>
        <HeadOfSendPoint
          status={`${resSendPointList?.urBoxStatus}`}
          topUpThePoint={onTopUpThePoint}
          handleExcelRequestUrBox={handleExcelRequestUrBox}
        />
        <Table
          colSize={{
            shopType: 'auto',
            shopName: '200px',
            phoneNumber: '180px',
            saleVolume: 'auto',
            rawPoint: 'auto',
            adjustmentPoint: 'auto',
            AccPointHTML: 'auto',
            requestTime: '180px',
            status: 'auto',
            message: 'Message',
            amount: 'auto',
            lastUpdateFormat: '150px',
          }}
          objectKey={{
            shopType: 'Customer Type',
            shopName: 'Customer Name',
            phoneNumber: 'Tel.',
            saleVolume: 'Sale Volume',
            rawPoint: 'Raw Point',
            adjustmentPoint: 'Adj. Point',
            AccPointHTML: 'Acc. Point',
            requestTime: 'Request Time',
            status: 'Status',
            message: 'Message',
            amount: 'Amount',
            lastUpdateFormat: 'Last Update',
          }}
          data={sendPointList}
        />
      </section>
    </Layout>
  )
}

const EnhancedSendPointToUrbox = withFormik({
  mapPropsToValues: () => ({
    filter: {
      year: getDefaultMonthYear().defaultYear,
      month: getDefaultMonthYear().defaultMonth,
    },
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(SendPointToUrbox)
export default EnhancedSendPointToUrbox
