import { useQuery } from '@apollo/client'
import { LIST_NOTIFICATION } from 'queries/notification.queries'
import { useHandleResponseAPI } from 'hooks'

function useNotification(variables?: any) {
  const { data, loading, error, refetch } = useQuery(LIST_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const errMsg = () => {
    if (error || data?.listNotification?.res_code === '99')
      return JSON.stringify(error || data?.listNotification?.res_desc)
    return ''
  }

  useHandleResponseAPI({
    isLoading: loading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listNotification,
    loading,
    refetch,
  }
}

export default useNotification
