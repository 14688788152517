import { createTheme } from '@mui/material/styles'
import Color from 'abstracts/export.module.scss'

export interface Props {
  name: string
  label: string
  placeholder?: string
  value: []
  option: {
    value: string
    label: string
  }[]
  disabled?: boolean
  require?: boolean
}

export const ColorThemeProvider = createTheme({
  palette: {
    // primary: {
    //   main: `${Color.primaryMain}`,
    // },
    // secondary: {
    //   main: `${Color.primarySecond}`,
    // },
  },
})

export const ColorThemeTextField = {
  '.MuiInputBase-root': {
    marginTop: '5px',
    backgroundColor: `${Color.white}`,
  },
  '& span': {
    fontSize: '12px',
    fontWeight: '700',
    fontFamily: 'FCIconicBold',
  },
  '.MuiChip-filled': {
    color: `${Color.primaryMain}`,
    backgroundColor: `${Color.primarySecond}`,
    border: `1px solid ${Color.primaryBorder}`,
  },
}
