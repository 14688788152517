// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { useModalForm, useModalRecently, useTags } from 'hooks'
import { HeadOfMasterData, ModalOfTags, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import yupTags from 'validations/yupTags.validate'
import { RootState } from 'states/store'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const TagsManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()

  const getTagsReducer = useSelector((state: RootState) => state.tags)

  const {
    data: resTrachTagsList,
    refetch: refetchTagsTrashList,
    createTags,
    updateTags,
    deleteTags,
    recoveryTags,
  } = useTags({
    pageSize: 9999,
    filter: {
      isActive: false,
    },
  })

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    tagID: '',
    tagName: '',
    typeTag: '',
    tagColor: '',
  })

  const tagsList = transformationDataIntoTable({
    dataList: getTagsReducer.tagsList,
    type: ETranformationDataIntoTable.ListTags,
    handleOpen,
  })

  const { isOpenModalRecently, handleCloseModalRecently, handleOpenModalRecently, onRecover } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoveryTags,
    refetchMasterDataTrash: refetchTagsTrashList,
    refetchMasterData: getTagsReducer.refetch,
  })

  return (
    <Layout open={false} section={t('tagsManagement.title')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('tagsManagement.recentlyDeleted')}
          buttonName={t('tagsManagement.buttonProduct')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
        />
        <Table
          objectKey={{
            tagName: 'Tags',
            typeTag: 'Type',
            actionView: 'Action',
          }}
          data={tagsList || []}
        />

        <ModalOfTags
          openModal={isModalOpen}
          closeModal={handleClose}
          createTags={createTags}
          updateTags={updateTags}
          deleteTags={deleteTags}
          refetchTagsList={getTagsReducer.refetch}
          refetchTagsTrashList={refetchTagsTrashList}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{
            tagName: '50%',
            typeTag: '50%',
          }}
          keyTable={{
            tagName: 'Tags',
            typeTag: 'Type',
          }}
          dataTable={resTrachTagsList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('tagsManagement.recentlyTitle'),
                description: t<string>('tagsManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  onRecover()
                  dispatch(setCloseDialog())
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="tagID"
        />
      </div>
    </Layout>
  )
}

const EnhancedTagsManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      tagID: '',
      tagName: '',
      typeTag: '',
      tagColor: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupTags,
  handleSubmit: () => {
    console.log('skip')
  },
})(TagsManagement)
export default EnhancedTagsManagement
