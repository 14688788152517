import { useQuery } from '@apollo/client'
import { LIST_NOTIFICATION_BY_PERMISSION } from 'queries/notification.queries'
import { useHandleResponseAPI } from 'hooks'

function useNotificationByPermission(variables?: any) {
  const { data, loading, error, refetch } = useQuery(LIST_NOTIFICATION_BY_PERMISSION, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const errMsg = () => {
    if (error || data?.listNotificationByPermission?.res_code === '99')
      return JSON.stringify(error || data?.listNotificationByPermission?.res_desc)
    return ''
  }

  useHandleResponseAPI({
    isLoading: false,
    errMsg: errMsg(),
  })

  return {
    data: data?.listNotificationByPermission,
    loading,
    refetch: refetch,
  }
}

export default useNotificationByPermission
