import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  provinceList: [],
  provinceListOption: [],
  refetch: null,
}

export const provinceSlice = createSlice({
  name: 'province',
  initialState,
  reducers: {
    setProvince: (state, action: PayloadAction<any>) => {
      state.provinceList = action.payload.items
      state.provinceListOption = action.payload.items.map((data: any) => ({
        value: data.provinceID,
        label: data.provinceName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProvince } = provinceSlice.actions

export default provinceSlice.reducer
