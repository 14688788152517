import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { District } from 'utils/generated'

interface DistrictState {
  districtList: District[]
  districtListOption: any[]
  refetch: any
}

interface SetDistrictPayload {
  items: District[]
  refetch: any
}

const initialState: DistrictState = {
  districtList: [],
  districtListOption: [],
  refetch: null,
}

export const districtSlice = createSlice({
  name: 'district',
  initialState,
  reducers: {
    setDistrict: (state, action: PayloadAction<SetDistrictPayload>) => {
      state.districtList = action.payload.items
      state.districtListOption = action.payload.items.map((data: District) => ({
        value: data.districtID,
        label: data.districtName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDistrict } = districtSlice.actions

export default districtSlice.reducer
