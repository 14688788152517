// Lib
import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Include in project
import styles from './index.module.scss'
import { AutoComplete, SelectSingle, DatePickers, Switch } from 'component'
import type { RootState } from 'states/store'

const CampaignRestrictionsSpecialModeEdit: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  const getZoneListReducer = useSelector((state: RootState) => state.zone)
  const getProvinceListReducer = useSelector((state: RootState) => state.province)
  const getDistrictListReducer = useSelector((state: RootState) => state.district)
  const getSegmentListReducer = useSelector((state: RootState) => state.segment)
  const getShopListReducer = useSelector((state: RootState) => state.shop)
  const getShopBigListReducer = useSelector((state: RootState) => state.shopBigRetailer)
  const getTagsListReducer = useSelector((state: RootState) => state.tags)

  const optionOfTargetTypeValue = useMemo(() => {
    switch (values.campaign.targetType) {
      case 'ZONE':
        return getZoneListReducer.zoneListOption

      case 'PROVINCE':
        return getProvinceListReducer.provinceListOption

      case 'DISTRICT':
        return getDistrictListReducer.districtListOption

      case 'SEGMENT':
        return getSegmentListReducer.segmentListOption

      case 'RETAILER':
        return getShopListReducer.retialerListOption

      case 'BIG_RETAILER':
        return getShopBigListReducer.bigRetialerListOption

      default:
        return []
    }
  }, [values.campaign.targetType])

  const disabledTagRetainerAndShopType = useMemo(() => {
    switch (values.campaign.targetType) {
      case 'ZONE':
      case 'PROVINCE':
      case 'DISTRICT':
      case 'SEGMENT':
        return false

      case 'RETAILER':
      case 'BIG_RETAILER':
        return true

      default:
        return true
    }
  }, [values.campaign.targetType])

  const disabledIsSubRTLCal = useMemo(() => {
    if (values.campaign.targetType === 'BIG_RETAILER' || values.campaign.shopType === 'BIG_RETAILER') return false
    else return true
  }, [values.campaign.targetType, values.campaign.shopType])

  return (
    <section className={styles.container}>
      <h5>{t<string>('createNormalCampaign.campaignRestric')}</h5>
      <div className={styles.wrapperInput}>
        <SelectSingle
          label={t<string>('common.targetType')}
          name="campaign.targetType"
          option={[
            {
              label: 'Zone',
              value: 'ZONE',
            },
            {
              label: 'Province',
              value: 'PROVINCE',
            },
            {
              label: 'District',
              value: 'DISTRICT',
            },
            {
              label: 'Segment',
              value: 'SEGMENT',
            },
            {
              label: 'Retailer',
              value: 'RETAILER',
            },
            {
              label: 'Big Retailer',
              value: 'BIG_RETAILER',
            },
          ]}
          disabled={false}
          require
        />

        <SelectSingle
          label={`Shop Type`}
          name="campaign.shopType"
          option={[
            {
              label: 'All',
              value: 'ALL',
            },
            {
              label: 'Retailer',
              value: 'RETAILER',
            },
            {
              label: 'Big Retailer',
              value: 'BIG_RETAILER',
            },
          ]}
          disabled={disabledTagRetainerAndShopType}
        />

        <AutoComplete
          name={'campaign.tagRetainer'}
          label={'Tag (Retailer)'}
          value={values.campaign.tagRetainer}
          option={getTagsListReducer.tagsRetialerListOption}
          disabled={disabledTagRetainerAndShopType}
        />

        <AutoComplete
          name={'campaign.targetProductDummy'}
          label={'Tag (Product)'}
          value={values.campaign.targetProductDummy}
          option={getTagsListReducer.tagsProductListOption}
          require
        />

        <AutoComplete
          name={'campaign.targetTypeValueDummy'}
          label={t<string>('common.targetTypeValue')}
          value={values.campaign.targetTypeValueDummy}
          option={optionOfTargetTypeValue}
          require
        />

        <DatePickers name="campaign.timeDuration" label={t<string>('common.timeDuration')} require />

        <div style={{ paddingLeft: '20px' }}>
          <Switch
            label="Cal with SubRTL"
            name={'campaign.isSubRTLCal'}
            checked={values.campaign.isSubRTLCal}
            disabled={disabledIsSubRTLCal}
          />
        </div>
      </div>
    </section>
  )
}
export default CampaignRestrictionsSpecialModeEdit
