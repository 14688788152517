import { gql } from '@apollo/client'

export const GET_LIST_PRODUCT = gql`
  query ListProduct($pageSize: Int!, $nextToken: String, $filter: FilterListProductInput) {
    listProduct(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      items {
        productID
        productName
        productBrandID
        countryID
        isActive
        productUnit_id
        brandName
        unitName
        tagList {
          tagID
          tagName
          typeTag
          tagColor
          isActive
        }
      }
      nextToken
    }
  }
`

export const CREATE_PRODUCT = gql`
  mutation Mutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      res_code
      res_desc
      productID
      productName
      productBrandID
      countryID
      isActive
      productUnit_id
      brandName
      unitName
      tagList {
        tagID
        tagName
        typeTag
        tagColor
        isActive
      }
    }
  }
`
export const UPDATE_PRODUCT = gql`
  mutation Mutation($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      res_code
      res_desc
      productID
      productName
      productBrandID
      countryID
      isActive
      productUnit_id
      brandName
      unitName
      tagList {
        tagID
        tagName
        typeTag
        tagColor
        isActive
      }
    }
  }
`
export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_PRODUCT = gql`
  mutation Mutation($input: RecoveryProductInput!) {
    recoveryProduct(input: $input) {
      res_code
      res_desc
    }
  }
`
