// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Images
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Props } from './config'
import { Button, SelectSingle, TextField, TextFieldPassword, Switch } from 'component'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { ETransformationDataToServer } from 'utils/tranformData'
import type { RootState } from 'states/store'
import { useHandleActionMasterData } from 'hooks'

const ModalOfUser: React.FC<Props> = (props: Props) => {
  const { values, errors, setFieldValue } = useFormikContext<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const getPermissionListReducer = useSelector((state: RootState) => state.permission)

  const { handleUpdate } = useHandleActionMasterData({
    errors,
    values,
    createMasterData: props.createUserLits,
    updateMasterData: props.updateUserList,
    typeUpdateMasterData: ETransformationDataToServer.UpdateUser,
    deleteMasterData: props.deleteUserList,
    refetchMasterData: props.refetchUserList,
    closeModal: props.onClose,
    key: 'userID',
  })

  const handleSwitchStatus = () => {
    if (values.formInput.isActive) {
      dispatch(
        setOpenDialog({
          title: t<string>('userListManagement.changStatus'),
          description: t<string>('userListManagement.statusDescription'),
          status: 'danger',
          functionOnSubmit: () => {
            setFieldValue('formInput.isActive', !values.formInput.isActive)
            dispatch(setCloseDialog())
          },
        }),
      )
    } else {
      setFieldValue('formInput.isActive', !values.formInput.isActive)
    }
  }

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <section className={styles.containerModal}>
        <div className={styles.wrapperCard}>
          <div className={styles.head}>
            {!values.formInput.userID ? (
              <h3>{t<string>('userListManagement.newUser')}</h3>
            ) : (
              <h3>{t<string>('userListManagement.deatailsUser')}</h3>
            )}
            <img src={closeSVG} alt="closeSVG" onClick={props.onClose} />
          </div>

          <div className={styles.wrapperInput}>
            <TextField name={`formInput.name`} type="text" label="Name" />
            <TextField name={'formInput.email'} type="text" label="Email" />
            {!values.formInput.userID && <TextFieldPassword name={'formInput.password'} label="Password" />}

            <SelectSingle
              name={'formInput.userRoleID'}
              label="User role"
              option={getPermissionListReducer.permissionListOption}
              disabled={false}
            />

            <div className={styles.status}>
              <h6>{t<string>('common.status')}</h6>
              <Switch
                checked={values.formInput.isActive}
                disabled={false}
                name="formInput.isActive"
                label="Active"
                onChange={handleSwitchStatus}
              />
            </div>
          </div>
        </div>

        <div className={styles.bottomWrapper}>
          <div className={styles.button}>
            <Button
              typeButton="button"
              type={'primary'}
              disabled={false}
              name={t<string>('common.save')}
              fullWidth
              functionOnClick={() => handleUpdate()}
            />
          </div>
        </div>
      </section>
    </Modal>
  )
}
export default ModalOfUser
