import * as Yup from 'yup'

const yupProvince = Yup.object().shape({
  formInput: Yup.object().shape({
    provinceCode: Yup.string().required('Please Enter Province Code'),
    provinceName: Yup.string().required('Please Enter Province Name'),
    zoneID: Yup.string().required('Please Select Zone'),
  }),
})

export default yupProvince
