import { useMutation, useQuery } from '@apollo/client'
import { useHandleResponseAPI } from 'hooks'
import { LIST_SEND_POINT, TOP_UP_POINT } from 'queries/sendPointURBox.queries'

function useSendPoint(variables?: any) {
  const { data, loading, error, refetch } = useQuery(LIST_SEND_POINT, { fetchPolicy: 'no-cache', variables: variables })

  const [topUpThePoint, optionTopUpThePoint] = useMutation(TOP_UP_POINT)

  const isLoading = loading || optionTopUpThePoint.loading

  const errMsg = () => {
    //  Query
    if (error || data?.sendPointList?.res_code === '99') return JSON.stringify(error || data?.sendPointList?.res_desc)

    // optionTopUpThePoint
    if (optionTopUpThePoint.error || optionTopUpThePoint.data?.topUpThePoint?.res_code === '99')
      return JSON.stringify(optionTopUpThePoint.error || optionTopUpThePoint.data?.topUpThePoint?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return { data: data?.sendPointList, refetch, topUpThePoint }
}

export default useSendPoint
