// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { useBrand, useModalForm, useModalRecently } from 'hooks'
import { HeadOfMasterData, ModalOfBrand, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { RootState } from 'states/store'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const BrandManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()

  const getBrandReducer = useSelector((state: RootState) => state.brand)

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    productBrandID: '',
    brandName: '',
  })

  const brandList = transformationDataIntoTable({
    dataList: getBrandReducer.brandList,
    type: ETranformationDataIntoTable.ListBrand,
    handleOpen,
  })

  const {
    data: resBrandTrachList,
    refetch: refetchBrandTrash,
    createBrand,
    updateBrand,
    deleteBrand,
    recoveryBrand,
  } = useBrand({
    pageSize: 9999,
    filter: {
      isActive: false,
    },
  })
  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoveryBrand,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoveryBrand,
    refetchMasterDataTrash: refetchBrandTrash,
    refetchMasterData: getBrandReducer.refetch,
  })

  return (
    <Layout open={false} section={t('brandManagement.title')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('brandManagement.recentlyDeleted')}
          buttonName="New Brand"
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
        />
        <Table
          objectKey={{
            brandName: 'Brand',
            actionView: 'Action',
          }}
          data={brandList || []}
        />

        <ModalOfBrand
          openModal={isModalOpen}
          closeModal={handleClose}
          createBrand={createBrand}
          updateBrand={updateBrand}
          deleteBrand={deleteBrand}
          refetchBrandList={getBrandReducer?.refetch}
          refetchBrandTrashList={refetchBrandTrash}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{
            brand: '100%',
          }}
          keyTable={{
            brandName: 'Brand',
          }}
          dataTable={resBrandTrachList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('brandManagement.recentlyTitle'),
                description: t<string>('brandManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  onRecoveryBrand()
                  dispatch(setCloseDialog())
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="productBrandID"
        />
      </div>
    </Layout>
  )
}

const EnhancedBrandManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      productBrandID: '',
      brandName: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  handleSubmit: () => {
    console.log('SKIP ')
  },
})(BrandManagement)
export default EnhancedBrandManagement
