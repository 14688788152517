import { gql } from '@apollo/client'

export const GET_LIST_CAMPAIGN = gql`
  query CampaignsList($status: ECampaignListStatus!, $type: ECampaignListType!, $pageSize: Int!, $nextToken: String) {
    campaignsList(status: $status, type: $type, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        campaignID
        campaignType
        campaignName
        promotion
        restrictionStartDate
        restrictionEndDate
        campaignStatus
        countryName
      }
    }
  }
`

export const GET_CAMPAIGN_DETAIL = gql`
  query CampaignDetail($campaignId: ID!, $type: ECampaignDetailType!) {
    campaignDetail(campaignID: $campaignId, type: $type) {
      res_code
      res_desc
      campaignID
      campaignType
      campaignName
      campaignDescription
      campaignGoal
      productList {
        productID
        productName
        unitName
        levelList {
          level
          point
        }
      }
      restrictionStartDate
      restrictionEndDate
      campaignStatus
      pointStatus
      campaignTargetType
      campaignTargetTypeValue {
        targetTypeID
        targetTypeName
      }
      updatedAt
      documents
      remark
      remarkPoint
    }
  }
`

export const CREATE_NORMAL_CAMPAIGN = gql`
  mutation CreateNormalCampaign($input: CreateNormalCampaignInput!) {
    createNormalCampaign(input: $input) {
      res_code
      res_desc
      campaignID
    }
  }
`

export const UPDATE_NORMAL_CAMPAIGN = gql`
  mutation UpdateNormalCampaign($input: UpdateNormalCampaignInput!) {
    updateNormalCampaign(input: $input) {
      res_code
      res_desc
      campaignID
    }
  }
`

export const REQUEST_APPROVE_CAMPAIGN = gql`
  mutation RequestApproveCampaign($input: RequestApproveCampaignInput!) {
    requestApproveCampaign(input: $input) {
      res_code
      res_desc
      campaignID
    }
  }
`
