import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IDecodeToken {
  countryID: number
  email: string
  exp: number
  iat: number
  isActive: boolean
  name: string
  typeTokenAuth: string
  userID: number
  userRoleID: number
}

const initialState: IDecodeToken & { permission: any } = {
  countryID: 0,
  email: '',
  exp: 0,
  iat: 0,
  isActive: true,
  name: '',
  typeTokenAuth: '',
  userID: 0,
  userRoleID: 0,
  permission: null,
}

export const decodeTokenSlice = createSlice({
  name: 'decodeToken',
  initialState,
  reducers: {
    setDecodeToken: (state, action: PayloadAction<IDecodeToken & { permission: any }>) => {
      state.countryID = action.payload.countryID
      state.email = action.payload.email
      state.exp = action.payload.exp
      state.iat = action.payload.iat
      state.isActive = action.payload.isActive
      state.name = action.payload.name
      state.typeTokenAuth = action.payload.typeTokenAuth
      state.userID = action.payload.userID
      state.userRoleID = action.payload.userRoleID
      state.permission = action.payload.permission
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDecodeToken } = decodeTokenSlice.actions

export default decodeTokenSlice.reducer
