// Lib
import React from 'react'

// Images

// Include in project
import { SelectSingle, Button } from 'component'
import styles from './index.module.scss'
import { useFilterYearMonth } from 'utils/other'

interface Props {
  isShowButton?: boolean
  titleButton?: string
  onClickButton?: () => void
}

const SearchYearAndMonth: React.FC<Props> = ({ titleButton, onClickButton, isShowButton }) => {
  const { monthListOption, yearsListOption } = useFilterYearMonth()
  return (
    <div className={styles.wrapperInput}>
      <div className={styles.groupInput}>
        <SelectSingle name="filter.year" option={yearsListOption} disabled={false} />
      </div>
      <div className={styles.groupInput}>
        <SelectSingle name="filter.month" option={monthListOption} disabled={false} />
      </div>
      {isShowButton && (
        <Button
          typeButton="button"
          type="primary"
          disabled={false}
          name={titleButton}
          functionOnClick={() => onClickButton?.()}
        />
      )}
    </div>
  )
}

export default SearchYearAndMonth
