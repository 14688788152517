import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_LIST_PRODUCT,
  RECOVERY_PRODUCT,
  UPDATE_PRODUCT,
} from 'queries/product.queries'
import { useHandleResponseAPI } from 'hooks'

function useProduct(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_PRODUCT, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createProduct, optionCreate] = useMutation(CREATE_PRODUCT)
  const [updateProduct, optionUpdate] = useMutation(UPDATE_PRODUCT)
  const [deleteProduct, optionDelete] = useMutation(DELETE_PRODUCT)
  const [recoveryProduct, optionRecovery] = useMutation(RECOVERY_PRODUCT)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listProduct?.res_code === '99')
      return JSON.stringify(errorQuery || data?.listProduct?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createProduct?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createProduct?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateProduct?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateProduct?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteProduct?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteProduct?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryProduct?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryProduct?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listProduct,
    productLoading: loading,
    refetch,
    createProduct,
    updateProduct,
    deleteProduct,
    recoveryProduct,
  }
}

export default useProduct
