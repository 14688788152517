// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { saveAs } from 'file-saver'

// Include in project
import Layout from 'layouts'
import { useModalForm, useModalRecently, useShop } from 'hooks'
import { HeadOfMasterData, ModalOfShop, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import yupShop from 'validations/yupShop.validate'
import { RootState } from 'states/store'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/tranformData'

const ShopManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    retailerID: '',
    retailerCode: '',
    retailerName: '',
    provinceID: '',
    districtID: '',
    segmentID: '',
    phoneNumber: '',
    zoneID: '',
    tagList: [],
    zaloID: '',
    email: '',
    lineUserID: '',
    isLineVerify: false,
  })

  const getShopReducer = useSelector((state: RootState) => state.shop)

  const shopList = transformationDataIntoTable({
    dataList: getShopReducer.shopList,
    type: ETranformationDataIntoTable.ListShopRetailer,
    handleOpen,
  })

  const {
    data: resTrashShop,
    refetch: refetchTrashShop,
    createShop,
    updateShop,
    deleteShop,
    recoveryShop,
  } = useShop({
    pageSize: 9999,
    filter: {
      isActive: false,
    },
  })

  const shopRetailerTrashList = transformationDataIntoTable({
    dataList: resTrashShop?.items,
    type: ETranformationDataIntoTable.ListShopBigRetailerTrash,
    handleOpen,
  })

  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoverShop,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoveryShop,
    refetchMasterDataTrash: refetchTrashShop,
    refetchMasterData: getShopReducer.refetch,
  })

  const keyOfTable = {
    retailerCode: 'DMS Code',
    retailerName: 'Name',
    zoneName: 'Zone',
    provinceName: 'Province',
    districtName: 'District',
    segmentName: 'Segment',
    phoneNumber: 'Phone Number',
    tagListHTML: 'Tags',
    zaloID: 'Zalo ID',
    email: 'Email',
    lineUserID: 'LineUserID',
    isLineVerify: 'LineVerify',
    action: 'Action',
  }

  const handleExcelRetailer = async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = `Retailer-` + new Date().toISOString()

    const buffer = await import('utils/other').then((generate) => {
      return generate.customerExcel(getShopReducer.shopList)
    })

    const excelToSave = new Blob([buffer], { type: fileType })

    saveAs(excelToSave, fileName + fileExtension)
  }

  return (
    <Layout open={false} section={t('shopManagement.title')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('shopManagement.recentlyDeleted')}
          buttonName={t('shopManagement.button')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
          isShowButtonExport
          titleButtonExport="Export File"
          onClickButtonExport={handleExcelRetailer}
        />

        <Table objectKey={keyOfTable} data={shopList || []} />

        <ModalOfShop
          openModal={isModalOpen}
          closeModal={handleClose}
          createShop={createShop}
          updateShop={updateShop}
          deleteShop={deleteShop}
          refetch={getShopReducer.refetch}
          refetchTrashList={refetchTrashShop}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          keyTable={_.omit(keyOfTable, 'action')}
          dataTable={shopRetailerTrashList || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('shopManagement.recentlyTitle'),
                description: t<string>('shopManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  onRecoverShop()
                  dispatch(setCloseDialog())
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="retailerID"
        />
      </div>
    </Layout>
  )
}

const EnhancedShopManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      retailerCode: '',
      retailerName: '',
      retailerID: '',
      zoneID: '',
      provinceID: '',
      districtID: '',
      segmentID: '',
      phoneNumber: '',
      tagList: [],
      zaloID: '',
      email: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupShop,
  handleSubmit: () => {
    console.log('SKIP')
  },
})(ShopManagement)
export default EnhancedShopManagement
