import { gql } from '@apollo/client'

export const GET_LIST_CAMPAIGN = gql`
  query CampaignsList(
    $nextToken: String
    $pageSize: Int!
    $status: ECampaignListStatus!
    $type: ECampaignListType!
    $pointStatus: EPointStatus
    $year: Int
    $month: Int
  ) {
    campaignsList(
      nextToken: $nextToken
      pageSize: $pageSize
      status: $status
      pointStatus: $pointStatus
      type: $type
      year: $year
      month: $month
    ) {
      res_code
      res_desc
      nextToken
      items {
        campaignID
        campaignType
        campaignName
        campaignShopType
        campaignTargetType
        campaignTargetTypeValue {
          targetTypeID
          targetTypeName
        }
        promotion
        restrictionStartDate
        restrictionEndDate
        campaignStatus
        countryName
        updatedAt
        pointStatus
        shopTagsList {
          tagID
          tagName
        }
      }
    }
  }
`
export const GET_CAMPAIGN_DETAIL = gql`
  query CampaignDetail($campaignId: ID!, $type: ECampaignDetailType!) {
    campaignDetail(campaignID: $campaignId, type: $type) {
      res_code
      res_desc
      campaignID
      campaignType
      campaignName
      campaignDescription
      campaignGoal
      campaignTargetType
      campaignTargetTypeValue {
        targetTypeID
        targetTypeName
      }
      targetProductList {
        tagID
        tagName
        levelList {
          level
          point
        }
      }
      shopTagsList {
        tagID
        tagName
        # tagColor
      }
      rewardPointList {
        rewardPointID
        targetTypeID
        targetTypeName
        pointReward
      }
      campaignShopType
      restrictionStartDate
      restrictionEndDate
      isSubRTLCal
      campaignStatus
    }
  }
`

export const APPROVE_CAMPAIGN = gql`
  mutation ApproveCampaign($input: ApproveCampaignInput!) {
    approveCampaign(input: $input) {
      res_code
      res_desc
      campaignID
      campaignStatus
    }
  }
`
