import * as Yup from 'yup'

const yupTags = Yup.object().shape({
  formInput: Yup.object().shape({
    tagName: Yup.string().required('Plese Enter Tags Name'),
    typeTag: Yup.string().required('Plese Select Tags Type'),
  }),
})

export default yupTags
