import { useMutation, useQuery } from '@apollo/client'
import { CREATE_BRAND, DELETE_BRAND, GET_LIST_BRAND, RECOVERY_BRAND, UPDATE_BRAND } from 'queries/brand.queries'
import { useHandleResponseAPI } from 'hooks'

function useBrand(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_BRAND, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createBrand, optionCreate] = useMutation(CREATE_BRAND)
  const [updateBrand, optionUpdate] = useMutation(UPDATE_BRAND)
  const [deleteBrand, optionDelete] = useMutation(DELETE_BRAND)
  const [recoveryBrand, optionRecovery] = useMutation(RECOVERY_BRAND)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listBrand?.res_code === '99') return JSON.stringify(errorQuery || data?.listBrand?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate?.data?.createBrand?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate?.data?.createBrand?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate?.data?.updateBrand?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate?.data?.updateBrand?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete?.data?.deleteBrand?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete?.data?.deleteBrand?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery?.data?.recoveryBrand?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery?.data?.recoveryBrand?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listBrand,
    brandLoading: loading,
    refetch,
    createBrand,
    updateBrand,
    deleteBrand,
    recoveryBrand,
  }
}

export default useBrand
