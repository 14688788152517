import { useMutation, useLazyQuery } from '@apollo/client'
import {
  CREATE_SPECIAL_CAMPAIGN,
  GET_CAMPAIGN_DETAIL,
  UPDATE_SPECIAL_CAMPAIGN,
  REQUEST_APPROVE_CAMPAIGN,
} from 'queries/campaignSpecial.queries'
import { useHandleResponseAPI } from 'hooks'

function useCampaignNormal() {
  const [getCampaign, { data, loading, error }] = useLazyQuery(GET_CAMPAIGN_DETAIL, {
    fetchPolicy: 'no-cache',
  })

  const [createSpecialCampaign, optionCreate] = useMutation(CREATE_SPECIAL_CAMPAIGN)
  const [updateSpecialCampaign, optionUpdate] = useMutation(UPDATE_SPECIAL_CAMPAIGN)
  const [requestApproveCampaign, optionRequestApprove] = useMutation(REQUEST_APPROVE_CAMPAIGN)

  const isLoading = loading || optionCreate.loading || optionUpdate.loading || optionRequestApprove.loading

  const errMsg = () => {
    // Query
    if (error || data?.campaignDetail?.res_code === '99') return JSON.stringify(error || data?.campaignDetail?.res_desc)

    // optionUpdate
    if (optionUpdate.error || optionUpdate.data?.updateSpecialCampaign?.res_code === '99')
      return JSON.stringify(optionCreate.error || optionUpdate.data?.updateSpecialCampaign?.res_desc)

    // optionCreate
    if (optionCreate.error || optionCreate.data?.createSpecialCampaign?.res_code === '99')
      return JSON.stringify(optionCreate.error || optionCreate.data?.createSpecialCampaign?.res_desc)

    // optionRequestApprove
    if (optionRequestApprove.error || optionRequestApprove.data?.requestApproveCampaign?.res_code === '99')
      return JSON.stringify(optionRequestApprove.error || optionRequestApprove.data?.requestApproveCampaign?.res_desc)
    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    getCampaign,
    createSpecialCampaign,
    updateSpecialCampaign,
    requestApproveCampaign,
  }
}

export default useCampaignNormal
