// Lib
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { withFormik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import moment from 'moment'

// Images

// Include in project
import { DatePickers, SelectSingle, Notification } from 'component'
import Color from 'abstracts/export.module.scss'
import styles from './index.module.scss'
import { useDashboard } from 'hooks'
import type { RootState } from 'states/store'
import { ByZoneCostPerTon } from 'utils/generated'

const BarGraphCostPerTon: React.FC = () => {
  const { values } = useFormikContext<any>()

  const getZoneListReducer = useSelector((state: RootState) => state.zone)
  const { getByZoneCostPerTon, dataByZoneCostPerTon, errMsg } = useDashboard()
  useEffect(() => {
    if (!values.timeDuration[0]) return
    if (!values.timeDuration[1]) return

    getByZoneCostPerTon({
      variables: {
        startDate: values.timeDuration[0].toISOString(),
        endDate: values.timeDuration[1].toISOString(),
        zoneID: values.zoneID,
      },
    })
  }, [values.zoneID, values.timeDuration, getZoneListReducer.zoneListOption])

  const optionsBarTotalStar: any = {
    plugins: {
      title: {
        display: true,
        text: 'Cost Per Ton',
      },
      datalabels: {
        display: true,
        color: 'black',
        anchor: 'end',
        offset: -20,
        align: 'start',
      },
      legend: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: 'VND/Ton',
        },
      },
    },
  }

  const labels = dataByZoneCostPerTon?.items?.map((data: ByZoneCostPerTon) => data.provinceName)

  const dataBarTotalStar = {
    labels,
    datasets: [
      {
        label: 'Campaign star',
        data: dataByZoneCostPerTon?.items?.map((data: ByZoneCostPerTon) => data.costPerTon),
        backgroundColor: `${Color.grey200}`,
      },
    ],
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  useEffect(() => {
    if (errMsg) return setIsOpen(true)
  }, [errMsg])
  return (
    <>
      <div className={styles.filter}>
        <SelectSingle
          name="zoneID"
          label={'Zone'}
          option={getZoneListReducer.zoneListOption}
          disabled={false}
          require
        />
        <DatePickers name="timeDuration" label={'Time Duration'} require />
      </div>
      <Bar options={optionsBarTotalStar} data={dataBarTotalStar} />
      <Notification
        title="Error From Server"
        description={errMsg}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        type="danger"
      />
    </>
  )
}

const EnhancedBarGraphCostPerTon = withFormik({
  mapPropsToValues: () => ({
    zoneID: 1,
    timeDuration: [moment().subtract(1, 'month').toDate(), moment().toDate()],
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(BarGraphCostPerTon)

export default EnhancedBarGraphCostPerTon
