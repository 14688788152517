// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import yupProvince from 'validations/yupProvince.validate'

// Include in project
import Layout from 'layouts'
import { useModalForm, useModalRecently, useProvince } from 'hooks'
import { HeadOfMasterData, ModalOfProvince, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const ProvinceManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()
  const getProvinceListReducer = useSelector((state: RootState) => state.province)

  // ProvinceList Hook and HandleFunction
  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    provinceID: '',
    provinceCode: '',
    provinceName: '',
    zoneID: '',
  })
  const provinceList = transformationDataIntoTable({
    dataList: getProvinceListReducer.provinceList,
    type: ETranformationDataIntoTable.ListProvince,
    handleOpen,
  })

  // ProvinceTrashList Hook and HandleFunction
  const {
    data: resProvinceTrashList,
    refetch: refetchProvinceTrashList,
    recoveryProvince,
    createProvince,
    updateProvince,
    deleteProvince,
  } = useProvince({
    pageSize: 9999,
    nextToken: '',
    filter: {
      isActive: false,
    },
  })
  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoverProvince,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoveryProvince,
    refetchMasterDataTrash: refetchProvinceTrashList,
    refetchMasterData: getProvinceListReducer.refetch,
  })

  return (
    <Layout open={false} section={t('provinceManagement.title')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('provinceManagement.recentlyDeleted')}
          buttonName={t('provinceManagement.buttonProvince')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
        />
        <Table
          objectKey={{
            provinceCode: 'Province Code',
            provinceName: 'Province Name',
            zoneID: 'Zone',
            actionView: 'Action',
          }}
          data={provinceList || []}
        />

        <ModalOfProvince
          openModal={isModalOpen}
          closeModal={handleClose}
          refetchProvinceList={getProvinceListReducer.refetch}
          refetchProvinceTrashList={refetchProvinceTrashList}
          createProvince={createProvince}
          updateProvince={updateProvince}
          deleteProvince={deleteProvince}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{
            provinceCode: '25%',
            provinceName: '25%',
            zone: '15%',
          }}
          keyTable={{
            provinceCode: 'Province Code',
            provinceName: 'Province Name',
            zoneID: 'Zone',
          }}
          dataTable={resProvinceTrashList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('provinceManagement.recentlyTitle'),
                description: t<string>('provinceManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  dispatch(setCloseDialog())
                  onRecoverProvince()
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="provinceID"
        />
      </div>
    </Layout>
  )
}

const EnhancedProvinceManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      provinceID: '',
      provinceCode: '',
      provinceName: '',
      zoneID: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupProvince,
  handleSubmit: () => {
    console.log('Skip Handle Sumit of formik')
  },
})(ProvinceManagement)

export default EnhancedProvinceManagement
