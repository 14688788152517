// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { useFormikContext } from 'formik'
import { ChipInput } from 'component'
import { convertRangeDate } from 'utils/convertDate'

const CampaignRestrictionsModeView: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <h5>{t<string>('createNormalCampaign.campaignRestric')}</h5>

      <div className={styles.wrapperInput}>
        <h6 className={styles.textTitle}>{t<string>('createNormalCampaign.promotion')}:</h6>
        <div className={styles.wrapperTags}>
          {values.campaign.productList &&
            values.campaign.productList.map((item: any, index: number) => (
              <ChipInput key={index} label={item.label} color="primary" />
            ))}
        </div>

        <h6 className={styles.textTitle}>{t<string>('common.timeDuration')}:</h6>
        <p>{convertRangeDate(values.campaign.timeDuration)}</p>
      </div>
    </section>
  )
}
export default CampaignRestrictionsModeView
