import { useMutation, useQuery } from '@apollo/client'

import { useHandleResponseAPI } from 'hooks'
import {
  CREATE_SHOP_BIG_RETAILER,
  DELETE_SHOP_BIG_RETAILER,
  GET_LIST_SHOP_BIG_RETAILER,
  RECOVERY_SHOP_BIG_RETAILER,
  UPDATE_SHOP_BIG_RETAILER,
} from 'queries/shopBigRetailer.queries'

function useShopBigRetailer(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_SHOP_BIG_RETAILER, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createShopBigRetailer, optionCreate] = useMutation(CREATE_SHOP_BIG_RETAILER)
  const [updateShopBigRetailer, optionUpdate] = useMutation(UPDATE_SHOP_BIG_RETAILER)
  const [deleteShopBigRetailer, optionDelete] = useMutation(DELETE_SHOP_BIG_RETAILER)
  const [recoveryShopBigRetailer, optionRecovery] = useMutation(RECOVERY_SHOP_BIG_RETAILER)

  const isLoading = loading || optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listShopBigRetailer?.res_code === '99')
      return JSON.stringify(errorQuery || data?.listShopBigRetailer?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createShopBigRetailer?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createShopBigRetailer?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateShopBigRetailer?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateShopBigRetailer?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteShopBigRetailer?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteShopBigRetailer?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryShopBigRetailer?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryShopBigRetailer?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listShopBigRetailer,
    shopBigLoading: loading,
    refetch,
    createShopBigRetailer,
    updateShopBigRetailer,
    deleteShopBigRetailer,
    recoveryShopBigRetailer,
  }
}

export default useShopBigRetailer
