import { gql } from '@apollo/client'

export const LIST_NOTIFICATION = gql`
  query ListNotification($pageSize: Int!, $nextToken: String) {
    listNotification(pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        notificationID
        permission
        notificationDescription
        senderUserID
        senderUserName
        receiverUserID
        isRead
        updatedAt
        createdAt
      }
    }
  }
`

export const LIST_NOTIFICATION_BY_PERMISSION = gql`
  query ListNotificationByPermission {
    listNotificationByPermission {
      res_code
      res_desc
      permissionList
    }
  }
`

export const MUTATION_READ_NOTI = gql`
  mutation ReadNotification($input: ReadNotificationInput) {
    readNotification(input: $input) {
      res_code
      res_desc
    }
  }
`
