// Lib
import React from 'react'

// Images

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'

interface Props {
  deletedArchive: string
  buttonName: string
  functionOpenRecently: () => void
  functionOpenModal: () => void

  isShowButtonExport?: boolean
  titleButtonExport?: string
  onClickButtonExport?: () => void
}

const HeadOfMasterData: React.FC<any> = (props: Props): JSX.Element => {
  return (
    <div className={styles.headMasterData}>
      <q className={styles.redText} onClick={props.functionOpenRecently}>
        {props.deletedArchive}
      </q>

      <div className={styles.row}>
        {props.isShowButtonExport && (
          <div>
            <Button
              typeButton="button"
              type="primary"
              disabled={false}
              name={props.titleButtonExport}
              functionOnClick={() => props.onClickButtonExport?.()}
            />
          </div>
        )}
        <div>
          <Button
            typeButton="button"
            type="primary"
            disabled={false}
            name={props.buttonName}
            fullWidth
            functionOnClick={() => props.functionOpenModal()}
          />
        </div>
      </div>
    </div>
  )
}

export default HeadOfMasterData
