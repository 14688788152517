// Lib
import React from 'react'
import { withFormik, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Images

// Include in project
import styles from './index.module.scss'
import { Calendar } from 'component'
import { HeadOfCampaignCalendar } from 'container'
import Layout from 'layouts'
import type { RootState } from 'states/store'
import { Campaign, ECampaignTargetType, ECampaignListType, CampaignTargetTypeValueGet } from 'utils/generated'
import { useCampaignSpecialList } from 'hooks'

const CampaignCalendar: React.FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const getZoneListReducer = useSelector((state: RootState) => state.zone.zoneListOption)
  const getProvinceListReducer = useSelector((state: RootState) => state.province.provinceListOption)
  const getDistrictListReducer = useSelector((state: RootState) => state.district.districtListOption)
  const getShopListReducer = useSelector((state: RootState) => state.shop.retialerListOption)
  const getShopBigRetailerListReducer = useSelector((state: RootState) => state.shopBigRetailer.bigRetialerListOption)

  // Find Option follow drop dawn select targetType
  const option = () => {
    if (values.targetType === ECampaignTargetType.Zone) return getZoneListReducer
    else if (values.targetType === ECampaignTargetType.Province) return getProvinceListReducer
    else if (values.targetType === ECampaignTargetType.District) return getDistrictListReducer
    else if (values.targetType === ECampaignTargetType.Retailer) return getShopListReducer
    else return getShopBigRetailerListReducer // values.targetType === ECampaignTargetType.BigRetailer
  }

  const { data: resCampaignsList } = useCampaignSpecialList({
    pageSize: 9999,
    status: values.status,
    type: ECampaignListType.Special,
    year: values.year,
    targetType: values.targetType,
    nextToken: null,
  })

  const preProcessCampaignList = () => {
    if (!resCampaignsList) return []

    const newArray: any[] = []
    resCampaignsList.items.map((data: Campaign) => {
      ;(data.campaignTargetTypeValue as CampaignTargetTypeValueGet[]).map((targetTypeValue) => {
        const packData = {
          ...data,
          targetTypeID: targetTypeValue?.targetTypeID,
        }

        newArray.push(packData)
      })
    })

    return newArray
  }

  // Calendar of the form targetTypeID --> 2D array of campaign concurrent
  const campaignCalendar = getCampaignCalendar(preProcessCampaignList(), option())

  return (
    <Layout open={false} section={t('campaignCalendar.section')}>
      <div className={styles.main}>
        <HeadOfCampaignCalendar />
        <Calendar optionList={option()} campaignCalendar={campaignCalendar} />
      </div>
    </Layout>
  )
}

const EnhancedCampaignCalendar = withFormik({
  mapPropsToValues: () => ({
    status: 'ALL',
    year: new Date().getFullYear(),
    targetType: ECampaignTargetType.Province,
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(CampaignCalendar)
export default EnhancedCampaignCalendar

interface CampaignWithTargetTypeID extends Campaign {
  targetTypeID: number
}
export interface CampaignWithMonthSpan extends CampaignWithTargetTypeID {
  startMonth: number
  endMonth: number
  monthSpan: number
  startDay: number
  endDay: number
}

/** Fill campaign with Month Metadata
 * @param {CampaignWithTargetTypeID[]} campaignList Raw campaign with targetTypeID
 * @return {CampaignWithMonthSpan[]} Campaign List with month metadata
 */
function fillCampaignMonthMeta(
  campaignList: CampaignWithTargetTypeID[],
  year = new Date().getFullYear(),
): CampaignWithMonthSpan[] {
  return campaignList.map((campaign) => {
    // startMonth should be capped at the beginning of the year
    const startDate = new Date(
      Math.max(new Date(campaign.restrictionStartDate).getTime(), new Date(year, 0, 1).getTime()),
    )
    const startMonth = startDate.getMonth()
    const startDay = startDate.getDate()
    // endMonth should be capped at the end of the year
    const endDate = new Date(
      Math.min(new Date(campaign.restrictionEndDate).getTime(), new Date(year, 11, 31).getTime()),
    )
    const endMonth = endDate.getMonth()
    const endDay = endDate.getDate()
    return {
      ...campaign,
      startMonth,
      endMonth,
      monthSpan: endMonth - startMonth + 1,
      startDay,
      endDay,
    }
  })
}

/** Group raw campaign list to object Map of  targetTypeID --> campaignList
 * ie. 0 (HTM) --> [campaign1, campaign2, ...]; 1 (MTM) --> [campaign3, ...]
 * @param {CampaignWithMonthSpan[]} campaignList Raw campaign with targetTypeID
 * @return {Record<string, CampaignWithMonthSpan[]>} Record Mapping of targetTypeID to campaignList
 */
function groupCampaignByProvince(campaignList: CampaignWithMonthSpan[]): Record<string, CampaignWithMonthSpan[]> {
  return campaignList.reduce<Record<string, CampaignWithMonthSpan[]>>((prev, curr) => {
    if (prev.hasOwnProperty(curr.targetTypeID)) prev[curr.targetTypeID].push(curr)
    else prev[curr.targetTypeID] = [curr]
    return prev
  }, {})
}

/** Get Campaign Calendar mapping of targetTypeID --> 2D Campaign of each concurrent month
 * ie. 0 (HTM) --> [[campaign1], [campaign2, ...]]; 1 (MTM) --> [[campaign3, ...]]
 * @param {CampaignWithTargetTypeID[]} campaignList Raw campaign with targetTypeID
 * @param {Record<string, string | number>[]} provinceList List of actual targetTypeID
 * @return {Record<string, CampaignWithMonthSpan[][]>} 2D mapping of campaign
 */
function getCampaignCalendar(
  campaignList: CampaignWithTargetTypeID[],
  optionList: Record<string, string | number>[],
): Record<string, CampaignWithMonthSpan[][]> {
  const campaignMeta = fillCampaignMonthMeta(campaignList)
  const campaignGroup = groupCampaignByProvince(campaignMeta)
  // Get Campaign Calendar mapping of targetTypeID --> 2D Campaign of each concurrent month
  const campaignCalendar = optionList
    .map((p) => p.value)
    .reduce<Record<string, CampaignWithMonthSpan[][]>>((prev, curr) => {
      prev[curr] = dedupCampaign(destructureConcurrentCampaign(campaignGroup[curr]))
      return prev
    }, {})
  return campaignCalendar
}

/** Remove all booleans in a 2d array
 * ie. [ 0: [false, campaign, true, false,...] --> [campaign...] ]
 * @param {(CampaignWithMonthSpan | boolean)[][]} campaignSlotList 2D raw campaign with targetTypeID
 * @return {CampaignWithMonthSpan[][]} 2d array of campaignList (removed boolean)
 */
function dedupCampaign(campaignSlotList: (CampaignWithMonthSpan | boolean)[][]): CampaignWithMonthSpan[][] {
  return campaignSlotList.map((rawSlot) => rawSlot.filter((s) => typeof s !== 'boolean') as CampaignWithMonthSpan[])
}

/** Destructuring concurrent campaign in the form of a 2d array
 * ie. [ 0: [false, campaign, true, false,...]
 *       1: [false, false, false, ...]] with length of 12. false = not reserved, true = reserved, campaign =
 * @param {CampaignWithMonthSpan[]} campaignList Raw campaign with targetTypeID
 * @return {(CampaignWithMonthSpan | boolean)[][]} 2d array of campaignList
 */
function destructureConcurrentCampaign(
  campaignList: CampaignWithMonthSpan[],
  length = 12,
): (CampaignWithMonthSpan | boolean)[][] {
  const nullArray = new Array<CampaignWithMonthSpan | boolean>(length).fill(false)
  const campaignSlotList = [[...nullArray]]
  if (!campaignList) return campaignSlotList
  for (const campaign of campaignList) {
    const freeSlot = findFreeSlot(campaignSlotList, campaign)
    if (typeof freeSlot === 'number') reserveFreeSlot(campaignSlotList, campaign, freeSlot)
    else openAndReserveSlot(campaignSlotList, campaign)
  }
  return campaignSlotList
}

/** Find free slot in slotList
 * @param {(CampaignWithMonthSpan | boolean)[][]} slotList The available reserved slots
 * @param {CampaignWithMonthSpan} campaign The campaign to find free slot
 * @return {number | null} The free slot avaliable
 */
function findFreeSlot(slotList: (CampaignWithMonthSpan | boolean)[][], campaign: CampaignWithMonthSpan): number | null {
  let freeSlot: number | null = null
  for (const [slotIndex, slotRow] of slotList.entries()) {
    // Check if whole section is free
    const isFree = slotRow.slice(campaign.startMonth, campaign.endMonth + 1).every((e) => e === false)
    if (isFree) {
      freeSlot = slotIndex
      break
    }
  }
  return freeSlot
}

/** Reserve free slot in slotList
 * @param {(CampaignWithMonthSpan | boolean)[][]} slotList The available reserved slots
 * @param {CampaignWithMonthSpan} campaign The campaign to find free slot
 * @param {number} freeSlot The index with the freeSlot
 * @return {void} Updated free slot
 */
function reserveFreeSlot(
  slotList: (CampaignWithMonthSpan | boolean)[][],
  campaign: CampaignWithMonthSpan,
  freeSlot: number,
): void {
  slotList[freeSlot][campaign.startMonth] = campaign
  for (let i = campaign.startMonth + 1; i <= campaign.endMonth; i++) {
    slotList[freeSlot][i] = true
  }
}

/** Reserve free slot in slotList
 * @param {(CampaignWithMonthSpan | boolean)[][]} slotList The available reserved slots
 * @param {CampaignWithMonthSpan} campaign The campaign to find free slot
 * @return {void} Updated new slot with reservation
 */
function openAndReserveSlot(
  slotList: (CampaignWithMonthSpan | boolean)[][],
  campaign: CampaignWithMonthSpan,
  length = 12,
): void {
  const nullArray = new Array<CampaignWithMonthSpan | boolean>(length).fill(false)
  slotList.push([...nullArray])
  reserveFreeSlot(slotList, campaign, slotList.length - 1)
}
