// Lib
import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useFormikContext } from 'formik'

//Images

// Include in project
import { Props, SwitchSx } from './config'

const SwitchComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { handleChange } = useFormikContext<any>()

  return (
    <FormControlLabel
      control={
        <Switch
          checked={props.checked}
          name={props.name}
          onChange={props.onChange || handleChange}
          disabled={props.disabled}
          sx={SwitchSx}
        />
      }
      label={<p style={{ padding: '0px 5px' }}>{props.label && props.label}</p>}
    />
  )
}

export default SwitchComponent
