// Lib
import _ from 'lodash'
import { useDispatch } from 'react-redux'

// Include in project
import { transformationDataToServer, ETransformationDataToServer } from 'utils/tranformData'
import { setCloseDialog } from 'states/slice/modalDialog'

const useHandleActionMasterData = ({
  errors,
  values,
  createMasterData,
  updateMasterData,
  typeUpdateMasterData,
  deleteMasterData,
  refetchMasterData,
  refetchMasterDataTrashList,
  closeModal,
  key,
}: any) => {
  const dispatch = useDispatch()

  const handleUpdate = () => {
    if (!_.isEmpty(errors)) return
    const packData: any = transformationDataToServer({
      values: values.formInput,
      type: typeUpdateMasterData,
    })

    if (!values.formInput[key]) {
      delete packData?.input[key]
      if (
        typeUpdateMasterData === ETransformationDataToServer.UpdateShopRetailer ||
        typeUpdateMasterData === ETransformationDataToServer.UpdateShopBigRetailer
      ) {
        delete packData?.input.addressID
      }
      createMasterData({ variables: packData }).then((res: any) => {
        if (Object.keys(res.data).length !== 1) return

        if (res.data[Object.keys(res.data)[0]].res_code === '99') return

        refetchMasterData()
        closeModal()
      })
    } else {
      updateMasterData({ variables: packData }).then((res: any) => {
        if (Object.keys(res.data).length !== 1) return

        if (res.data[Object.keys(res.data)[0]].res_code === '99') return

        refetchMasterData()
        closeModal()
      })
    }
  }

  const handleDelete = () => {
    const packData = transformationDataToServer({
      values: values.formInput[key],
      type: ETransformationDataToServer.DeleteMasterData,
    })

    deleteMasterData({ variables: packData }).then((res: any) => {
      if (Object.keys(res.data).length !== 1) return

      if (res.data[Object.keys(res.data)[0]].res_code === '99') return

      refetchMasterData()
      refetchMasterDataTrashList()
      closeModal()
      dispatch(setCloseDialog())
    })
  }

  return {
    handleUpdate,
    handleDelete,
  }
}

export default useHandleActionMasterData
