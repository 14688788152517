// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { useModalForm, useModalRecently, useUnit } from 'hooks'
import { HeadOfMasterData, ModalOfUnit, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import yupUnit from 'validations/yupUnit.validate'
import { RootState } from 'states/store'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const UnitManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()

  const getUnitReducers = useSelector((state: RootState) => state.unit)

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    id: '',
    unitName: '',
  })
  const unitList = transformationDataIntoTable({
    dataList: getUnitReducers.unitList,
    type: ETranformationDataIntoTable.ListUnit,
    handleOpen,
  })

  const {
    data: resUnitTrachList,
    refetch: refetchUnitTrashList,
    createUnit,
    updateUnit,
    deleteUnit,
    recoverUnit,
  } = useUnit({
    filter: {
      isActive: false,
    },
    pageSize: 9999,
    nextToken: null,
  })
  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoverUnit,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoverUnit,
    refetchMasterDataTrash: refetchUnitTrashList,
    refetchMasterData: getUnitReducers.refetch,
  })

  return (
    <Layout open={false} section={t('unitManagement.title')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('unitManagement.recentlyDeleted')}
          buttonName={t('unitManagement.buttonProduct')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
        />
        <Table
          objectKey={{
            unitName: 'Unit',
            actionView: 'Action',
          }}
          data={unitList || []}
        />

        <ModalOfUnit
          openModal={isModalOpen}
          closeModal={handleClose}
          createUnit={createUnit}
          updateUnit={updateUnit}
          deleteUnit={deleteUnit}
          refetchUnitList={getUnitReducers.refetch}
          refetchUnitTrashList={refetchUnitTrashList}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{
            unit: '100%',
          }}
          keyTable={{
            unitName: 'Unit',
          }}
          dataTable={resUnitTrachList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('unitManagement.recentlyTitle'),
                description: t<string>('unitManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  onRecoverUnit()
                  dispatch(setCloseDialog())
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="id"
        />
      </div>
    </Layout>
  )
}

const EnhancedUnitManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      id: '',
      unitName: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupUnit,
  handleSubmit: () => {
    console.log('skip to handleFunction')
  },
})(UnitManagement)
export default EnhancedUnitManagement
