// Lib
import React from 'react'
import { Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

// Images
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField } from 'component'
import { Props } from './config'
import { Form, useFormikContext } from 'formik'

const ModalPointAdjustment: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { errors, handleSubmit } = useFormikContext<any>()

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Form className={styles.containerModal}>
        <div className={styles.wrapperCard}>
          <div className={styles.head}>
            <h3>{t<string>('checkPointCamapaign.pointAdjust')}</h3>
            <img src={closeSVG} alt="closeSVG" onClick={props.onClose} />
          </div>

          <div className={styles.cardTotal}>
            <h6>{t<string>('checkPointCamapaign.pointTotal')}</h6>

            <div className={styles.groupInput}>
              <p>{t<string>('common.point')}:</p>
              <TextField name="pointAdjustment.point" type={'number'} />
              <h6>{t<string>('common.star')} </h6>
            </div>

            <div className={styles.line} />

            <TextField
              name="pointAdjustment.adjustmentReason"
              type="text"
              label="Comment and Detail"
              require
              placeholder="Your comment"
            />
          </div>

          <div className={styles.wrapperButton}>
            <Button type="secondary" typeButton="button" name={t<string>('common.cancel')} disabled={false} />
            <Button
              type="primary"
              typeButton="submit"
              name={t<string>('common.save')}
              disabled={false}
              functionOnClick={() => {
                handleSubmit()
                props.onPointAdjustment()
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}
export default ModalPointAdjustment
