// Lib
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, useFormikContext, withFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { TableUserManagement } from 'container'
import { Button } from 'component'
import styles from './index.module.scss'
import { useUserPermission } from 'hooks'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/tranformData'
import { UserPermissionInput } from 'utils/generated'
import type { RootState } from 'states/store'

const UserRoleManagement: React.FC = () => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<{ userPermissionList: UserPermissionInput[] }>()
  const dispatch = useDispatch()
  const getPermissionListReducer = useSelector((state: RootState) => state.permission)
  const { updateUserPermission } = useUserPermission()

  useEffect(() => {
    if (!getPermissionListReducer.permissionList) return
    setFieldValue('userPermissionList', getPermissionListReducer.permissionList)
  }, [getPermissionListReducer.permissionList])

  const handleUpdate = () => {
    const packData = transformationDataToServer({
      values: values.userPermissionList,
      type: ETransformationDataToServer.UpdateUserPermission,
    })
    updateUserPermission({ variables: packData }).then((res: any) => {
      if (res.data.updateUserPermission.res_code === '99') return

      getPermissionListReducer.refetch()
      dispatch(setCloseDialog())
    })
  }

  return (
    <Layout open={false} section={t('userManagement.title')}>
      <Form className={styles.container}>
        <div className={styles.table}>
          <TableUserManagement />
        </div>
        <div className={styles.button}>
          <Button
            typeButton="submit"
            type="primary"
            disabled={false}
            name={t<string>('common.save')}
            functionOnClick={() => {
              dispatch(
                setOpenDialog({
                  title: t<string>('userManagement.daialogTitle'),
                  description: t<string>('userManagement.daialogDescription'),
                  status: 'success',
                  functionOnSubmit: handleUpdate,
                }),
              )
            }}
          />
        </div>
      </Form>
    </Layout>
  )
}

const EnhancedUserRoleManagement = withFormik({
  mapPropsToValues: () => ({
    userPermissionList: [],
  }),
  handleSubmit: () => {
    // console.log(values, 'values')
  },
})(UserRoleManagement)
export default EnhancedUserRoleManagement
