import * as Yup from 'yup'

const yupDistrict = Yup.object().shape({
  formInput: Yup.object().shape({
    districtName: Yup.string().required('Plese Enter District Name'),
    provinceID: Yup.string().required('Plese Select Province'),
  }),
})

export default yupDistrict
