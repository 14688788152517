// Lib
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'

// Include in project
import { setDecodeToken, IDecodeToken } from 'states/slice/decodeToken'
import type { RootState } from 'states/store'

const useIntitialDecode = () => {
  const dispatch = useDispatch()
  const getPermissionReducer = useSelector((state: RootState) => state.permission)

  const accessToken = localStorage.getItem('accessToken')
  useEffect(() => {
    if (!accessToken) return
    if (getPermissionReducer.permissionList.length === 0) return

    const decode: IDecodeToken = jwt_decode(accessToken)
    const findPermission = getPermissionReducer.permissionList.find((ele: any) => ele.userRoleID === decode.userRoleID)

    const packData = {
      ...decode,
      permission: findPermission.permission,
    }

    dispatch(setDecodeToken(packData))
  }, [accessToken, getPermissionReducer])
}

export default useIntitialDecode
