// Lib
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { SelectSingle, TextField } from 'component'
import { ECampaignType } from 'utils/generated'

interface Props {
  type: 'NORMAL' | 'SPECIAL'
}

const SetupNewCampaignModeEdit: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation()

  const optionCampaignType = useMemo(() => {
    if (type === 'NORMAL') return [{ label: 'Normal', value: ECampaignType.Normal }]
    else
      return [
        { label: 'Plus bonus point', value: ECampaignType.SpecialPlusBonusPoint },
        { label: 'Step Level Targeting', value: ECampaignType.SpecialStepLevelTargeting },
        { label: 'Achieved Targeting', value: ECampaignType.SpecialAchievedTargeting },
        { label: 'Special month each targeting', value: ECampaignType.SpecialMonthlyEachTargeting },
        { label: 'Special month acc targeting', value: ECampaignType.SpecialMonthlyAccTargeting },
      ]
  }, [type])

  return (
    <section className={styles.container}>
      <h5>{t<string>('createNormalCampaign.setUpNewCampaign')}</h5>
      <div className={styles.wrapperInput}>
        <TextField
          name="campaign.campaignName"
          type="text"
          label={t<string>('createNormalCampaign.name')}
          placeholder="Name of campaign"
          require
        />
        <TextField
          name="campaign.campaignDescription"
          type="text"
          label={t<string>('common.description')}
          placeholder="Description"
        />
        <SelectSingle
          name="campaign.campaignType"
          option={optionCampaignType}
          disabled={false}
          label={t<string>('createNormalCampaign.type')}
          require
        />

        <TextField name="campaign.campaignGoal" type="text" label={t<string>('createNormalCampaign.campaignGoal')} />
      </div>
    </section>
  )
}
export default SetupNewCampaignModeEdit
