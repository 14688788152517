import { gql } from '@apollo/client'

export const REVIEW_CAMPAIGN_CALCULATE_POINT = gql`
  query ReviewCampaignCalculationPoint(
    $pageSize: Int!
    $nextToken: String
    $filter: FilterListCampaignCalculationPointInput
  ) {
    reviewCampaignCalculationPoint(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      items {
        shopID
        shopName
        shopType
        shopCode
        totalPoint
        phoneNumber
        saleVolume
        rawPoint
        point
        adjustmentPoint
        updatedAt
      }
      nextToken
      res_code
      res_desc
    }
  }
`

export const REQUEST_APPROVE_CALCULATE_POINT = gql`
  mutation RequestApproveCalculatePoint($input: RequestApproveCalculatePointInput!) {
    requestApproveCalculatePoint(input: $input) {
      res_code
      res_desc
      pointStatus
    }
  }
`

export const REVIEW_POINT_HISTORY_DETAIL = gql`
  query ReviewPointHistoryDetail($campaignId: String!, $shopId: Int!, $shopType: EShopType!, $month: Int, $year: Int) {
    reviewPointHistoryDetail(
      campaignID: $campaignId
      shopID: $shopId
      shopType: $shopType
      month: $month
      year: $year
    ) {
      res_code
      res_desc
      campaignID
      campaignName
      restrictionStartDate
      restrictionEndDate
      customerName
      phoneNumber
      point
      targetProductList {
        tagID
        tagName
        productID
        productName
        levelList {
          level
          point
          actualTarget
          target
          actualPoint
        }
      }
      pointTransaction {
        inputDate
        orderCode
        saleVolume
        targetProduct
        productName
        unitName
        point
        insertBy
      }
    }
  }
`

export const CREATE_POINT_ADJUSTMENT = gql`
  mutation CreatePointAdjustment($input: CreatePointAdjustmentInput!) {
    createPointAdjustment(input: $input) {
      res_code
      res_desc
      shopID
      shopType
      point
      adjustmentReason
      campaignID
      month
      year
    }
  }
`
