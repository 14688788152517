// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import exportFromJSON from 'export-from-json'

//Images
import excelSVG from 'images/sendPoint/excel.svg'
import pdfSVG from 'images/sendPoint/pdf.svg'

// Include in project
import styles from './index.module.scss'
import { Props } from './config'
import { Button, Table } from 'component'

const PointTransaction: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const data = props.dataList.map((data: any) => ({
    Timestamp: data.timelineFormat,
    [`Order Code`]: data.orderCode,
    [`Sale Volume`]: data.saleVolume,
    [`Product Name`]: data.productName,
    Unit: data.unitName,
    Point: data.point,
    By: data.insertBy,
  }))

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.wrapperText}>
          <h4>{t<string>('checkPointCamapaign.pointTransaction')}</h4>
          <h6 className={styles.text}>
            {t<string>('createNormalCampaign.name')} : <pre className={styles.textDetail}>{props.campaignName}</pre>
          </h6>
        </div>

        <div className={styles.wrapperButton}>
          <Button
            type="primary"
            typeButton="button"
            disabled={false}
            name={t<string>('common.dowload')}
            icon={excelSVG}
            functionOnClick={() =>
              exportFromJSON({ data, fileName: props.fileName, exportType: exportFromJSON.types.xls })
            }
          />
          <Button
            type="primary"
            typeButton="button"
            disabled={false}
            name={t<string>('common.dowload')}
            icon={pdfSVG}
          />
        </div>
      </div>

      <div className={styles.wrapperTable}>
        <Table
          objectKey={{
            timelineFormat: 'Timestamp',
            orderCode: 'Order Code',
            saleVolume: 'Sale Volume',
            productName: 'Product Name',
            unitName: 'Unit',
            point: 'Point',
            insertBy: 'By',
          }}
          data={props.dataList || []}
        />
      </div>
    </section>
  )
}
export default PointTransaction
