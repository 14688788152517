import { useQuery, useMutation } from '@apollo/client'
import { GET_LIST_ZONE, CREATE_ZONE, UPDATE_ZONE, RECOVERY_ZONE, DELETE_ZONE } from 'queries/zone.queries'
import { useHandleResponseAPI } from 'hooks'

function useZone(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_ZONE, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createZone, optionCreate] = useMutation(CREATE_ZONE)
  const [updateZone, optionUpdate] = useMutation(UPDATE_ZONE)
  const [deleteZone, optionDelete] = useMutation(DELETE_ZONE)
  const [reconveryZone, optionRecovery] = useMutation(RECOVERY_ZONE)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listZone?.res_code === '99') return JSON.stringify(errorQuery || data?.listZone?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createZone?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createZone?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateZone?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateZone?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteZone?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteZone?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryZone?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryZone?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listZone,
    zoneLoading: loading,
    refetch,
    createZone,
    updateZone,
    reconveryZone,
    deleteZone,
  }
}

export default useZone
