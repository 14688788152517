import * as Yup from 'yup'

const yupNormalCampaign = Yup.object().shape({
  campaign: Yup.object().when('activeStep', {
    is: 0,
    then: Yup.object().shape({
      campaignName: Yup.string().required('Please enter your Campaign Name'),
      productList: Yup.array(
        Yup.object({
          levelList: Yup.array(
            Yup.object({
              point: Yup.number().required().min(1, 'Please Add'),
            }),
          ),
        }),
      ).min(1, 'Please Select Product'),
      productListDummy: Yup.array().min(1, 'Please Select Product'),
      timeDuration: Yup.array().min(2, 'Please Select Time Duration'),
    }),
  }),
})

export default yupNormalCampaign
