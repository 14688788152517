// Lib
import React from 'react'
import { MenuItem, InputLabel, FormControl, ListItemText } from '@mui/material'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { useFormikContext } from 'formik'

//Images

// Include in project
import styles from './index.module.scss'
import { Props, SelectSx, CheckboxSx, MenuProps } from './config'
import Color from 'abstracts/export.module.scss'
import { ChipInput } from 'component'

const SelectMutipleComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>()
  return (
    <div className={styles.wapper}>
      <p>{props.label}</p>
      <FormControl size="small" fullWidth>
        <Select
          multiple
          name={props.name}
          value={props.value}
          displayEmpty
          onChange={(e) => {
            setFieldValue(`${props.name}`, e.target.value)
          }}
          renderValue={(selected) => (
            <div className={`${styles.chipItem}`}>
              {selected.map((e: any, i: number) => {
                return <ChipInput key={i} label={e} color={'primary'} disabled={false} />
              })}
            </div>
          )}
          sx={SelectSx}
          MenuProps={MenuProps}
        >
          {props.option &&
            props.option.map((e, i) => (
              <MenuItem key={i} value={e.value}>
                <Checkbox sx={CheckboxSx} checked={values[props.name].indexOf(e.label) > -1} />
                <ListItemText
                  sx={CheckboxSx}
                  primary={<p style={{ color: `${Color.primaryMain}`, fontWeight: 'bold' }}>{e.label}</p>}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectMutipleComponent
