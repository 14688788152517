// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { HeadOfMasterData, ModalOfZone, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import { useModalForm, useModalRecently, useZone } from 'hooks'
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import yupZone from 'validations/yupZone.validate'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const ZoneManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()
  const getZoneListReducer = useSelector((state: RootState) => state.zone)

  // ZoneList Hook and HandleFunction
  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    zoneID: '',
    zoneName: '',
  })
  const zoneList = transformationDataIntoTable({
    dataList: getZoneListReducer.zoneList,
    type: ETranformationDataIntoTable.ListZone,
    handleOpen,
  })

  // ZoneTrashList Hook and HandleFunction
  const {
    data: resZoneTrashList,
    refetch: refetchZoneTrashList,
    createZone,
    updateZone,
    reconveryZone,
    deleteZone,
  } = useZone({
    pageSize: 9999,
    filter: {
      isActive: false,
    },
  })
  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoverZone,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: reconveryZone,
    refetchMasterDataTrash: refetchZoneTrashList,
    refetchMasterData: getZoneListReducer.refetch,
  })

  return (
    <Layout open={false} section={t('zoneManagement.section')}>
      <div className={styles.container}>
        <HeadOfMasterData
          deletedArchive={t('common.recentDelete')}
          buttonName={t('zoneManagement.buttonopenModol')}
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
        />
        <Table
          objectKey={{
            zoneID: 'Zone ID',
            zoneName: 'Zone Name',
            actionView: 'Action',
          }}
          data={zoneList || []}
        />
        <ModalOfZone
          openModal={isModalOpen}
          closeModal={handleClose}
          refetchZoneList={getZoneListReducer.refetch}
          refetchZoneTrashList={refetchZoneTrashList}
          createZone={createZone}
          updateZone={updateZone}
          deleteZone={deleteZone}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{}}
          keyTable={{
            zoneID: 'Zone ID',
            zoneName: 'Zone Name',
          }}
          dataTable={resZoneTrashList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('zoneManagement.recentlyTitle'),
                description: t<string>('zoneManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  dispatch(setCloseDialog())
                  onRecoverZone()
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="zoneID"
        />
      </div>
    </Layout>
  )
}

const EnhancedZoneManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      zoneID: '',
      zoneName: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupZone,
  handleSubmit: () => {
    console.log('Skip Handle Sumit of formik')
  },
})(ZoneManagement)

export default EnhancedZoneManagement
