import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  unitList: [],
  unitListOption: [],
  refetch: null,
}

export const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    setUnit: (state, action: PayloadAction<any>) => {
      state.unitList = action.payload.items
      state.unitListOption = action.payload.items.map((data: any) => ({
        value: data.id,
        label: data.unitName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setUnit } = unitSlice.actions

export default unitSlice.reducer
