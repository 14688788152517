// Lib
import React, { useMemo } from 'react'
import { Button } from '@mui/material'

//Images

// Include in project
import { Props, colorButton } from './config'

const ButtonComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const seletedColor = useMemo(() => colorButton[props.type], [])

  return (
    <Button
      type={props.typeButton}
      onClick={props.functionOnClick && props.functionOnClick}
      disabled={props.disabled}
      sx={seletedColor}
      style={{ gap: '10px', textTransform: 'none', height: '35px' }}
      variant="contained"
      fullWidth={props.fullWidth}
      className="fontBold"
    >
      {props.name && <q>{props.name}</q>}
      {props.icon && <img src={props.icon} width={23} height={23} />}
    </Button>
  )
}

export default ButtonComponent
