// Lib
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// Include in project
import {
  useZone,
  useProvince,
  useDistrict,
  useSegment,
  useUnit,
  useTags,
  useBrand,
  useProduct,
  useShop,
  useShopBigRetailer,
  useUserPermissionList,
  useHandleResponseAPI,
  useNotificationByPermission,
  useNotification,
} from 'hooks'
import { setZone } from 'states/slice/zone'
import { setProvince } from 'states/slice/province'
import { setDistrict } from 'states/slice/district'
import { setSegment } from 'states/slice/segment'
import { setUnit } from 'states/slice/unit'
import { setTags } from 'states/slice/tags'
import { setBrand } from 'states/slice/brand'
import { setProduct } from 'states/slice/product'
import { setShop } from 'states/slice/shop'
import { setPermission } from 'states/slice/permission'
import { setShopBigRetailer } from 'states/slice/shopBigRetailer'
import { setListNotification, setListNotificationByPermission } from 'states/slice/notificationBell'

const useIntitialMasterData = () => {
  const dispatch = useDispatch()

  //   <----- START Zone ----->
  const {
    data: resZoneList,
    zoneLoading,
    refetch: refetchZone,
  } = useZone({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setZone({ items: resZoneList?.items || [], refetch: refetchZone }))
  }, [resZoneList])
  //   <----- END Zone ----->

  //   <----- START Province ----->
  const {
    data: resProvinceList,
    provinceLoading,
    refetch: refetchProvince,
  } = useProvince({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })
  useEffect(() => {
    dispatch(setProvince({ items: resProvinceList?.items || [], refetch: refetchProvince }))
  }, [resProvinceList])
  //   <----- END Province ----->

  //   <----- START District ----->
  const {
    data: resDistrictList,
    districtLoading,
    refetch: refetchDistrict,
  } = useDistrict({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })
  useEffect(() => {
    dispatch(setDistrict({ items: resDistrictList?.items || [], refetch: refetchDistrict }))
  }, [resDistrictList])
  //   <----- END District ----->

  //   <----- START Unit ----->
  const {
    data: resUnitList,
    unitLoading,
    refetch: refetchUnit,
  } = useUnit({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })
  useEffect(() => {
    dispatch(setUnit({ items: resUnitList?.items || [], refetch: refetchUnit }))
  }, [resUnitList])
  //   <----- END Unit ----->

  //   <----- START Segment ----->
  const {
    data: resSegmentList,
    segmentLoading,
    refetch: refetchSegment,
  } = useSegment({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setSegment({ items: resSegmentList?.items || [], refetch: refetchSegment }))
  }, [resSegmentList])
  //   <----- END Segment ----->

  //   <----- START Tags ----->
  const {
    data: resTagsList,
    tagsLoading,
    refetch: refetchTags,
  } = useTags({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setTags({ items: resTagsList?.items || [], refetch: refetchTags }))
  }, [resTagsList])

  //   <----- END Tags ----->

  //   <----- START Brand ----->
  const {
    data: resBrandList,
    brandLoading,
    refetch: refetchBrand,
  } = useBrand({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setBrand({ items: resBrandList?.items, refetch: refetchBrand }))
  }, [resBrandList])
  //   <----- END Brand ----->

  //   <----- START Product ----->
  const {
    data: resProductList,
    productLoading,
    refetch: refetchProduct,
  } = useProduct({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setProduct({ items: resProductList?.items || [], refetch: refetchProduct }))
  }, [resProductList])
  //   <----- END Product ----->

  //   <----- START Shop ----->
  const {
    data: resShopList,
    shopLoading,
    refetch: refetchShopList,
  } = useShop({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setShop({ items: resShopList?.items || [], refetch: refetchShopList }))
  }, [resShopList])
  //   <----- END Shop ----->

  //   <----- START Permission ----->
  const {
    data: resPermission,
    permissionLoading,
    refetch: refetchPermission,
  } = useUserPermissionList({
    pageSize: 9999,
  })

  useEffect(() => {
    dispatch(setPermission({ items: resPermission?.items || [], refetch: refetchPermission }))
  }, [resPermission])
  //   <----- END Permission ----->

  //   <----- START Shop Big Retailer ----->
  const {
    data: resShopBigRetailerList,
    shopBigLoading,
    refetch: refetchShopBigRetailerList,
  } = useShopBigRetailer({
    pageSize: 9999,
    filter: {
      isActive: true,
    },
  })

  useEffect(() => {
    dispatch(setShopBigRetailer({ items: resShopBigRetailerList?.items || [], refetch: refetchShopBigRetailerList }))
  }, [resShopBigRetailerList])
  //   <----- END Shop Big Retailer ----->

  //   <----- START listNotificationByPermission ----->
  const {
    data: listNotificationByPermission,
    loading: notiPermissionloading,
    refetch: refetchNotiPermissionloading,
  } = useNotificationByPermission({
    pageSize: 25,
    nextToken: null,
  })

  useEffect(() => {
    dispatch(
      setListNotificationByPermission({
        items: listNotificationByPermission?.permissionList || [],
        refetch: refetchNotiPermissionloading,
      }),
    )
  }, [listNotificationByPermission])
  //   <----- END listNotificationByPermission ----->

  //   <----- START listNotification ----->
  const {
    data: listNotification,
    loading: listNotificationLoading,
    refetch: refetchListNotification,
  } = useNotification({
    pageSize: 25,
    nextToken: null,
  })

  useEffect(() => {
    dispatch(
      setListNotification({
        items: listNotification?.items || [],
        refetch: refetchListNotification,
        nextToken: listNotification?.nextToken || '',
      }),
    )
  }, [listNotification])
  //   <----- END listNotification ----->

  useHandleResponseAPI({
    isLoading:
      zoneLoading ||
      provinceLoading ||
      districtLoading ||
      unitLoading ||
      segmentLoading ||
      tagsLoading ||
      brandLoading ||
      productLoading ||
      shopLoading ||
      shopBigLoading ||
      permissionLoading ||
      notiPermissionloading ||
      listNotificationLoading,
    errMsg: '',
  })
}

export default useIntitialMasterData
