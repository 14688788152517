// Lib
import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

//Images

// Include in project
import styles from './index.module.scss'
import { IMenuApp, ISubMenuApp } from 'config'
import type { RootState } from 'states/store'
import { Notification } from 'utils/generated'
import { useReadNotification } from 'hooks'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/tranformData'

interface Props {
  data: IMenuApp
  permission: any
}

const MenuNavSide: React.FC<Props> = ({ data, permission }: Props): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(true)

  const notificationBellReducer = useSelector((state: RootState) => state.notificationBell)

  function countSub() {
    if (!data.subMenu) return 0

    let count = 0
    for (let index = 0; index < data.subMenu.length; index++) {
      if (permission[data.subMenu[index].accessKey as string]) count++
    }

    return count
  }

  const { readNotification } = useReadNotification()
  const handleClickMenu = async (link: string, findNotiPermission: Notification) => {
    const promiseReadNoti = new Promise((resolve, reject) => {
      if (findNotiPermission) {
        const packData = transformationDataToServer({
          values: findNotiPermission,
          type: ETransformationDataToServer.ReadNotification,
        })

        readNotification({ variables: packData }).then((res: any) => {
          if (res.data.readNotification.res_code === '99') return resolve(false)
          return resolve(true)
        })
      } else {
        return resolve(true)
      }
    })

    console.log('3', await promiseReadNoti)

    if (notificationBellReducer.refetchList) {
      notificationBellReducer.refetchList({
        pageSize: 25,
        nextToken: null,
      })
    }

    if (notificationBellReducer.refetchListByPermission) {
      notificationBellReducer.refetchListByPermission({
        pageSize: 25,
        nextToken: null,
      })
    }

    navigate(link)
  }

  if (countSub() === 0) return <></>
  return (
    <div className={styles.group}>
      <div className={styles.boxSummary}>
        <img className={styles.icon} src={data.photo} alt="" />
        <p className="fontOnlyNavSideBar">{data.name}</p>
        <p
          aria-expanded={isOpen}
          className={`${styles.arrow} fontOnlyNavSideBar`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {'>'}
        </p>
      </div>
      {isOpen &&
        data.subMenu?.map((dataSubMenu: ISubMenuApp, indexSub) => {
          if (!permission[dataSubMenu.accessKey as string]) return <></>

          const findNotiPermission = notificationBellReducer.listNotificationByPermission.find(
            (ele: string) =>
              ele ===
              (dataSubMenu.accessKey as string).charAt(0).toUpperCase() + (dataSubMenu.accessKey as string).slice(1),
          )
          const findDotRed = Boolean(findNotiPermission)

          return (
            <p
              key={indexSub}
              className={
                location.pathname.search(dataSubMenu.link) > -1
                  ? `${styles.activeSubManu} fontOnlyNavSideBar`
                  : `${styles.notActiveSubManu} fontOnlyNavSideBar`
              }
              onClick={() => handleClickMenu(dataSubMenu.link, findNotiPermission)}
              aria-expanded={findDotRed}
            >
              {dataSubMenu.name}
            </p>
          )
        })}
    </div>
  )
}

export default MenuNavSide
