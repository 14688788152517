import { useMutation, useQuery } from '@apollo/client'
import { useHandleResponseAPI } from 'hooks'
import { REQUEST_APPROVE_CALCULATE_POINT, REVIEW_CAMPAIGN_CALCULATE_POINT } from 'queries/reviewCalculatePoint.queries'

function useReviewCalculatePoint(variables?: any) {
  const { data, refetch, error, loading } = useQuery(REVIEW_CAMPAIGN_CALCULATE_POINT, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [requestApproveCalculatePoint, optionRequestApproveCalculatePoint] = useMutation(
    REQUEST_APPROVE_CALCULATE_POINT,
  )
  const isLoading = loading || optionRequestApproveCalculatePoint.loading

  const errMsg = () => {
    //  Query
    if (error || data?.reviewCampaignCalculationPoint?.res_code === '99')
      return JSON.stringify(error || data?.reviewCampaignCalculationPoint?.res_desc)

    // optionRequestApproveCalculatePoint
    if (
      optionRequestApproveCalculatePoint?.error ||
      optionRequestApproveCalculatePoint.data?.optionRequestApproveCalculatePoint?.res_code === '99'
    )
      return JSON.stringify(
        optionRequestApproveCalculatePoint?.error ||
          optionRequestApproveCalculatePoint.data?.optionRequestApproveCalculatePoint?.res_desc,
      )

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.reviewCampaignCalculationPoint,
    refetch,
    requestApproveCalculatePoint,
  }
}

export default useReviewCalculatePoint
