// Lib
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'

// Include in project
import Layout from 'layouts'
import styles from './index.module.scss'
import {
  CampaignRestrictionsModeView,
  CampaignRestrictionsSpecialModeView,
  DocumentCampaign,
  FooterButtonReviewCampaign,
  SetupNewCampaignModeView,
  TargetOfCondition,
} from 'container'
import { ETranformationDataIntoClient, tranformationDataIntoClient } from 'utils/tranformData'
import { useCampaign, useCampaignNormal, useCampaignSpecial } from 'hooks'
import { ECampaignListType, ECampaignStatus, ECampaignType } from 'utils/generated'

const ReviewCampaignDetail: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const { approveCampaign } = useCampaign()
  const { getCampaign: getCampaignSpecial } = useCampaignSpecial()
  const { getCampaign: getCampaignNoraml } = useCampaignNormal()

  const campaignType = useMemo(() => {
    if (searchParams.get('campaignType') === ECampaignType.Normal) return ECampaignListType.Normal
    else return ECampaignListType.Special
  }, [searchParams])

  useEffect(() => {
    if (campaignType === ECampaignListType.Normal) {
      getCampaignNoraml({
        variables: { campaignId: searchParams.get('campaignID'), type: `${campaignType}` },
      }).then((res) => {
        const detailCampaign = res.data.campaignDetail
        if (detailCampaign.res_code === '99') return

        const dataTranform = tranformationDataIntoClient({
          values: detailCampaign,
          type: ETranformationDataIntoClient.DetailNormalCampaign,
        })
        setFieldValue('campaign', dataTranform)
      })
    } else {
      getCampaignSpecial({
        variables: { campaignId: searchParams.get('campaignID'), type: `${campaignType}` },
      }).then((res) => {
        const detailCampaign = res.data.campaignDetail
        if (detailCampaign.res_code === '99') return

        const dataTranform = tranformationDataIntoClient({
          values: detailCampaign,
          type: ETranformationDataIntoClient.DetailSpecialCampaign,
        })
        setFieldValue('campaign', dataTranform)
      })
    }
  }, [searchParams])

  const renderCampaignRestric = (type: string) => {
    switch (type) {
      case 'NORMAL':
        return <CampaignRestrictionsModeView />
      default:
        return <CampaignRestrictionsSpecialModeView />
    }
  }

  return (
    <Layout open={true} section={t('ReviewCampaign.section')}>
      <div className={styles.container}>
        <div className={styles.card}>
          <SetupNewCampaignModeView />
          {renderCampaignRestric(values.campaign.campaignType)}
          <TargetOfCondition />
          <DocumentCampaign />
        </div>
        {values.campaign.campaignStatus === ECampaignStatus.WaitingApprove && (
          <FooterButtonReviewCampaign approveCampaign={approveCampaign} />
        )}
      </div>
    </Layout>
  )
}

const EnhancedReviewCampaignDetail = withFormik({
  mapPropsToValues: () => ({
    status: 'ALL',
    campaign: {
      // Value in container SetupNewCampaignModeView
      campaignName: '',
      campaignDescription: '',
      campaignType: '',
      campaignGoal: '',

      // Value in container CampaignRestrictionsModeView or CampaignRestrictionsSpecialModeView
      shopType: '',
      targetType: '',
      targetTypeValue: [],
      tagRetainer: [],
      targetProduct: [],
      timeDuration: [],
      isSubRTLCal: false,
      imageList: [], // Keep Image Url

      remark: '', // Reason Why leader is reject campaign

      campaignID: '',
      status: 'DRAFT',
    },
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(ReviewCampaignDetail)
export default EnhancedReviewCampaignDetail
