// Lib
import React from 'react'
import { MenuItem, FormControl, ListItemText, Select } from '@mui/material'
import { getIn, useFormikContext } from 'formik'

//Images

// Include in project
import styles from './index.module.scss'
import Color from 'abstracts/export.module.scss'
import { Props, SelectSx, CheckboxSx, MenuProps } from './config'

const SelectSingleComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<any>()

  return (
    <div className={styles.wapper}>
      <p>
        {props.label}
        {props.require && <span className={'startRequire'}>*</span>}
      </p>
      <FormControl size="small" fullWidth>
        <Select
          name={props.name}
          value={getIn(values, props.name)}
          displayEmpty
          onChange={handleChange}
          sx={SelectSx}
          MenuProps={MenuProps}
          error={getIn(touched, props.name) && Boolean(getIn(errors, props.name))}
          fullWidth
          disabled={props.disabled}
        >
          {props.option &&
            props.option.map((e, i) => (
              <MenuItem key={i} value={e.value}>
                <ListItemText
                  sx={CheckboxSx}
                  primary={<p style={{ color: `${Color.primaryMain}`, fontWeight: 'bold' }}>{e.label}</p>}
                />
              </MenuItem>
            ))}
        </Select>
        <p className={styles.errorMsg}>
          {getIn(touched, props.name) && getIn(errors, props.name) && getIn(errors, props.name)}
        </p>
      </FormControl>
    </div>
  )
}

export default SelectSingleComponent
