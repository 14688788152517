// Lib
import React from 'react'
import { useFormikContext } from 'formik'

// Include in project
import styles from './index.module.scss'

const StepProgressBarWithFormik: React.FC = (): JSX.Element => {
  const { values }: { values: { activeStep: number } } = useFormikContext()

  const step = ['1. Setup New Campaign', '2. Review', '3. Request Approve']
  return (
    <div className={styles.container}>
      <div className={styles.wrapperStep}>
        {step.map((label, index: number) => (
          <div key={index} className={styles.groupStep}>
            <q className={values.activeStep >= index ? styles.textActive : styles.textDefault}>{label}</q>
            <div className={values.activeStep >= index ? styles.progressActive : styles.progress} />
          </div>
        ))}
      </div>
      <div className={styles.wrapperStepMobile}>
        {step.map((label, index: number) => (
          <div key={index} className={styles.groupStep}>
            <h4 className={values.activeStep >= index ? styles.textActive : styles.textDefault}>{index + 1}</h4>
            <div className={values.activeStep > index ? styles.progressActive : styles.progress}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepProgressBarWithFormik
