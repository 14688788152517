// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'

// Images
import deleteSVG from 'images/commons/delete.svg'
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField } from 'component'
import { setOpenDialog } from 'states/slice/modalDialog'
import { ETransformationDataToServer } from 'utils/tranformData'
import { useHandleActionMasterData } from 'hooks'

interface Props {
  openModal: boolean
  closeModal: () => void
  createBrand: any
  updateBrand: any
  deleteBrand: any
  refetchBrandList: any
  refetchBrandTrashList: any
}

const ModalOfBrand: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, handleSubmit } = useFormikContext<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { handleUpdate, handleDelete } = useHandleActionMasterData({
    errors,
    values,
    createMasterData: props.createBrand,
    updateMasterData: props.updateBrand,
    typeUpdateMasterData: ETransformationDataToServer.UpdateBrand,
    deleteMasterData: props.deleteBrand,
    refetchMasterData: props.refetchBrandList,
    refetchMasterDataTrashList: props.refetchBrandTrashList,
    closeModal: props.closeModal,
    key: 'productBrandID',
  })

  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <div className={styles.containerModal}>
        <div className={styles.section}>
          <h3>{t('brandManagement.titleModol')}</h3>
          <img className={styles.closeIcon} src={closeSVG} alt="closeSVG" onClick={props.closeModal} />
        </div>
        <TextField name={`formInput.brandName`} type="text" label="Brand" require />
        <div className={styles.rowButton}>
          {values.formInput.productBrandID && (
            <div className={styles.button}>
              <Button
                typeButton="button"
                type={'outline'}
                disabled={false}
                name={t<string>('common.delete')}
                icon={deleteSVG}
                fullWidth
                functionOnClick={() =>
                  dispatch(
                    setOpenDialog({
                      title: t<string>('brandManagement.deleteTitle'),
                      description: t<string>('brandManagement.deleteDescription'),
                      status: 'danger',
                      functionOnSubmit: handleDelete,
                    }),
                  )
                }
              />
            </div>
          )}
          <div className={styles.button}>
            <Button
              typeButton="submit"
              type={'primary'}
              disabled={false}
              name={t<string>('common.save')}
              fullWidth
              functionOnClick={() => {
                handleSubmit()
                handleUpdate()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalOfBrand
