// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { Button, SelectSingle } from 'component'
import { ECampaignListStatus, EPointStatus } from 'utils/generated'

interface Props {
  isShowButton?: boolean
  isPointStatus?: boolean
  titleButton?: string
  onClickButton?: () => void
}

const HeadFilterByStatus: React.FC<Props> = ({ titleButton, onClickButton, isShowButton, isPointStatus }) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <p>{t('common.status')}</p>
      <div className={styles.inputForm}>
        {isPointStatus ? (
          <SelectSingle
            name={'pointStatus'}
            option={[
              { label: 'All', value: 'ALL' },
              { label: 'Approved', value: EPointStatus.Approved },
              { label: 'Rejected', value: EPointStatus.Rejected },
              { label: 'Waiting Approve', value: EPointStatus.WaitingApprove },
              { label: 'Waiting Review', value: EPointStatus.WaitingReview },
            ]}
            disabled={false}
          />
        ) : (
          <SelectSingle
            name={'status'}
            option={[
              { label: 'All', value: ECampaignListStatus.All },
              { label: 'Active', value: ECampaignListStatus.Active },
              { label: 'Waiting Approve', value: ECampaignListStatus.WaitingApprove },
              { label: 'Rejected', value: ECampaignListStatus.Rejected },
              { label: 'Draft', value: ECampaignListStatus.Draft },
              { label: 'Expired', value: ECampaignListStatus.Expired },
            ]}
            disabled={false}
          />
        )}
      </div>
      {isShowButton && (
        <Button
          typeButton="button"
          type="primary"
          disabled={false}
          name={titleButton}
          functionOnClick={() => onClickButton?.()}
        />
      )}
    </section>
  )
}
export default HeadFilterByStatus
