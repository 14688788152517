// Lib
import React from 'react'

// Include in project
import { Props } from './config'
import styles from './index.module.scss'

const StepProgressBar: React.FC<Props> = (props: Props): JSX.Element => {
  const step = ['1. Setup New Campaign', '2. Review', '3. Request Approve']
  return (
    <div className={styles.container}>
      <div className={styles.wrapperStep}>
        {step.map((label, index: number) => (
          <div key={index} className={styles.groupStep}>
            <q className={props.activeStep >= index ? styles.textActive : styles.textDefault}>{label}</q>
            <div className={props.activeStep >= index ? styles.progressActive : styles.progress} />
          </div>
        ))}
      </div>
      <div className={styles.wrapperStepMobile}>
        {step.map((label, index: number) => (
          <div key={index} className={styles.groupStep}>
            <h4 className={props.activeStep >= index ? styles.textActive : styles.textDefault}>{index + 1}</h4>
            <div className={props.activeStep > index ? styles.progressActive : styles.progress}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepProgressBar
