// Lib
import React from 'react'

//Images

// Include in project
import { ChipInput } from 'component'
import { Props } from './config'

const ChipByStatusCampaign: React.FC<Props> = (props: Props): JSX.Element => {
  switch (props.status.toLocaleUpperCase()) {
    case 'ACTIVE':
      return <ChipInput color={'success'} label="Active" />
    case 'WAITING_APPROVE':
      return <ChipInput color={'warning'} label="Waiting Approve" />
    case 'REJECTED':
      return <ChipInput color={'danger'} label="Rejected" />
    case 'DRAFT':
      return <ChipInput color={'info'} label="Draft" />
    case 'EXPIRED':
      return <ChipInput color={'disabled'} label="Expired" />
    default:
      return <></>
  }
}

export default ChipByStatusCampaign
