import { useLazyQuery } from '@apollo/client'
import { useHandleResponseAPI } from 'hooks'
import {
  GET_MONTHLY_STAR,
  GET_MONTHLY_SALE_VOLUMN,
  GET_MONTHLY_COST_PER_TON,
  GET_BY_ZONE_ACHIVED_RTL,
  GET_BY_ZONE_STAR,
  GET_BY_ZONE_COST_PER_TON,
} from 'queries/dashboard.queries'

function useDashboard() {
  const [getMonthlyStar, { data: dataMonthlyStar, loading: loadingMonthlyStar, error: errorMonthlyStar }] =
    useLazyQuery(GET_MONTHLY_STAR, {
      fetchPolicy: 'no-cache',
    })

  const [getMonthlySale, { data: dataMonthlySale, loading: loadingMonthlySale, error: errorMonthlySale }] =
    useLazyQuery(GET_MONTHLY_SALE_VOLUMN, {
      fetchPolicy: 'no-cache',
    })

  const [
    getMonthlyCostPerTon,
    { data: dataMonthlyCostPerTon, loading: loadingMonthlyCostPerTon, error: errorMonthlyCostPerTon },
  ] = useLazyQuery(GET_MONTHLY_COST_PER_TON, {
    fetchPolicy: 'no-cache',
  })

  const [
    getByZoneAchivedRtl,
    { data: dataByZoneAchivedRtl, loading: loadingByZoneAchivedRtl, error: errorByZoneAchivedRtl },
  ] = useLazyQuery(GET_BY_ZONE_ACHIVED_RTL, {
    fetchPolicy: 'no-cache',
  })

  const [getByZoneStar, { data: dataByZoneStar, loading: loadingByZoneStar, error: errorByZoneStar }] = useLazyQuery(
    GET_BY_ZONE_STAR,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const [
    getByZoneCostPerTon,
    { data: dataByZoneCostPerTon, loading: loadingByZoneCostPerTon, error: errorByZoneCostPerTon },
  ] = useLazyQuery(GET_BY_ZONE_COST_PER_TON, {
    fetchPolicy: 'no-cache',
  })

  const errMsg = () => {
    if (errorMonthlyStar || dataMonthlyStar?.listMonthlyStar?.res_code === '99')
      return JSON.stringify(errorMonthlyStar || dataMonthlyStar?.listMonthlyStar?.res_desc)

    if (errorMonthlySale || dataMonthlySale?.listMonthlySaleVolume?.res_code === '99')
      return JSON.stringify(errorMonthlySale || dataMonthlySale?.listMonthlySaleVolume?.res_desc)

    if (errorMonthlyCostPerTon || dataMonthlyCostPerTon?.listMonthlyCostPerTon?.res_code === '99')
      return JSON.stringify(errorMonthlyCostPerTon || dataMonthlyCostPerTon?.listMonthlyCostPerTon?.res_desc)

    if (errorByZoneAchivedRtl || dataByZoneAchivedRtl?.listByZoneAchievedRTL?.res_code === '99')
      return JSON.stringify(errorByZoneAchivedRtl || dataByZoneAchivedRtl?.listByZoneAchievedRTL?.res_desc)

    if (errorByZoneStar || dataByZoneStar?.listByZoneStar?.res_code === '99')
      return JSON.stringify(errorByZoneStar || dataByZoneStar?.listByZoneStar?.res_desc)

    if (errorByZoneCostPerTon || dataByZoneCostPerTon?.listByZoneCostPerTon?.res_code === '99')
      return JSON.stringify(errorByZoneCostPerTon || dataByZoneCostPerTon?.listByZoneCostPerTon?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading:
      loadingMonthlyStar ||
      loadingMonthlySale ||
      loadingMonthlyCostPerTon ||
      loadingByZoneAchivedRtl ||
      loadingByZoneStar ||
      loadingByZoneCostPerTon,
    // errMsg: errMsg(),
    errMsg: '',
  })

  return {
    getMonthlyStar,
    getMonthlySale,
    getMonthlyCostPerTon,
    getByZoneAchivedRtl,
    getByZoneStar,
    getByZoneCostPerTon,
    dataMonthlyStar: dataMonthlyStar?.listMonthlyStar,
    dataMonthlySale: dataMonthlySale?.listMonthlySaleVolume,
    dataMonthlyCostPerTon: dataMonthlyCostPerTon?.listMonthlyCostPerTon,
    dataByZoneAchivedRtl: dataByZoneAchivedRtl?.listByZoneAchievedRTL,
    dataByZoneStar: dataByZoneStar?.listByZoneStar,
    dataByZoneCostPerTon: dataByZoneCostPerTon?.listByZoneCostPerTon,
    errMsg: errMsg(),
  }
}

export default useDashboard
