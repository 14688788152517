import Color from 'abstracts/export.module.scss'

export const cancelColorList = {
  default: {
    backgroundColor: `${Color.primarySecond}`,
    color: `${Color.primaryMain}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.primarySecond}`,
    },
  },
  danger: {
    backgroundColor: `${Color.dangerSecond}`,
    color: `${Color.dangerMain}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.dangerSecond}`,
    },
  },
  success: {
    backgroundColor: `${Color.successSecond}`,
    color: `${Color.successMain}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.successSecond}`,
    },
  },
  warning: {
    backgroundColor: `${Color.warningSecond}`,
    color: `${Color.warningMain}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.warningSecond}`,
    },
  },
  info: {
    backgroundColor: `${Color.infoSecond}`,
    color: `${Color.infoMain}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.infoSecond}`,
    },
  },
}

export const okeyColorList = {
  default: {
    backgroundColor: `${Color.primaryMain}`,
    color: `${Color.white}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.primaryMain}`,
    },
  },
  danger: {
    backgroundColor: `${Color.dangerMain}`,
    color: `${Color.white}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.dangerMain}`,
    },
  },
  success: {
    backgroundColor: `${Color.successMain}`,
    color: `${Color.white}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.successMain}`,
    },
  },
  warning: {
    backgroundColor: `${Color.warningMain}`,
    color: `${Color.white}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.warningMain}`,
    },
  },
  info: {
    backgroundColor: `${Color.infoMain}`,
    color: `${Color.white}`,
    fontSize: '12px',
    fontWeight: '700px ',
    '&:hover': {
      backgroundColor: `${Color.infoMain}`,
    },
  },
}

export const ModalSx = {
  zIndex: '3000',
  '*': {
    outline: 'none',
  },
}
