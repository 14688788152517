import * as Yup from 'yup'

const yupProduct = Yup.object().shape({
  formInput: Yup.object().shape({
    productBrandID: Yup.string().required('Please Select Brand'),
    productName: Yup.string().required('Please Enter Product Name'),
    productUnit_id: Yup.string().required('Please Select Unit/Star'),
  }),
})

export default yupProduct
