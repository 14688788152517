// Lib
import React, { useEffect, useState } from 'react'
import { withFormik, useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useMsal } from '@azure/msal-react'
import jwt_decode from 'jwt-decode'

// Images
import photoSidePNG from 'images/Login/loginNav.png'

// Include in project
import styles from './index.module.scss'
import { FormLogin } from 'container'
import useLogin from 'hooks/api/uselogin'
import yupLogin from 'validations/yupLogin.validate'
import { aesEncrypt, checkIfAzureEmail } from 'utils/other'
import { env } from 'config'
import { loginRequest } from 'config/azure'
import useScgLogin from 'hooks/api/useScgLogin'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const { values, setFieldValue, errors } = useFormikContext<{ email: string; hash: string; remember: string[] }>()
  const [isShowInputPassword, setIsShowInputPassword] = useState(false)

  useEffect(() => {
    const email = localStorage.getItem('email')
    // const hash = localStorage.getItem('hash')
    // if (!email || !hash) return
    if (!email) return

    setFieldValue('email', email)
    // setFieldValue('hash', aesDecrypt(hash, env.LOGIN_AES_KEY))
    setFieldValue('remember', ['remember'])
  }, [])

  function getTimestampInSecondsPlusOneDay() {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return `${date.getTime()}`
  }

  const { login } = useLogin((response) => {
    if (values.remember.length === 1) {
      localStorage.removeItem('expiredLogin')
      localStorage.setItem('email', values.email)
      // localStorage.setItem('hash', aesEncrypt(values.hash, env.LOGIN_AES_KEY))
    }
    if (values.remember.length === 0) {
      localStorage.setItem('expiredLogin', getTimestampInSecondsPlusOneDay())
      localStorage.removeItem('email')
      // localStorage.removeItem('hash')
    }

    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.setItem('refreshToken', response.data.refreshToken)

    navigate('/')
  })

  const { scgLogin } = useScgLogin((response) => {
    if (values.remember.length === 1) {
      localStorage.removeItem('expiredLogin')
      localStorage.setItem('email', values.email)
    }
    if (values.remember.length === 0) {
      localStorage.setItem('expiredLogin', getTimestampInSecondsPlusOneDay())
      localStorage.removeItem('email')
    }

    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.setItem('refreshToken', response.data.refreshToken)

    navigate('/')
  })

  const handleSubmitLogin = async () => {
    console.log(errors, 'errors')
    if (!_.isEmpty(errors)) return

    const packData = {
      email: values.email,
      hash: aesEncrypt(values.hash, env.LOGIN_AES_KEY),
    }

    login(packData)
  }

  const { instance } = useMsal()
  const handleScgLogin = async () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        const decode: { upn: string } = jwt_decode(res.accessToken)

        const packData = {
          email: decode.upn,
        }

        scgLogin(packData)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const isSCG = checkIfAzureEmail(values?.email)

  const handleShowInputPassword = () => {
    setIsShowInputPassword(!isShowInputPassword)
  }

  return (
    <div className={styles.container}>
      <section className={styles.wrapperInput}>
        <FormLogin
          handleSubmitLogin={isSCG ? handleScgLogin : handleSubmitLogin}
          handleShowInputPassword={handleShowInputPassword}
          isShowInputPassword={isShowInputPassword}
        />
      </section>
      <section className={styles.wrapperImg}>
        <img src={photoSidePNG} alt="photoSidePNG" />
      </section>
    </div>
  )
}

const EnhancedLogin = withFormik({
  mapPropsToValues: () => ({
    email: '',
    hash: '',
    remember: [],
  }),
  validationSchema: yupLogin,
  handleSubmit: () => {
    console.log('SKIP')
  },
})(Login)
export default EnhancedLogin
