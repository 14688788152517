// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

// Images

// Include in project
import { SelectSingle } from 'component'
import { useFilterYearMonth } from 'utils/other'
import styles from './index.module.scss'

const HeadOfCampaignCalendar: React.FC = () => {
  const { t } = useTranslation()
  const { yearsListOption } = useFilterYearMonth()
  return (
    <div className={styles.selectContent}>
      <SelectSingle label="Status" name="status" option={status} disabled={false} />
      <SelectSingle label="Year" name="year" option={yearsListOption} disabled={false} />
      <SelectSingle
        label={t<string>('common.targetType')}
        name="targetType"
        option={[
          {
            label: 'Zone',
            value: 'ZONE',
          },
          {
            label: 'Province',
            value: 'PROVINCE',
          },
          {
            label: 'District',
            value: 'DISTRICT',
          },
          {
            label: 'Segment',
            value: 'SEGMENT',
          },
          {
            label: 'Retailer',
            value: 'RETAILER',
          },
          {
            label: 'Big Retailer',
            value: 'BIG_RETAILER',
          },
        ]}
        disabled={false}
      />
    </div>
  )
}

export default HeadOfCampaignCalendar

const status = [
  { label: 'All', value: 'ALL' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Waiting Approve', value: 'WAITING_APPROVE' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Expired', value: 'EXPIRED' },
]
