import { useLazyQuery, useMutation } from '@apollo/client'
import useHandleResponseAPI from 'hooks/useHandleResponseAPI'
import { CREATE_POINT_ADJUSTMENT, REVIEW_POINT_HISTORY_DETAIL } from 'queries/reviewCalculatePoint.queries'

function useReviewPointHistoryDetail(variables?: any) {
  const [getReviewPointDetail, { data, refetch, loading, error }] = useLazyQuery(REVIEW_POINT_HISTORY_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [creatPointAdjustment, optionCreatePointAdjustment] = useMutation(CREATE_POINT_ADJUSTMENT)

  const isLoading = loading || optionCreatePointAdjustment.loading

  const errMsg = () => {
    // Query
    if (error || data?.reviewPointHistoryDetail?.res_code === '99')
      return JSON.stringify(error || data?.reviewPointHistoryDetail?.res_desc)

    // optionCreatePointAdjustment
    if (
      optionCreatePointAdjustment?.error ||
      optionCreatePointAdjustment.data?.createPointAdjustment?.res_code === '99'
    )
      return JSON.stringify(
        optionCreatePointAdjustment?.error || optionCreatePointAdjustment.data?.createPointAdjustment?.res_desc,
      )

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    refetch,
    getReviewPointDetail,
    creatPointAdjustment,
  }
}

export default useReviewPointHistoryDetail
