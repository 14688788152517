// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

//Images
import searchSVG from 'images/commons/search.svg'

// Include in project
import styles from './index.module.scss'
import { Button, SelectSingle, TextField } from 'component'
import { RootState } from 'states/store'

const HeadOfUserList: React.FC<any> = ({ handleOpen }) => {
  const { t } = useTranslation()
  const getPermissionListReducer = useSelector((state: RootState) => state.permission)

  return (
    <section className={styles.container}>
      <div className={styles.wrapperButton}>
        <SelectSingle
          label={t('common.status')}
          name={'filter.isActive'}
          option={[
            { label: 'Inactive', value: 'INACTIVE' },
            { label: 'Active', value: 'ACTIVE' },
          ]}
          disabled={false}
        />
        <SelectSingle
          label="User Role"
          name={'filter.userRoleID'}
          option={getPermissionListReducer.permissionListOption}
          disabled={false}
        />
      </div>

      <div className={styles.wrapperButton}>
        <TextField name="filter.email" type="text" placeholder="Email" dataAdornment={searchSVG} />
        <TextField name="filter.name" type="text" placeholder="Name" dataAdornment={searchSVG} />
        <Button
          typeButton="button"
          type="primary"
          disabled={false}
          name={t<string>('userListManagement.newUser')}
          fullWidth
          functionOnClick={() => handleOpen()}
        />
      </div>
    </section>
  )
}
export default HeadOfUserList
