// Lib
import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

//Images

// Include in project
import { Props, ThemeColor } from './config'

const AccoradionComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const [expanded, setExpanded] = useState(props.expanded)
  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={ThemeColor}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <q>{props.title}</q>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  )
}

export default AccoradionComponent
