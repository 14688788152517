// Lib
import moment from 'moment'

export const convertDateToISO = (date: string) => {
  return new Date(date).toISOString()
}

export const convertDateFormat = (date: string) => {
  return moment(date).format('D MMM YYYY')
}

export const convertDateFormatTimeStamp = (date: string) => {
  const dateFormat = moment(date).format('D MMM YYYY')
  const timeFormat = moment(date).format('LT')
  return `${dateFormat} at ${timeFormat}`
}

export const convertRangeDate = (date: string[]) => {
  if (date.length <= 0) return
  return `${convertDateFormat(date[0])} - ${convertDateFormat(date[1])}`
}

export const convertRelativeTime = (date: string) => {
  return moment(date).startOf('day').fromNow()
}
