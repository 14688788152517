import { gql } from '@apollo/client'

export const GET_LIST_UNIT = gql`
  query ListUnit($pageSize: Int!, $nextToken: String, $filter: FilterListUnitInput) {
    listUnit(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      items {
        id
        unitName
        isActive
      }
      nextToken
    }
  }
`
export const CREATE_UNIT = gql`
  mutation Mutation($input: CreateUnitInput!) {
    createUnit(input: $input) {
      res_code
      res_desc
      id
      unitName
      isActive
    }
  }
`

export const UPDATE_UNIT = gql`
  mutation Mutation($input: UpdateUnitInput!) {
    updateUnit(input: $input) {
      res_code
      res_desc
      id
      unitName
      isActive
    }
  }
`

export const DELETE_UNIT = gql`
  mutation Mutation($input: DeleteUnitInput!) {
    deleteUnit(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVER_UNIT = gql`
  mutation RecoveryUnit($input: RecoveryUnitInput!) {
    recoveryUnit(input: $input) {
      res_code
      res_desc
    }
  }
`
