import Color from 'abstracts/export.module.scss'

export interface Props {
  label?: string
  checked?: boolean
  name: string
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  value: string
}

export const ColorTheme = {
  color: `${Color.primaryMain}`,
  // color: `${Color.gray300}`,
  // '&.MuiCheckbox-root': {
  //   color: `${Color.grey500}`,
  // },
  '&.Mui-checked': {
    color: `${Color.primaryMain}`,
  },
  '&.Mui-disabled': {
    color: `${Color.grey500}`,
  },
}
