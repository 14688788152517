import { gql } from '@apollo/client'

export const GET_DATA_FROM_DMS = gql`
  query ListDataSource($pageSize: Int!, $filter: FilterListDataSourceInput, $nextToken: String) {
    listDataSource(pageSize: $pageSize, filter: $filter, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        dataSourceID
        inputDate
        dmsCode
        orderCode
        code
        retailerName
        retailerLevel1Name
        year
        month
        productBrand
        productName
        saleVolume
        unitOrder
        shopType
        zone
        province
        district
        segment
        phoneNumber
        zaloID
        email
        adjustmentReason
        adjustmentByUserID
        adjustmentByName
      }
    }
  }
`

export const ADJUST_SALE_VOLUME = gql`
  mutation AdjustSaleVolume($input: AdjustSaleVolumeInput!) {
    adjustSaleVolume(input: $input) {
      res_code
      res_desc
      dataSourceID
      adjustmentReason
      saleVolume
    }
  }
`

//ADHocData

export const ADHocDATA = gql`
  mutation AdHocData($input: QueryAdHocInput!) {
    adHocData(input: $input) {
      res_code
      res_desc
    }
  }
`

export const ManualImportData = gql`
  mutation ManualImportData($input: ManualImportDatasourceInput) {
    manualImportData(input: $input) {
      res_code
      res_desc
    }
  }
`
