// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { Props } from './config'

const CardPointSummary: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <h4>{t<string>('checkPointCamapaign.pointSummary')}</h4>
      <div className={styles.wrapperTable}>
        <table className="tableMain">
          <thead>
            <tr>
              <th rowSpan={2}>
                <p>{t<string>('createNormalCampaign.promotion')}</p>
              </th>
              {props.dataList.targetProductList &&
                props.dataList.targetProductList[0]?.levelList.map((item: any, index: number) => (
                  <th key={index}>
                    <p>{item.level}</p>
                  </th>
                ))}
            </tr>
            <tr>
              {props.dataList?.targetProductList &&
                props.dataList.targetProductList[0]?.levelList.map((index: number) => (
                  <th key={index}>
                    <p>{t<string>('createSpecialCampaign.bonus')}</p>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {props.dataList.targetProductList &&
              props.dataList.targetProductList.map((element: any, eleIndex: number) => (
                <tr key={eleIndex}>
                  <td>{element.tagName || element.productName}</td>
                  {element.levelList.map((level: any, levelIdx: number) => (
                    <td key={levelIdx}>{level.point}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className={styles.wrapperTable}>
        <table className="tableMain">
          <thead>
            <tr>
              <th rowSpan={2}>
                <p> {t<string>('createNormalCampaign.promotion')}</p>
              </th>
              {props.dataList?.targetProductList &&
                props.dataList.targetProductList[0]?.levelList.map((item: any, index: number) => (
                  <th colSpan={2} key={index}>
                    <p>{item.level}</p>
                  </th>
                ))}
            </tr>
            <tr>
              {props.dataList?.targetProductList &&
                props.dataList.targetProductList[0]?.levelList.map(() => (
                  <>
                    <th>
                      <p> {'Actual (Ton)'}</p>
                    </th>
                    <th>
                      <p> {'Actual (Point)'}</p>
                    </th>
                  </>
                ))}
            </tr>
          </thead>
          <tbody>
            {props.dataList.targetProductList &&
              props.dataList.targetProductList.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.tagName || item.productName}</td>
                  {item.levelList.map((targetItem: any, targetIndex: number) => (
                    <>
                      <td key={targetIndex}>
                        <div className={targetItem.actualTarget > targetItem.target ? styles.groupText : ''}>
                          <p>
                            {targetItem.actualTarget} / {targetItem.target || '-'}
                          </p>
                          <pre>{'(Actual / Target)'}</pre>
                        </div>
                      </td>
                      <td>{targetItem.point}</td>
                    </>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}
export default CardPointSummary
