// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

//Images
import addSVG from 'images/commons/add.svg'

// Include in project
import styles from './index.module.scss'
import { Button, SelectSingle } from 'component'

interface Props {
  buttonName: string
  pathName?: string
}
const HeadOfTableCampaign: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <section className={styles.container}>
      <div className={styles.groupLeft}>
        <p>{t('common.status')}</p>
        <div className={styles.inputForm}>
          <SelectSingle
            name={'status'}
            option={[
              { label: 'All', value: 'ALL' },
              { label: 'Active', value: 'ACTIVE' },
              { label: 'Waiting Approve', value: 'WAITING_APPROVE' },
              { label: 'Rejected', value: 'REJECTED' },
              { label: 'Draft', value: 'DRAFT' },
              { label: 'Expired', value: 'EXPIRED' },
            ]}
            disabled={false}
          />
        </div>
      </div>
      <Button
        typeButton={'button'}
        type={'primary'}
        disabled={false}
        name={`${props.buttonName}`}
        icon={addSVG}
        functionOnClick={() => navigate(`${props.pathName}`)}
      />
    </section>
  )
}
export default HeadOfTableCampaign
