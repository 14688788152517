// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

// Include in project
import Layout from 'layouts'
import { HeadOfMasterData, ModalOfSegment, ModalRecentlyDeleted } from 'container'
import { Table } from 'component'
import { useModalForm, useModalRecently, useSegment } from 'hooks'
import styles from './index.module.scss'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import yupSegment from 'validations/yupSegment.validate'
import { RootState } from 'states/store'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const SegmentManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext<any>()
  const getSegmentListReducer = useSelector((state: RootState) => state.segment)

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    segmentID: '',
    segmentName: '',
  })
  const segmentList = transformationDataIntoTable({
    dataList: getSegmentListReducer.segmentList,
    type: ETranformationDataIntoTable.ListSegment,
    handleOpen,
  })

  const {
    createSegment,
    updateSegment,
    deleteSegment,
    data: resSegmentTrachList,
    refetch: refetchTrashSegmentList,
    recoverSegment,
  } = useSegment({
    pageSize: 9999,
    filter: {
      isActive: false,
    },
  })
  const {
    isOpenModalRecently,
    handleCloseModalRecently,
    handleOpenModalRecently,
    onRecover: onRecoverSegment,
  } = useModalRecently({
    recentlyList: values.recently.recentlyList,
    reconveryMasterData: recoverSegment,
    refetchMasterDataTrash: refetchTrashSegmentList,
    refetchMasterData: getSegmentListReducer.refetch,
  })

  return (
    <Layout open={false} section={t('segmentManagement.section')}>
      <div className={styles.container}>
        <HeadOfMasterData
          functionOpenModal={handleOpen}
          functionOpenRecently={handleOpenModalRecently}
          deletedArchive={t('common.recentDelete')}
          buttonName={t('segmentManagement.buttonopenModol')}
        />
        <Table
          objectKey={{
            segmentName: 'Segment Name',
            actionView: 'Action',
          }}
          data={segmentList || []}
        />
        <ModalOfSegment
          openModal={isModalOpen}
          closeModal={handleClose}
          createSegment={createSegment}
          updateSegment={updateSegment}
          deleteSegment={deleteSegment}
          refetchSegmentList={getSegmentListReducer.refetch}
          refetchSegmentTrashList={refetchTrashSegmentList}
        />

        <ModalRecentlyDeleted
          onClose={handleCloseModalRecently}
          isOpen={isOpenModalRecently}
          widthCol={{}}
          keyTable={{
            segmentName: 'Segment Name',
          }}
          dataTable={resSegmentTrachList?.items || []}
          onClickRecover={() =>
            dispatch(
              setOpenDialog({
                title: t<string>('segmentManagement.recentlyTitle'),
                description: t<string>('segmentManagement.recentlyDescrip'),
                status: 'info',
                functionOnSubmit: () => {
                  dispatch(setCloseDialog())
                  onRecoverSegment()
                  setFieldValue('recently.recentlyList', [])
                },
              }),
            )
          }
          primaryKey="segmentID"
        />
      </div>
    </Layout>
  )
}

const EnhancedUserListManagement = withFormik({
  mapPropsToValues: () => ({
    formInput: {
      segmentID: '',
      segmentName: '',
    },
    recently: {
      recentlyList: [],
      selectAll: '',
    },
  }),
  validationSchema: yupSegment,
  handleSubmit: () => {
    console.log('skip')
  },
})(SegmentManagement)
export default EnhancedUserListManagement
