// Lib
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { withFormik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

// Images

// Include in project
import Color from 'abstracts/export.module.scss'
import styles from './index.module.scss'
import { useFilterYearMonth } from 'utils/other'
import { useDashboard } from 'hooks'
import type { RootState } from 'states/store'
import { SelectSingle, Notification } from 'component'
import { IOption } from 'utils/interface'
import { MonthlyStar } from 'utils/generated'

const BarGraphTotalStar: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { yearsListOption } = useFilterYearMonth()

  const getZoneListReducer = useSelector((state: RootState) => state.zone)
  const getProvinceListReducer = useSelector((state: RootState) => state.province)
  const getDistrictListReducer = useSelector((state: RootState) => state.district)
  const getSegmentListReducer = useSelector((state: RootState) => state.segment)
  const getShopListReducer = useSelector((state: RootState) => state.shop)
  const getShopBigListReducer = useSelector((state: RootState) => state.shopBigRetailer)

  const optionOfTargetTypeValue = useMemo(() => {
    switch (values.type) {
      case 'ZONE':
        return getZoneListReducer.zoneListOption

      case 'PROVINCE':
        return getProvinceListReducer.provinceListOption

      case 'DISTRICT':
        return getDistrictListReducer.districtListOption

      case 'SEGMENT':
        return getSegmentListReducer.segmentListOption

      case 'RETAILER':
        return getShopListReducer.retialerListOption

      case 'BIG_RETAILER':
        return getShopBigListReducer.bigRetialerListOption

      default:
        return []
    }
  }, [
    values.type,
    getZoneListReducer,
    getProvinceListReducer,
    getDistrictListReducer,
    getSegmentListReducer,
    getShopListReducer,
    getShopBigListReducer,
  ])

  const { getMonthlyStar, dataMonthlyStar, errMsg } = useDashboard()
  useEffect(() => {
    if (!optionOfTargetTypeValue) return

    getMonthlyStar({
      variables: {
        year: values.year,
        type: values.type,
        value: optionOfTargetTypeValue.find((ele: IOption) => ele.value === values.value)?.label || '',
      },
    })
  }, [values.year, values.type, values.value, optionOfTargetTypeValue])

  const optionsBarTotalStar = {
    plugins: {
      title: {
        display: true,
        text: 'Total Star',
      },
      datalabels: {
        display: true,
        color: 'black',
      },
      legend: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Star',
        },
      },
    },
  }

  const labels = []
  for (let index = 0; index < 12; index++) {
    labels.push(index + 1)
  }

  const dataBarTotalStar = {
    labels,
    datasets: [
      {
        label: 'Basic Star',
        data: dataMonthlyStar?.items?.map((data: MonthlyStar) => data.basicStar) || [],
        backgroundColor: `${Color.infoBorder}`,
      },
      {
        label: 'Bonus Star',
        data: dataMonthlyStar?.items?.map((data: MonthlyStar) => data.bonusStar) || [],
        backgroundColor: `${Color.warningBorder}`,
      },
    ],
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  useEffect(() => {
    if (errMsg) return setIsOpen(true)
  }, [errMsg])
  return (
    <>
      <div className={styles.filter}>
        <SelectSingle name="year" label={'Year'} option={yearsListOption} disabled={false} require />
        <SelectSingle
          name="type"
          label={'Type'}
          option={[
            { label: 'Zone', value: 'ZONE' },
            { label: 'Province', value: 'PROVINCE' },
            { label: 'District', value: 'DISTRICT' },
            { label: 'Segment', value: 'SEGMENT' },
            { label: 'Retailer', value: 'RETAILER' },
            { label: 'Big Retailer', value: 'BIG_RETAILER' },
          ]}
          disabled={false}
          require
        />
        <SelectSingle name="value" label={'Value'} option={optionOfTargetTypeValue} disabled={false} require />
      </div>
      <Bar options={optionsBarTotalStar} data={dataBarTotalStar} />
      <Notification
        title="Error From Server"
        description={errMsg}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        type="danger"
      />
    </>
  )
}

const EnhancedBarGraphTotalStar = withFormik({
  mapPropsToValues: () => ({
    year: new Date().getFullYear(),
    type: 'ZONE',
    value: 1,
  }),
  handleSubmit: () => {
    console.log('Skip')
  },
})(BarGraphTotalStar)

export default EnhancedBarGraphTotalStar
