// Lib
import React, { CSSProperties, useMemo } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'

//Images
import { ReactComponent as Cross } from 'images/commons/cross.svg'
import { ReactComponent as Danger } from 'images/commons/danger.svg'
import { ReactComponent as Success } from 'images/commons/success.svg'
import { ReactComponent as Info } from 'images/commons/info.svg'
import { ReactComponent as Warning } from 'images/commons/warning.svg'

// Include in project
import Color from 'abstracts/export.module.scss'
import styles from './index.module.scss'
import { Props } from './config'

const AlertIcon: React.FC<{ type: string }> = ({ type }): JSX.Element => {
  switch (type) {
    case 'danger':
      return <Danger className={`${styles.iconAlert}`} />
    case 'success':
      return <Success className={`${styles.iconAlert}`} />
    case 'warning':
      return <Warning className={`${styles.iconAlert}`} />
    case 'info':
      return <Info className={`${styles.iconAlert}`} />
    default:
      return <></>
  }
}

const NotificationComponent: React.FC<Props> = (props: Props): JSX.Element => {
  const borderColor = useMemo(() => {
    switch (props.type) {
      case 'danger':
        return `${Color.dangerBorder}`
      case 'success':
        return `${Color.successBorder}`
      case 'warning':
        return `${Color.warningBorder}`
      case 'info':
        return `${Color.infoBorder}`
      default:
        return ''
    }
  }, [])

  const background = useMemo(() => {
    switch (props.type) {
      case 'danger':
        return `${Color.dangerSecond}`
      case 'success':
        return `${Color.successSecond}`
      case 'warning':
        return `${Color.warningSecond}`
      case 'info':
        return `${Color.infoSecond}`
    }
  }, [])

  return (
    <>
      <Snackbar
        open={props.isOpen}
        onClose={props.onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={Slide}
        autoHideDuration={2000}
      >
        <div
          style={
            {
              '--background': background,
              '--borderColor': borderColor,
            } as CSSProperties
          }
          className={`${styles.main}`}
        >
          <div className={`${styles.head}`}>
            <div className={`${styles.titleBox}`}>
              <AlertIcon type={props.type} />
              <h5>{props.title}</h5>
            </div>

            <Cross onClick={props.onClose} className={`${styles.cross}`} />
          </div>
          <div className={styles.padding}>
            <p>{props.description}</p>
          </div>
        </div>
      </Snackbar>
    </>
  )
}

export default NotificationComponent
