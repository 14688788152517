import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  shopList: [],
  retialerListOption: [],
  refetch: null,
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShop: (state, action: PayloadAction<any>) => {
      state.shopList = action.payload.items
      state.retialerListOption = action.payload.items.map((data: any) => ({
        value: data.retailerID,
        label: data.retailerName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setShop } = shopSlice.actions

export default shopSlice.reducer
