import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  tagsList: [],
  tagsRetialer: [],
  tagsProduct: [],
  tagsRetialerListOption: [],
  tagsProductListOption: [],
  refetch: null,
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<any>) => {
      const tagsOnlyRetailer = action.payload.items.filter((ele: any) => ele.typeTag === 'RETAILER')
      const tagsOnlyProduct = action.payload.items.filter((ele: any) => ele.typeTag === 'PRODUCT')

      state.tagsList = action.payload.items

      state.tagsRetialer = tagsOnlyRetailer
      state.tagsProduct = tagsOnlyProduct

      state.tagsRetialerListOption = tagsOnlyRetailer.map((data: any) => ({
        value: data.tagID,
        label: data.tagName,
      }))
      state.tagsProductListOption = tagsOnlyProduct.map((data: any) => ({
        value: data.tagID,
        label: data.tagName,
      }))

      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTags } = tagsSlice.actions

export default tagsSlice.reducer
