import { useMemo } from 'react'
import useHandleResponseAPI from 'hooks/useHandleResponseAPI'
import { useMutation } from 'react-query'
import { scgLogin as scgLoginFetch, ScgLoginParams } from 'services/auth.service'

function useScgLogin(onSuccess?: (data: any) => void) {
  const { mutate, error, isLoading } = useMutation(({ email }: ScgLoginParams) => scgLoginFetch({ email }), {
    onSuccess: (result) => {
      onSuccess?.(result)
    },
  })

  const errMsg = useMemo(() => {
    if (error) return (error as any)?.response?.data?.res_desc
    return ''
  }, [error])

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg,
  })

  return {
    scgLogin: mutate,
  }
}

export default useScgLogin
