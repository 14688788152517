import * as Yup from 'yup'

const yupPointAdjustment = Yup.object().shape({
  pointAdjustment: Yup.object().shape({
    point: Yup.string().required('Please Enter Point'),
    adjustmentReason: Yup.string().required('Please Enter Adjustment Reason'),
  }),
})

export default yupPointAdjustment
