import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import useHandleResponseAPI from 'hooks/useHandleResponseAPI'
import {
  APPROVE_POINT_CAMPAIGN,
  GET_APPROVE_POINT_HISTORY_DETAIL,
  LIST_APPROVE_CAMPAIGN_CALCULATE_POINT,
} from 'queries/approveCampaignCalculatePoint.queries'

function useCampaignApproveCalculatePointList(variables?: any) {
  const { data, refetch, loading, error } = useQuery(LIST_APPROVE_CAMPAIGN_CALCULATE_POINT, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [
    getApprovePointHistory,
    { data: dataGetApprovePointHistory, loading: loadGetApprovePointHistory, error: errGetApprovePointHistory },
  ] = useLazyQuery(GET_APPROVE_POINT_HISTORY_DETAIL)

  const [approvePointCampaign, optionApprovePoint] = useMutation(APPROVE_POINT_CAMPAIGN)

  const isLoading = loading || loadGetApprovePointHistory || optionApprovePoint.loading

  const errMsg = () => {
    // Query
    if (error || data?.approveCampaignCalculatePointList?.res_code === '99')
      return JSON.stringify(error || data?.approveCampaignCalculatePointList?.res_desc)

    // LazyQuery
    if (errGetApprovePointHistory || dataGetApprovePointHistory?.approvePointHistoryDetail?.res_code === '99')
      return JSON.stringify(
        errGetApprovePointHistory || dataGetApprovePointHistory?.approvePointHistoryDetail?.res_desc,
      )

    // optionApprovePoint
    if (optionApprovePoint.error || optionApprovePoint.data?.approvePointCampaign.res_code === '99')
      return JSON.stringify(optionApprovePoint.error || optionApprovePoint.data?.approvePointCampaign.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return { data: data?.approveCampaignCalculatePointList, refetch, getApprovePointHistory, approvePointCampaign }
}
export default useCampaignApproveCalculatePointList
