// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, withFormik } from 'formik'

// Images

// Include in project
import Layout from 'layouts'
import { HeadOfTableCampaign } from 'container'
import { Table } from 'component'
import styles from './index.module.scss'
import { useCampaignNormalList, useInfiniteScroll } from 'hooks'
import { transformationDataIntoTable } from 'utils/tranformData'
import { ECampaignListStatus, ECampaignListType } from 'utils/generated'
import { ETranformationDataIntoTable } from 'utils/tranformData'

const ListOfNormalCampaigns: React.FC = () => {
  const { values } = useFormikContext<{ status: ECampaignListStatus }>()
  const { t } = useTranslation()

  const { data: resCampaignsList, refetch } = useCampaignNormalList({
    pageSize: 25,
    status: values.status,
    type: ECampaignListType.Normal,
    nextToken: null,
  })

  const { dataList } = useInfiniteScroll({
    result: resCampaignsList,
    refetch: refetch,
    variables: {
      pageSize: 25,
      status: values.status,
      type: ECampaignListType.Normal,
      nextToken: resCampaignsList?.nextToken,
    },
    filter: values,
    key: 'campaignsList',
  })

  const campaignList = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.ListNormalCampaign,
  })

  return (
    <Layout open={false} section={t('ListNormalCampaigns.title')}>
      <div className={styles.container}>
        <HeadOfTableCampaign
          buttonName={t('ListNormalCampaigns.create_normal')}
          pathName="/campaign/list-normal/create"
        />
        <Table
          colSize={{
            campaignID: '150px',
            campaignName: '200px',
            countryName: 'auto',
            promotion: '300px',
            timeline: '150px',
            campaignStatusHTML: 'auto',
            view: 'auto',
          }}
          objectKey={{
            campaignID: 'Campaigns ID',
            campaignName: 'Campaigns Name',
            countryName: 'Country',
            promotion: 'Normal Promotion',
            timeline: 'Timeline',
            campaignStatusHTML: 'Status',
            view: 'Data',
          }}
          data={campaignList || []}
        />
      </div>
    </Layout>
  )
}

const EnhancedListOfNormalCampaigns = withFormik({
  mapPropsToValues: () => ({
    status: 'ALL',
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(ListOfNormalCampaigns)
export default EnhancedListOfNormalCampaigns
