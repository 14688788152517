import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  productList: [],
  productListOption: [],
  refetch: null,
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<any>) => {
      state.productList = action.payload.items
      state.productListOption = action.payload.items.map((data: any) => ({
        value: data.productID,
        label: data.productName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProduct } = productSlice.actions

export default productSlice.reducer
