// Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useFormikContext } from 'formik'

//Images
import cloudSVG from 'images/UploadFile/cloud.svg'
import fileImgSVG from 'images/UploadFile/file.svg'
import deleteFileSVG from 'images/UploadFile/delete.svg'

// Include in project
import styles from './index.module.scss'
import { Button } from 'component'
import { ECampaignStatus } from 'utils/generated'

const UploadFile: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()

  const onDeleteFile = (index: number) => {
    const cloneImageList: string[] = [...values.campaign.imageList]

    setFieldValue('campaign.imageList', [...cloneImageList.slice(0, index), ...cloneImageList.slice(index + 1)])
  }

  const upload = (file: any) => {
    if (!file) return

    const form = new FormData()
    form.append('file', file)

    axios({
      method: 'post',
      url: `${process.env['REACT_APP_END_POINT']}/${process.env['REACT_APP_END_POINT_PATH']}${process.env['REACT_APP_END_POINT_STAGE']}-httpUploadFile`,
      data: form,
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    })
      .then((res) => {
        const cloneImageList: string[] = [...values.campaign.imageList]
        setFieldValue('campaign.imageList', [...cloneImageList, res.data.imagePath[0]])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const ButtonInput: React.FC = () => {
    return (
      <div className={styles.groupInput}>
        <Button typeButton={'button'} type={'outline'} disabled={false} name={t<string>('common.uploadFile')} />
        <input
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          onChange={(e: any) => upload(e.target.files[0])}
        />
      </div>
    )
  }

  function convertUrlToFileName(url: string) {
    const splitUrl = url.split('/')
    const fileName = splitUrl[splitUrl.length - 1]
    const fileType = fileName.split('.')[fileName.split('.').length - 1]
    return { fileName, fileType }
  }

  return (
    <div className={styles.container}>
      {values.campaign.imageList.length > 0 ? (
        <div className={styles.wrapperShowFile}>
          <div className={styles.groupFile}>
            {values.campaign.imageList.map((file: string, index: number) => (
              <div className={styles.groupCardFile} key={index} onClick={() => window.open(file)}>
                <div className={styles.groupImg}>
                  <img src={fileImgSVG} alt="fileImgPNG" />
                  {values.campaign.campaignStatus === ECampaignStatus.Draft && values.activeStep === 0 && (
                    <img src={deleteFileSVG} alt="deleteFileSVG" onClick={() => onDeleteFile(index)} />
                  )}
                  <p className={styles.fileType}>{convertUrlToFileName(file).fileType}</p>
                </div>
                <pre className={styles.textFile}>{convertUrlToFileName(file).fileName}</pre>
              </div>
            ))}
          </div>
          {values.campaign.campaignStatus === ECampaignStatus.Draft && values.activeStep === 0 && (
            <div className={styles.groupIcon}>
              <img src={cloudSVG} alt="cloudSVG" />
              <ButtonInput />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapperUpload}>
          {values.campaign.campaignStatus === ECampaignStatus.Draft && values.activeStep === 0 && (
            <>
              <img src={cloudSVG} alt="cloudSVG" />
              <div className={styles.groupText}>
                <q>{t<string>('uploadFile.title')}</q>
                <pre>{t<string>('uploadFile.subTitle')}</pre>
              </div>
              <ButtonInput />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default UploadFile
