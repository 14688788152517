// Lib
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useMsal } from '@azure/msal-react'
import jwt_decode from 'jwt-decode'

// Images
import bellRingingPNG from 'images/commons/bellRinging.png'
import arrowCircleRightPNG from 'images/commons/arrowCircleRight.png'
import hamburgurSVG from 'images/commons/hamburgur.svg'
import logoscgPNG from 'images/commons/iconScg.png'

// Include in project
import styles from './index.module.scss'
import type { RootState } from 'states/store'
import { convertDateFormatTimeStamp, convertRelativeTime } from 'utils/convertDate'
import { Notification } from 'utils/generated'
import { menuApp, IMenuApp, ISubMenuApp } from 'config'
import { useReadNotification } from 'hooks'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/tranformData'
import { IOption } from 'utils/interface'
import { IDecodeToken } from 'states/slice/decodeToken'
import { checkIfAzureEmail } from 'utils/other'

interface Props {
  open: boolean
  section: string
  sideNavOpen: boolean
  setSideNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const NavTopBar: React.FC<Props> = (props: Props): JSX.Element => {
  const navigate = useNavigate()

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorElMenu)

  const [anchorElNoti, setAnchorElNoti] = useState<null | HTMLElement>(null)
  const openNoti = Boolean(anchorElNoti)

  const { instance } = useMsal()
  const handleLogout = () => {
    setAnchorElMenu(null)

    const accessToken = localStorage.getItem('accessToken')
    const decode: IDecodeToken | null = accessToken ? jwt_decode(accessToken as string) : null
    const isSCG = checkIfAzureEmail(decode?.email)

    if (isSCG) {
      localStorage.removeItem('expiredLogin')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      instance.logoutRedirect()
    } else {
      localStorage.removeItem('expiredLogin')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      navigate('/login')
    }
  }

  const getDecodeTokenReducer = useSelector((state: RootState) => state.decodeToken)
  const notificationBellReducer = useSelector((state: RootState) => state.notificationBell)
  const permissionReducer = useSelector((state: RootState) => state.permission)

  const showDotRed = () => {
    const find = notificationBellReducer.listNotification.find((ele: any) => !ele.isRead)
    return Boolean(find)
  }

  const { readNotification } = useReadNotification()
  const handleClickMenu = async (data: Notification) => {
    const flatMenu: IMenuApp[] & ISubMenuApp[] = []
    menuApp.map((data: IMenuApp) => {
      if (data.isSubMenu) {
        data.subMenu?.flat().map((dataSub) => flatMenu.push(dataSub))
      } else {
        flatMenu.push(data)
      }
    })

    const findPath = flatMenu.find(
      (ele) =>
        ele.accessKey === (data.permission as string).charAt(0).toLowerCase() + (data.permission as string).slice(1),
    )

    const packData = transformationDataToServer({
      values: data.permission,
      type: ETransformationDataToServer.ReadNotification,
    })
    const promiseReadNoti = new Promise((resolve, reject) => {
      readNotification({ variables: packData }).then((res: any) => {
        if (res.data.readNotification.res_code === '99') return resolve(false)

        return resolve(true)
      })
    })
    const resReadNoti = await promiseReadNoti
    console.log(resReadNoti)

    notificationBellReducer.refetchList({
      pageSize: 25,
      nextToken: null,
    })
    notificationBellReducer.refetchListByPermission({
      pageSize: 25,
      nextToken: null,
    })
    navigate(`${findPath?.link}`)
    setAnchorElNoti(null)
  }

  const handleScroll = (e: any) => {
    const bottom = e.target.clientHeight - (e.target.scrollHeight - e.target.scrollTop) >= 0
    if (bottom && notificationBellReducer?.nextToken) {
      notificationBellReducer.refetchList({
        pageSize: 25,
        nextToken: notificationBellReducer?.nextToken,
      })
    }
  }

  const RenderBell = () => {
    return (
      <>
        <div className={styles.wrapperBell}>
          <img
            className={styles.bellIcon}
            src={bellRingingPNG}
            alt=""
            onClick={(e: React.MouseEvent<HTMLHeadingElement>) => setAnchorElNoti(e.currentTarget)}
          />
          {showDotRed() && <div className={`${styles.isRead} ${styles.isReadBell}`}> </div>}
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorElNoti}
          open={openNoti}
          onClose={() => setAnchorElNoti(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              maxHeight: '500px',
              width: '400px',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
            onScroll: (e) => handleScroll(e),
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {notificationBellReducer.listNotification.length !== 0 ? (
            notificationBellReducer.listNotification.map((data: Notification, index: number) => (
              <MenuItem onClick={() => handleClickMenu(data)} key={index}>
                <div className={styles.menuNoti}>
                  <div className={styles.firstStringInName}>{(data.senderUserName as string).charAt(0)}</div>
                  <div>
                    <p>{convertDateFormatTimeStamp(data.createdAt)}</p>
                    <h6>
                      {data.senderUserName}
                      {data.notificationDescription}
                    </h6>
                    <p aria-expanded={data.isRead as boolean} className={styles.timeRelative}>
                      {convertRelativeTime(data.createdAt)}
                    </p>
                  </div>
                  <div className={!data.isRead ? styles.isRead : ''}> </div>
                </div>
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Data</MenuItem>
          )}
        </Menu>
      </>
    )
  }

  const roleName =
    permissionReducer.permissionListOption.find((ele: IOption) => ele.value === getDecodeTokenReducer.userRoleID)
      ?.label || ''

  return (
    <>
      <div className={styles.navBarTop}>
        {/* Section Name and button back */}
        <div className={styles.boxSection}>
          {props.open && (
            <div className={styles.boxHistory} onClick={() => navigate(-1)}>
              <img className={styles.arrowHistory} src={arrowCircleRightPNG} alt="" />
              <q className={styles.colorTextRed}>Back</q>
            </div>
          )}
          <h3>{props.section}</h3>
        </div>

        <div className={styles.boxProfile}>
          {RenderBell()}

          <h6
            aria-expanded={openMenu}
            className={styles.email}
            onClick={(e: React.MouseEvent<HTMLHeadingElement>) => setAnchorElMenu(e.currentTarget)}
          >
            {getDecodeTokenReducer.email} - {`${roleName.slice(0)[0]}${roleName.slice(1).toLowerCase()}`}
            <KeyboardArrowDownIcon />
          </h6>
          <Menu
            anchorEl={anchorElMenu}
            open={openMenu}
            onClose={() => setAnchorElMenu(null)}
            PaperProps={{
              sx: {
                width: '150px',
              },
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>

      <div className={styles.navBarTopMobile}>
        <img
          width={40}
          height={40}
          src={hamburgurSVG}
          alt=""
          onClick={() => props.setSideNavOpen(!props.sideNavOpen)}
        />
        <img width={50} height={50} src={logoscgPNG} alt="" onClick={() => navigate('/')} />
        {RenderBell()}
      </div>
    </>
  )
}
export default NavTopBar
