import armchairPNG from 'images/commons/armChair.png'
import armchairWhitePNG from 'images/commons/armChairWhite.png'
import campaignIconSVG from 'images/Navside/campaign.svg'
import customerIconSVG from 'images/Navside/customer.svg'
import linkIconSVG from 'images/Navside/link.svg'
import approveIconSVG from 'images/Navside/approve.svg'
import sendIconSVG from 'images/Navside/sendMail.svg'
import reportIconSVG from 'images/Navside/report.svg'
import configIconSVG from 'images/Navside/config.svg'
import dataIconSVG from 'images/Navside/database.svg'
import userIconSVG from 'images/Navside/user.svg'

export interface IMenuApp {
  name: string
  link: string
  photo: string
  photoActive: string
  isSubMenu: boolean
  id?: number
  subMenu?: ISubMenuApp[]
  accessKey?: string
}

export interface ISubMenuApp {
  name?: string
  link: string
  accessKey: string
}

export const menuApp: IMenuApp[] = [
  // {
  //   name: 'Dashboard',
  //   link: '/',
  //   photo: armchairPNG,
  //   photoActive: armchairWhitePNG,
  //   isSubMenu: false,
  // },
  {
    name: 'Campaign',
    link: '/campaign',
    photo: campaignIconSVG,
    photoActive: armchairWhitePNG,
    isSubMenu: true,
    id: 1,
    subMenu: [
      {
        name: 'Calendar',
        link: '/campaign/calendar',
        accessKey: 'calendar',
      },
      {
        name: 'Normal Campaign',
        link: '/campaign/list-normal',
        accessKey: 'normalCampaign',
      },
      {
        name: 'Special Campaign',
        link: '/campaign/list-special',
        accessKey: 'specialCampaign',
      },
    ],
  },
  // {
  //   name: 'Customer',
  //   link: '/customer',
  //   isSubMenu: false,
  //   photo: customerIconSVG,
  //   photoActive: armchairWhitePNG,
  // },
  {
    name: 'Call from DMS',
    link: '/call-from-dms',
    accessKey: 'callFromDMS',
    photo: linkIconSVG,
    photoActive: armchairWhitePNG,
    isSubMenu: false,
  },
  {
    name: 'Review & Approval',
    link: '/review-and-approval',
    photo: approveIconSVG,
    photoActive: approveIconSVG,
    isSubMenu: true,
    id: 2,
    subMenu: [
      {
        name: 'Approve Campaign',
        link: '/review-and-approval/review-campaign-list',
        accessKey: 'approveCampaign',
      },
      {
        name: 'Review Point',
        link: '/check-point/campaign-list',
        accessKey: 'reviewPoint',
      },
      {
        name: 'Approve Point',
        link: '/approve-point/campaign-list',
        accessKey: 'approvePoint',
      },
    ],
  },
  {
    name: 'Send point',
    link: '/send-point',
    accessKey: 'sendPoint',
    isSubMenu: false,
    photo: sendIconSVG,
    photoActive: sendIconSVG,
  },
  // {
  //   name: 'Report',
  //   link: '/report',
  //   isSubMenu: false,
  //   photo: reportIconSVG,
  //   photoActive: reportIconSVG,
  // },
  // {
  //   name: 'Configuration',
  //   link: '/configuration',
  //   isSubMenu: false,
  //   photo: configIconSVG,
  //   photoActive: configIconSVG,
  // },
  {
    name: 'Master data',
    link: '/master-data',
    isSubMenu: true,
    photo: dataIconSVG,
    photoActive: dataIconSVG,
    id: 3,
    subMenu: [
      {
        name: 'Zone management',
        link: '/master-data/zone',
        accessKey: 'zone',
      },
      {
        name: 'Province management',
        link: '/master-data/province',
        accessKey: 'province',
      },
      {
        name: 'District management',
        link: '/master-data/district',
        accessKey: 'district',
      },
      {
        name: 'Segment management',
        link: '/master-data/segment',
        accessKey: 'segment',
      },
      {
        name: 'Promotional Brand',
        link: '/master-data/brand',
        accessKey: 'brand',
      },
      {
        name: 'Promotional Subject',
        link: '/master-data/product',
        accessKey: 'product',
      },
      {
        name: 'Unit',
        link: '/master-data/unit',
        accessKey: 'unit',
      },
      {
        name: 'Customer Management',
        link: '/master-data/shop',
        accessKey: 'shopRetailer',
      },
      {
        name: 'Customer Management (Big Retailer)',
        link: '/master-data/big-retailer',
        accessKey: 'shopBigRetailer',
      },
      {
        name: 'Tags Management',
        link: '/master-data/tags',
        accessKey: 'tag',
      },
    ],
  },
  {
    name: 'User Management',
    link: '/user-management',
    isSubMenu: true,
    photo: userIconSVG,
    photoActive: userIconSVG,
    subMenu: [
      {
        name: 'User Permission',
        link: '/user-management/role',
        accessKey: 'userRole',
      },
      {
        name: 'User List',
        link: '/user-management/user',
        accessKey: 'userList',
      },
    ],
  },
]
