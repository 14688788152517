import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  brandList: [],
  brandOptionList: [],
  refetch: null,
}

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    setBrand: (state, action: PayloadAction<any>) => {
      state.brandList = action.payload.items
      state.brandOptionList = action.payload.items?.map((data: any) => ({
        value: data.productBrandID,
        label: data.brandName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setBrand } = brandSlice.actions

export default brandSlice.reducer
