import { gql } from '@apollo/client'

export const GET_LIST_DISTRICT = gql`
  query ListDistrict($pageSize: Int!, $nextToken: String, $filter: FilterListDistrictInput) {
    listDistrict(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      items {
        districtID
        districtName
        provinceID
        countryID
        isActive
      }
      nextToken
    }
  }
`

export const UPDATE_DISTRICT = gql`
  mutation UpdateDistrict($input: UpdateDistrictInput!) {
    updateDistrict(input: $input) {
      res_code
      res_desc
    }
  }
`

export const CREATE_DISTRICT = gql`
  mutation CreateDistrict($input: CreateDistrictInput!) {
    createDistrict(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_DISTRICT = gql`
  mutation RecoveryDistrict($input: RecoveryDistrictInput!) {
    recoveryDistrict(input: $input) {
      res_code
      res_desc
    }
  }
`

export const DELETE_DISTRICT = gql`
  mutation DeleteDistrict($input: DeleteDistrictInput!) {
    deleteDistrict(input: $input) {
      res_code
      res_desc
    }
  }
`
