import { useQuery, useMutation } from '@apollo/client'
import {
  GET_LIST_PROVINCE,
  UPDATE_PROVINCE,
  CREATE_PROVINCE,
  RECOVERY_PROVINCE,
  DELETE_PROVINCE,
} from 'queries/province.queries'
import { useHandleResponseAPI } from 'hooks'

function useProvince(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_PROVINCE, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createProvince, optionCreate] = useMutation(CREATE_PROVINCE)
  const [updateProvince, optionUpdate] = useMutation(UPDATE_PROVINCE)
  const [deleteProvince, optionDelete] = useMutation(DELETE_PROVINCE)
  const [recoveryProvince, optionRecovery] = useMutation(RECOVERY_PROVINCE)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listProvince?.res_code === '99')
      return JSON.stringify(errorQuery || data?.listProvince?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createProvince?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createProvince?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateProvince?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateProvince?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteProvince?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteProvince?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryProvince?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryProvince?.res_desc)
    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listProvince,
    provinceLoading: loading,
    refetch,
    createProvince,
    updateProvince,
    deleteProvince,
    recoveryProvince,
  }
}

export default useProvince
