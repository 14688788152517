//Lib
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

//Includes Project
import { TextField } from 'component'
import styles from './index.module.scss'

interface Props {
  level: string[]
}

const TableLevelSpecialCampaign: React.FC<Props> = (props: Props) => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  return (
    <section className={styles.overflowTable}>
      {values.campaign.targetProduct.length > 0 && (
        <table className="tableMain">
          <thead>
            <tr>
              <th rowSpan={2}>
                <p> {t<string>('createNormalCampaign.promotion')}</p>
              </th>
              {props.level.map((ele, index: number) => (
                <th key={index}>
                  <p>{ele}</p>
                </th>
              ))}
            </tr>

            <tr>
              {props.level.map((ele, index: number) => (
                <th key={index}>
                  <p> {t<string>('createSpecialCampaign.bonus')}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values.campaign.targetProduct &&
              values.campaign.targetProduct.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{item.label}</td>
                    {props.level.map((ele, indexKey: number) => (
                      <td key={indexKey}>
                        {values.activeStep === 0 ? (
                          <TextField
                            name={`campaign.targetProduct[${index}].levelList[${indexKey}].point`}
                            type={'number'}
                          />
                        ) : (
                          <p>{values.campaign.targetProduct[index].levelList[indexKey]?.point}</p>
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
    </section>
  )
}

export default TableLevelSpecialCampaign
