// Lib
import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

// Include in project
import styles from './index.module.scss'
import { ChipByStatusCampaign } from 'component'

const SetupNewCampaignModeView: React.FC = () => {
  const { values } = useFormikContext<any>()
  const { t } = useTranslation()

  return (
    <section className={styles.container}>
      <h5>{t<string>('createNormalCampaign.setUpNewCampaign')}</h5>
      <div className={styles.wrapperInput}>
        <h6 className={styles.textTitle}>{t<string>('createNormalCampaign.name')}:</h6>
        <div className={styles.flexWrapper}>
          <p>{values.campaign.campaignName}</p>
          <ChipByStatusCampaign status={`${values.campaign.campaignStatus}`} />
        </div>
        <h6 className={styles.textTitle}>{t<string>('common.description')}:</h6>
        <p>{values.campaign.campaignDescription}</p>

        <h6 className={styles.textTitle}>{t<string>('createNormalCampaign.type')}:</h6>
        <p>{values.campaign.campaignType}</p>

        <h6 className={styles.textTitle}>{t<string>('createNormalCampaign.campaignGoal')}:</h6>
        <p>{values.campaign.campaignGoal}</p>

        <h6 className={styles.textTitle}>Remark:</h6>
        <p>{values.campaign.remark}</p>
      </div>
    </section>
  )
}
export default SetupNewCampaignModeView
