export { menuApp } from './menu'
export type { IMenuApp, ISubMenuApp } from './menu'
import urBoxLogoPNG from 'images/sendPoint/urbox.png'

//========================== [Vietnam] Starting Login Prod Key ============================

const VN_LOGIN_AES_KEY_PRD = `CC09BB781121E8D4`
const VN_CHANGE_PASSWORD_AES_KEY_PRD = `A9BFA1BDD3583F6E`

const VN_AZURE_CLIENT_ID_PRD = 'cc92db3b-9372-468e-a61a-0d9ce6ee8297'
const VN_AZURE_TENANT_ID_PRD = '5db8bf0e-8592-4ed0-82b2-a6d4d77933d4'

//========================== [Vietnam] Ending Login Prod Key ============================

//========================== [Vietnam] Starting Login Qas Key ============================

const VN_LOGIN_AES_KEY_QAS = `5F14B504E78C3FE8`
const VN_CHANGE_PASSWORD_AES_KEY_QAS = `34B5489A91B64E39`

const VN_AZURE_CLIENT_ID_QAS = 'e5dbcbc0-93b3-467a-ba6d-028168090dd5'
const VN_AZURE_TENANT_ID_QAS = '5db8bf0e-8592-4ed0-82b2-a6d4d77933d4'

//========================== [Vietnam] Ending Login Qas Key ============================

//========================== [Philippines] Starting Login Prod Key ============================

const PH_LOGIN_AES_KEY_PRD = `A2D0D40BC9607483`
const PH_CHANGE_PASSWORD_AES_KEY_PRD = `92210F68C6B8D9BD`

const PH_AZURE_CLIENT_ID_PRD = 'e8bf1d44-a329-40ec-a088-9fbcef859ab5'
const PH_AZURE_TENANT_ID_PRD = '5db8bf0e-8592-4ed0-82b2-a6d4d77933d4'

//========================== [Philippines] Ending Login Prod Key ============================

//========================== [Philippines] Starting Login Qas Key ============================

const PH_LOGIN_AES_KEY_QAS = `100839645CDF016D`
const PH_CHANGE_PASSWORD_AES_KEY_QAS = `6112B82C968D6440`

const PH_AZURE_CLIENT_ID_QAS = 'b93e41d7-c28d-4cac-aca5-7fb4740e05a5'
const PH_AZURE_TENANT_ID_QAS = '5db8bf0e-8592-4ed0-82b2-a6d4d77933d4'

//========================== [Philippines] Ending Login Qas Key ============================

interface CONFIG {
  SEND_POINT_LOGO: string | null
  IS_QUERY_DATA_DMS: boolean
  PROJECT_TITLE: string
  ENV_TEXT: string
  LOGIN_AES_KEY: string
  CHANGE_PASSWORD_AES_KEY: string
  AZURE_CLIENT_ID: string
  AZURE_TENANT_ID: string
}

interface IENV_CONFIG {
  prod: CONFIG
  dev: CONFIG
  test: CONFIG
  'prod-ph': CONFIG
  'dev-ph': CONFIG
  'test-ph': CONFIG
}

const ENV_CONFIG: IENV_CONFIG = {
  prod: {
    SEND_POINT_LOGO: urBoxLogoPNG,
    IS_QUERY_DATA_DMS: true,
    PROJECT_TITLE: 'CBMV',
    ENV_TEXT: 'PRD-VN',
    LOGIN_AES_KEY: VN_LOGIN_AES_KEY_PRD,
    CHANGE_PASSWORD_AES_KEY: VN_CHANGE_PASSWORD_AES_KEY_PRD,
    AZURE_CLIENT_ID: VN_AZURE_CLIENT_ID_PRD,
    AZURE_TENANT_ID: VN_AZURE_TENANT_ID_PRD,
  },
  dev: {
    SEND_POINT_LOGO: urBoxLogoPNG,
    IS_QUERY_DATA_DMS: true,
    PROJECT_TITLE: 'CBMV',
    ENV_TEXT: 'QAS-VN',
    LOGIN_AES_KEY: VN_LOGIN_AES_KEY_QAS,
    CHANGE_PASSWORD_AES_KEY: VN_CHANGE_PASSWORD_AES_KEY_QAS,
    AZURE_CLIENT_ID: VN_AZURE_CLIENT_ID_QAS,
    AZURE_TENANT_ID: VN_AZURE_TENANT_ID_QAS,
  },
  test: {
    SEND_POINT_LOGO: urBoxLogoPNG,
    IS_QUERY_DATA_DMS: true,
    PROJECT_TITLE: 'CBMV',
    ENV_TEXT: 'TEST-VN',
    LOGIN_AES_KEY: VN_LOGIN_AES_KEY_QAS,
    CHANGE_PASSWORD_AES_KEY: VN_CHANGE_PASSWORD_AES_KEY_QAS,
    AZURE_CLIENT_ID: VN_AZURE_CLIENT_ID_QAS,
    AZURE_TENANT_ID: VN_AZURE_TENANT_ID_QAS,
  },
  'prod-ph': {
    SEND_POINT_LOGO: null,
    IS_QUERY_DATA_DMS: false,
    PROJECT_TITLE: 'SCGM',
    ENV_TEXT: 'PRD-PH',
    LOGIN_AES_KEY: PH_LOGIN_AES_KEY_PRD,
    CHANGE_PASSWORD_AES_KEY: PH_CHANGE_PASSWORD_AES_KEY_PRD,
    AZURE_CLIENT_ID: PH_AZURE_CLIENT_ID_PRD,
    AZURE_TENANT_ID: PH_AZURE_TENANT_ID_PRD,
  },
  'dev-ph': {
    SEND_POINT_LOGO: null,
    IS_QUERY_DATA_DMS: false,
    PROJECT_TITLE: 'SCGM',
    ENV_TEXT: 'QAS-PH',
    LOGIN_AES_KEY: PH_LOGIN_AES_KEY_QAS,
    CHANGE_PASSWORD_AES_KEY: PH_CHANGE_PASSWORD_AES_KEY_QAS,
    AZURE_CLIENT_ID: PH_AZURE_CLIENT_ID_QAS,
    AZURE_TENANT_ID: PH_AZURE_TENANT_ID_QAS,
  },
  'test-ph': {
    SEND_POINT_LOGO: null,
    IS_QUERY_DATA_DMS: false,
    PROJECT_TITLE: 'SCGM',
    ENV_TEXT: 'TEST-PH',
    LOGIN_AES_KEY: PH_LOGIN_AES_KEY_QAS,
    CHANGE_PASSWORD_AES_KEY: PH_CHANGE_PASSWORD_AES_KEY_QAS,
    AZURE_CLIENT_ID: PH_AZURE_CLIENT_ID_QAS,
    AZURE_TENANT_ID: PH_AZURE_TENANT_ID_QAS,
  },
}

export const env = ENV_CONFIG[`${process.env['REACT_APP_END_POINT_STAGE'] as keyof IENV_CONFIG}`]
