// Lib
import React from 'react'

//Include Project
import styles from './index.module.scss'
import { Props } from './config'

const Table: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <section className={styles.wapperTable}>
      <table className="tableMain">
        {props.colSize && (
          <colgroup>
            {Object.keys(props.colSize).map((key, index) => (
              <col key={index} style={{ minWidth: props.colSize[key] }} />
            ))}
          </colgroup>
        )}
        <thead>
          <tr>
            {Object.keys(props.objectKey).map((key, index) => (
              <th key={index}>
                <h6>{props.objectKey[key]}</h6>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {props.data.length <= 0 ? (
            <tr>
              <td colSpan={Object.keys(props.objectKey).length}>No Data</td>
            </tr>
          ) : (
            <>
              {props.data.map((data: any, index) => {
                return (
                  <tr key={index}>
                    {Object.keys(props.objectKey).map((key, indexKey) => (
                      <td key={indexKey}>{data[key] ? data[key] : '-'}</td>
                    ))}
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </table>
    </section>
  )
}

export default Table
