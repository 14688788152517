import { gql } from '@apollo/client'

export const GET_LIST_BRAND = gql`
  query ListBrand($pageSize: Int!, $nextToken: String, $filter: FilterListBrandInput) {
    listBrand(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      items {
        productBrandID
        brandName
        isActive
      }
      nextToken
    }
  }
`

export const CREATE_BRAND = gql`
  mutation Mutation($input: CreateBrandInput!) {
    createBrand(input: $input) {
      res_code
      res_desc
      productBrandID
      brandName
      isActive
    }
  }
`
export const UPDATE_BRAND = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      res_code
      res_desc
      productBrandID
      brandName
      isActive
    }
  }
`

export const DELETE_BRAND = gql`
  mutation DeleteBrand($input: DeleteBrandInput!) {
    deleteBrand(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_BRAND = gql`
  mutation RecoveryBrand($input: RecoveryBrandInput!) {
    recoveryBrand(input: $input) {
      res_code
      res_desc
    }
  }
`
