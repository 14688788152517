import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import districtReducer from './slice/district'
import modalDialogReducer from './slice/modalDialog'
import notificationReducer from './slice/notification'
import productReducer from './slice/product'
import provinceReducer from './slice/province'
import segmentReducer from './slice/segment'
import shopReducer from './slice/shop'
import tagsReducer from './slice/tags'
import testReducer from './slice/test'
import zoneReducer from './slice/zone'
import unitReducer from './slice/unit'
import brandReducer from './slice/brand'
import permissionReducer from './slice/permission'
import shopBigRetailerReducer from './slice/shopBigRetailer'
import decodeTokenReducer from './slice/decodeToken'
import notificationBellReducer from './slice/notificationBell'

export const store = configureStore({
  reducer: {
    district: districtReducer,
    modalDialog: modalDialogReducer,
    notification: notificationReducer,
    product: productReducer,
    province: provinceReducer,
    segment: segmentReducer,
    shop: shopReducer,
    tags: tagsReducer,
    test: testReducer,
    zone: zoneReducer,
    unit: unitReducer,
    brand: brandReducer,
    permission: permissionReducer,
    shopBigRetailer: shopBigRetailerReducer,
    decodeToken: decodeTokenReducer,
    notificationBell: notificationBellReducer,
  },
  middleware: [thunk],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
