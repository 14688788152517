import { gql } from '@apollo/client'

export const GET_LIST_SEGMENT = gql`
  query ListSegment($pageSize: Int!, $nextToken: String, $filter: FilterListSegmentInput) {
    listSegment(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      items {
        segmentID
        segmentName
        isActive
      }
      nextToken
      res_code
      res_desc
    }
  }
`

export const CREATE_SEGMENT = gql`
  mutation Mutation($input: CreateSegmentInput!) {
    createSegment(input: $input) {
      res_code
      res_desc
      segmentID
      segmentName
      isActive
    }
  }
`
export const UPDATE_SEGMENT = gql`
  mutation UpdateSegment($input: UpdateSegmentInput!) {
    updateSegment(input: $input) {
      res_code
      res_desc
      segmentID
      segmentName
      isActive
    }
  }
`

export const DELETE_SEGMENT = gql`
  mutation Mutation($input: DeleteSegmentInput!) {
    deleteSegment(input: $input) {
      res_code
      res_desc
    }
  }
`
export const RECOVER_SEGMENT = gql`
  mutation Mutation($input: RecoverySegmentInput!) {
    recoverySegment(input: $input) {
      res_code
      res_desc
    }
  }
`
