import { useQuery } from '@apollo/client'
import { GET_LIST_USER_PERMISSION } from 'queries/userPermission.queries'
import { useHandleResponseAPI } from 'hooks'

function useUserPermissionList(variables?: any) {
  const { data, loading, error, refetch } = useQuery(GET_LIST_USER_PERMISSION, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const errMsg = () => {
    if (error || data?.userPermission?.res_code === '99') return JSON.stringify(error || data?.userPermission?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: loading,
    errMsg: errMsg(),
  })

  return { data: data?.userPermission, permissionLoading: loading, refetch }
}

export default useUserPermissionList
