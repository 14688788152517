import * as Yup from 'yup'

const yupShopBigRetailer = Yup.object().shape({
  formInput: Yup.object().shape({
    bigRetailerCode: Yup.string().required('Please Enter DMS Code'),
    bigRetailerName: Yup.string().required('Please Enter Retailer Name'),
    zoneID: Yup.string().required('Please Select Zone'),
    provinceID: Yup.string().required('Please Select Province'),
    districtID: Yup.string().required('Please Select District'),
    segmentID: Yup.string().required('Please Select Segment'),
    // phoneNumber: Yup.string().required('Please Enter Phone Number'),
  }),
})

export default yupShopBigRetailer
