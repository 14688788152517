import { gql } from '@apollo/client'

export const GET_MONTHLY_STAR = gql`
  query ListMonthlyStar($year: Int!, $type: EMonthlyStarType!, $value: String!) {
    listMonthlyStar(year: $year, type: $type, value: $value) {
      res_code
      res_desc
      items {
        monthNumber
        basicStar
        bonusStar
        total
      }
    }
  }
`

export const GET_MONTHLY_SALE_VOLUMN = gql`
  query ListMonthlySaleVolume($year: Int!, $type: EMonthlySaleVolumeType!, $value: String!) {
    listMonthlySaleVolume(year: $year, type: $type, value: $value) {
      res_code
      res_desc
      items {
        monthNumber
        saleVolume
      }
    }
  }
`

export const GET_MONTHLY_COST_PER_TON = gql`
  query ListMonthlyCostPerTon($year: Int!, $type: EMonthlySaleVolumeType!, $value: String!) {
    listMonthlyCostPerTon(year: $year, type: $type, value: $value) {
      res_code
      res_desc
      items {
        costPerTon
        monthNumber
      }
    }
  }
`

export const GET_BY_ZONE_ACHIVED_RTL = gql`
  query ListByZoneAchievedRTL($startDate: Date, $endDate: Date, $zoneID: Int) {
    listByZoneAchievedRTL(startDate: $startDate, endDate: $endDate, zoneID: $zoneID) {
      res_code
      res_desc
      items {
        provinceID
        provinceName
        achievedRTL
        notAchievedRTL
        totalRTL
      }
    }
  }
`
export const GET_BY_ZONE_STAR = gql`
  query ListByZoneStar($startDate: Date, $endDate: Date, $zoneID: Int) {
    listByZoneStar(startDate: $startDate, endDate: $endDate, zoneID: $zoneID) {
      res_code
      res_desc
      items {
        provinceID
        provinceName
        basicStar
        bonusStar
        total
      }
    }
  }
`

export const GET_BY_ZONE_COST_PER_TON = gql`
  query ListByZoneCostPerTon($startDate: Date, $endDate: Date, $zoneID: Int) {
    listByZoneCostPerTon(startDate: $startDate, endDate: $endDate, zoneID: $zoneID) {
      res_code
      res_desc
      items {
        provinceID
        provinceName
        costPerTon
      }
    }
  }
`
