// Lib
import React from 'react'
import { Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'

// Images
import closeSVG from 'images/commons/close.svg'

// Include in project
import styles from './index.module.scss'
import { Button, TextField } from 'component'
import { Props } from './config'
import { ETransformationDataToServer, transformationDataToServer } from 'utils/tranformData'

const ModalSaleVolumeAdjustment: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { errors, values, handleSubmit } = useFormikContext<any>()

  const handleAdjustSaleVolume = () => {
    if (!_.isEmpty(errors)) return
    const packData = transformationDataToServer({
      values: values.formInput,
      type: ETransformationDataToServer.AdjustSaleVolume,
    })
    props.adjustSaleVolume({ variables: packData }).then((res: any) => {
      if (res.data.adjustSaleVolume.res_code === '99') return

      props.onClose()
      props.refetchDataFromDMS()
    })
  }

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Form className={styles.containerModal}>
        <div className={styles.wrapperCard}>
          <div className={styles.head}>
            <h3>{t<string>('callFromDMS.modalTitle')}</h3>
            <img src={closeSVG} alt="closeSVG" onClick={props.onClose} />
          </div>

          <div className={styles.cardTotal}>
            <h6>{t<string>('callFromDMS.modalDescription')}</h6>

            <div className={styles.groupInput}>
              <p>Sale Volume</p>
              <TextField name="formInput.saleVolume" type={'number'} />
            </div>

            <div className={styles.line} />
            <TextField
              name="formInput.adjustmentReason"
              type="text"
              label="Comment and Detail"
              require
              placeholder="Your comment"
            />
          </div>

          <div className={styles.wrapperButton}>
            <Button type="secondary" typeButton="button" name={t<string>('common.cancel')} disabled={false} />
            <Button
              type="primary"
              typeButton="submit"
              name={t<string>('common.save')}
              disabled={false}
              functionOnClick={() => {
                handleSubmit()
                handleAdjustSaleVolume()
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}
export default ModalSaleVolumeAdjustment
