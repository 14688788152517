import { gql } from '@apollo/client'

export const GET_LIST_TAGS = gql`
  query ListTags($pageSize: Int!, $nextToken: String, $filter: FilterListTagsInput) {
    listTags(pageSize: $pageSize, nextToken: $nextToken, filter: $filter) {
      res_code
      res_desc
      items {
        tagID
        tagName
        typeTag
        tagColor
        isActive
      }
      nextToken
    }
  }
`
export const CREATE_TAGS = gql`
  mutation CreateTags($input: CreateTagInput!) {
    createTags(input: $input) {
      res_code
      res_desc
      tagID
      tagName
      typeTag
      # tagColor
      isActive
    }
  }
`

export const UPDATE_TAGS = gql`
  mutation UpdateTags($input: UpdateTagInput!) {
    updateTags(input: $input) {
      res_code
      res_desc
      tagID
      tagName
      typeTag
      # tagColor
      isActive
    }
  }
`
export const DELETE_TAGS = gql`
  mutation DeleteTags($input: DeleteTagInput!) {
    deleteTags(input: $input) {
      res_code
      res_desc
    }
  }
`

export const RECOVERY_TAGS = gql`
  mutation Mutation($input: RecoveryTagInput!) {
    recoveryTags(input: $input) {
      res_code
      res_desc
    }
  }
`
