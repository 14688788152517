import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_USER_LIST,
  DELETE_USER_LIST,
  GET_LIST_USER_LIST,
  RECOVERY_USER_LITS,
  UPDATE_USER_LIST,
} from 'queries/userList.queries'
import { useHandleResponseAPI } from 'hooks'

function useUserList(variables?: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_USER_LIST, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createUserList, optionCreate] = useMutation(CREATE_USER_LIST)
  const [updateUserList, optionUpdate] = useMutation(UPDATE_USER_LIST)
  const [deleteUserList, optionDelete] = useMutation(DELETE_USER_LIST)
  const [recoveryUserList, optionRecovery] = useMutation(RECOVERY_USER_LITS)

  const isLoading = loading || optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listUser?.res_code === '99') return JSON.stringify(errorQuery || data?.listUser?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createUser?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createUser?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateUser?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateUser?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteUser?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteUser?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryUser?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryUser?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return { data: data?.listUser, refetch, createUserList, updateUserList, deleteUserList, recoveryUserList }
}

export default useUserList
