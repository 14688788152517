import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  segmentList: [],
  segmentListOption: [],
  refetch: null,
}

export const segmentSlice = createSlice({
  name: 'segment',
  initialState,
  reducers: {
    setSegment: (state, action: PayloadAction<any>) => {
      state.segmentList = action.payload.items
      state.segmentListOption = action.payload.items.map((data: any) => ({
        value: data.segmentID,
        label: data.segmentName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSegment } = segmentSlice.actions

export default segmentSlice.reducer
