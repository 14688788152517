import { useMutation, useQuery } from '@apollo/client'
import { CREATE_TAGS, DELETE_TAGS, GET_LIST_TAGS, RECOVERY_TAGS, UPDATE_TAGS } from 'queries/tags.queries'
import { useHandleResponseAPI } from 'hooks'

function useTags(variables: any) {
  const {
    data,
    loading,
    error: errorQuery,
    refetch,
  } = useQuery(GET_LIST_TAGS, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createTags, optionCreate] = useMutation(CREATE_TAGS)
  const [updateTags, optionUpdate] = useMutation(UPDATE_TAGS)
  const [deleteTags, optionDelete] = useMutation(DELETE_TAGS)
  const [recoveryTags, optionRecovery] = useMutation(RECOVERY_TAGS)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listTags?.res_code === '99') return JSON.stringify(errorQuery || data?.listTags?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createTags?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createTags?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateTags?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateTags?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteTags?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteTags?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoveryTags?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoveryTags?.res_desc)
    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listTags,
    tagsLoading: loading,
    refetch,
    createTags,
    updateTags,
    deleteTags,
    recoveryTags,
  }
}

export default useTags
