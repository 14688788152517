import Color from 'abstracts/export.module.scss'

export interface Props {
  color: 'disabled' | 'primary' | 'danger' | 'info' | 'success' | 'warning'
  label: string
  icon?: JSX.Element
  disabled?: boolean
  onDelete?: () => void
}

export const ThemeColor = {
  disabled: {
    color: `${Color.grey900}`,
    border: `1px solid ${Color.grey900}`,
    backgroundColor: `${Color.grey100}`,
    '& .MuiChip-deleteIcon': {
      color: `${Color.grey900}`,
      '&:hover': {
        color: `${Color.white} !important`,
      },
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '700',
      fontFamily: 'FCIconicBold !important',
    },
    '&:hover': {
      color: `${Color.white} !important`,
      '& .MuiChip-deleteIcon': {
        color: `${Color.white}`,
      },
    },
  },
  primary: {
    color: `${Color.primaryMain}`,
    border: `1px solid ${Color.primaryBorder}`,
    backgroundColor: `${Color.primarySecond}`,
    '& .MuiChip-deleteIcon': {
      color: `${Color.primaryMain}`,
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '700',
      fontFamily: 'FCIconicBold !important',
    },
    '&:hover': {
      color: `${Color.white} !important`,
      backgroundColor: `${Color.primaryHover}`,
      '& .MuiChip-deleteIcon': {
        color: `${Color.white}`,
      },
    },
  },
  danger: {
    color: `${Color.dangerMain}`,
    border: `1px solid ${Color.dangerBorder}`,
    backgroundColor: `${Color.dangerSecond}`,
    '& .MuiChip-deleteIcon': {
      color: `${Color.dangerMain}`,
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '700',
      fontFamily: 'FCIconicBold !important',
    },
    '&:hover': {
      color: `${Color.white} !important`,
      backgroundColor: `${Color.dangerHover}`,
      '& .MuiChip-deleteIcon': {
        color: `${Color.white}`,
      },
    },
  },
  warning: {
    color: `${Color.warningMain}`,
    border: `1px solid ${Color.warningBorder}`,
    backgroundColor: `${Color.warningSecond}`,
    '& .MuiChip-deleteIcon': {
      color: `${Color.warningMain}`,
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '700',
      fontFamily: 'FCIconicBold !important',
    },
    '&:hover': {
      color: `${Color.white} !important`,
      backgroundColor: `${Color.warningHover}`,
      '& .MuiChip-deleteIcon': {
        color: `${Color.white}`,
      },
    },
  },
  success: {
    color: `${Color.successMain}`,
    border: `1px solid ${Color.successBorder}`,
    backgroundColor: `${Color.successSecond}`,
    '& .MuiChip-deleteIcon': {
      color: `${Color.successMain}`,
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '700',
      fontFamily: 'FCIconicBold !important',
    },
    '&:hover': {
      color: `${Color.white} !important`,
      backgroundColor: `${Color.successHover}`,
      '& .MuiChip-deleteIcon': {
        color: `${Color.white}`,
      },
    },
  },
  info: {
    color: `${Color.infoMain}`,
    border: `1px solid ${Color.infoBorder}`,
    backgroundColor: `${Color.infoSecond}`,
    '& .MuiChip-deleteIcon': {
      color: `${Color.infoMain}`,
    },
    '& span': {
      fontSize: '12px',
      fontWeight: '700',
      fontFamily: 'FCIconicBold !important',
    },
    '&:hover': {
      color: `${Color.white} !important`,
      backgroundColor: `${Color.infoHover}`,
      '& .MuiChip-deleteIcon': {
        color: `${Color.white}`,
      },
    },
  },
}
