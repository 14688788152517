import * as Yup from 'yup'

const yupSpecialCampaign = Yup.object().shape({
  campaign: Yup.object().when('activeStep', {
    is: 0,
    then: Yup.object().shape({
      campaignName: Yup.string().required('Please enter your Campaign Name'),
      campaignType: Yup.string().required('Please enter your Campaign Type'),
      targetType: Yup.string().required('Please enter your Target Type'),
      shopType: Yup.string().when('targetType', {
        is: 'ZONE' || 'PROVINCE' || 'DISTRICT' || 'SEGMENT',
        then: Yup.string().required('Please enter your Shop Type'),
      }),
      targetProductDummy: Yup.array().min(1, 'Please enter your Tag Product'),
      targetTypeValueDummy: Yup.array().min(1, 'Please enter your Target Type Value'),
      timeDuration: Yup.array().min(2, 'Please enter your Time Duration'),
      targetProduct: Yup.array(
        Yup.object({
          levelList: Yup.array(
            Yup.object({
              point: Yup.number().required().min(0, 'Please Add'),
            }),
          ),
        }),
      ).min(1, 'Please Select Product'),
      targetTypeValue: Yup.array().when('campaignType', {
        is:
          'SPECIAL_StepLevelTargeting' ||
          'SPECIAL_AchievedTargeting' ||
          'SPECIAL_MONTHLY_EACH_TARGETING' ||
          'SPECIAL_MONTHLY_ACC_TARGETING',
        then: Yup.array(
          Yup.object({
            productList: Yup.array(
              Yup.object({
                targetList: Yup.array(
                  Yup.object({
                    amount: Yup.number().required().min(0, 'Please Add'),
                  }),
                ),
              }),
            ),
          }),
        ).min(1, 'Please Select Product'),
      }),
    }),
  }),
})

export default yupSpecialCampaign
