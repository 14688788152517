import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_SEGMENT,
  DELETE_SEGMENT,
  GET_LIST_SEGMENT,
  RECOVER_SEGMENT,
  UPDATE_SEGMENT,
} from 'queries/segment.queries'
import { useHandleResponseAPI } from 'hooks'

function useSegment(variables?: any) {
  const {
    data,
    refetch,
    loading,
    error: errorQuery,
  } = useQuery(GET_LIST_SEGMENT, {
    fetchPolicy: 'no-cache',
    variables: variables,
  })

  const [createSegment, optionCreate] = useMutation(CREATE_SEGMENT)
  const [updateSegment, optionUpdate] = useMutation(UPDATE_SEGMENT)
  const [deleteSegment, optionDelete] = useMutation(DELETE_SEGMENT)
  const [recoverSegment, optionRecovery] = useMutation(RECOVER_SEGMENT)

  const isLoading = optionCreate.loading || optionUpdate.loading || optionRecovery.loading

  const errMsg = () => {
    // Query
    if (errorQuery || data?.listSegment?.res_code === '99')
      return JSON.stringify(errorQuery || data?.listSegment?.res_desc)

    // optionCreate
    if (optionCreate?.error || optionCreate.data?.createSegment?.res_code === '99')
      return JSON.stringify(optionCreate?.error || optionCreate.data?.createSegment?.res_desc)

    // optionUpdate
    if (optionUpdate?.error || optionUpdate.data?.updateSegment?.res_code === '99')
      return JSON.stringify(optionUpdate?.error || optionUpdate.data?.updateSegment?.res_desc)

    // optionDelete
    if (optionDelete?.error || optionDelete.data?.deleteSegment?.res_code === '99')
      return JSON.stringify(optionDelete?.error || optionDelete.data?.deleteSegment?.res_desc)

    // optionRecovery
    if (optionRecovery?.error || optionRecovery.data?.recoverySegment?.res_code === '99')
      return JSON.stringify(optionRecovery?.error || optionRecovery.data?.recoverySegment?.res_desc)

    return ''
  }

  useHandleResponseAPI({
    isLoading: isLoading,
    errMsg: errMsg(),
  })

  return {
    data: data?.listSegment,
    segmentLoading: loading,
    refetch,
    createSegment,
    updateSegment,
    deleteSegment,
    recoverSegment,
  }
}

export default useSegment
