import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  zoneList: [],
  zoneListOption: [],
  refetch: null,
}

export const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    setZone: (state, action: PayloadAction<any>) => {
      state.zoneList = action.payload.items
      state.zoneListOption = action.payload.items.map((data: any) => ({
        value: data.zoneID,
        label: data.zoneName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

// Action creators are generated for each case reducer function
export const { setZone } = zoneSlice.actions

export default zoneSlice.reducer
