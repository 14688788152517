// Lib
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik, useFormikContext } from 'formik'

// Include in project
import Layout from 'layouts'
import { HeadOfUserList, ModalOfUser } from 'container'
import { Table } from 'component'
import { useInfiniteScroll, useModalForm, useUserList } from 'hooks'
import styles from './index.module.scss'
import { transformationDataIntoTable, ETranformationDataIntoTable } from 'utils/tranformData'

const UserListManagement: React.FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()

  const { isModalOpen, handleOpen, handleClose } = useModalForm({
    userID: '',
    name: '',
    country: '',
    email: '',
    password: '',
    userRoleID: '',
    isActive: false,
  })
  const {
    data: resUserList,
    refetch: refetchUserList,
    createUserList,
    updateUserList,
    deleteUserList,
  } = useUserList({
    pageSize: 25,
    filter: {
      isActive: values.filter.isActive === 'ACTIVE' ? true : false,
    },
  })

  const { dataList, handleResetCount } = useInfiniteScroll({
    result: resUserList,
    refetch: refetchUserList,
    variables: {
      pageSize: 25,
      isActive: values.filter.isActive === 'ACTIVE' ? true : false,
      nextToken: resUserList?.nextToken,
    },
    filter: values.filter,
    key: 'listUser',
  })

  const userList = transformationDataIntoTable({
    dataList: dataList,
    type: ETranformationDataIntoTable.ListUser,
    handleOpen: handleOpen,
  })

  useEffect(() => {
    handleResetCount()
    refetchUserList()
  }, [values.filter.isActive])

  return (
    <Layout open={false} section={t('userListManagement.title')}>
      <div className={styles.container}>
        <HeadOfUserList handleOpen={handleOpen} />
        <Table
          objectKey={{
            email: 'Email',
            name: 'Name',
            countryName: 'Country',
            userRole: 'User Role',
            statusHTML: 'Status',
            actionView: 'Action',
          }}
          data={userList || []}
        />
        <ModalOfUser
          isOpen={isModalOpen}
          onClose={handleClose}
          refetchUserList={refetchUserList}
          createUserLits={createUserList}
          updateUserList={updateUserList}
          deleteUserList={deleteUserList}
        />
      </div>
    </Layout>
  )
}

const EnhancedUserListManagement = withFormik({
  mapPropsToValues: () => ({
    filter: {
      isActive: 'ACTIVE',
      userRoleID: '',
      email: '',
      name: '',
    },
    formInput: {
      userID: '',
      name: '',
      country: '',
      email: '',
      password: '',
      userRoleID: '',
      isActive: false,
    },
  }),
  handleSubmit: (values) => {
    console.log(values, 'values')
  },
})(UserListManagement)
export default EnhancedUserListManagement
